import React, { useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { dataTableCustomStyles } from "../../../common/datatable/styles";
import { useMemo } from "react";
import FilterComponent from "../../../ui/filter";
import {
  DeletePriceGroupByIdAsync,
  GetPriceGroupsPagedAsync,
} from "../../../common/price-groups.services";
import { GetPriceGroupViewModel } from "../../../common/price-groups.services/helpeService";
import * as AiIcons from "react-icons/ai";
import { toast } from "react-toastify";
export default function PriceGroupsList() {
  const [loading, setLoading] = useState(false);
  const [priceGroupsView, setPriceGroupsView] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rowToRemovePriceGroup, setRowToRemovePriceGroup] = useState(-1);
  const [filterNameText, setFilterNameText] = useState("");
  const [filterBrandNameText, setFilterBrandNameText] = useState("");
  const filteredPriceGroups = getFilteredItems();
  function getFilteredItems() {
    return priceGroupsView.filter((item) => {
      const lowerCaseName = item.name?.toLowerCase() || "";
      const lowerCaseBrandName = item.brandName?.toLowerCase() || "";

      return (
        lowerCaseName.includes(filterNameText.toLowerCase()) &&
        lowerCaseBrandName.includes(filterBrandNameText.toLowerCase())
      );
    });
  }

  useEffect(() => {
    // Clear any existing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTimeout = setTimeout(() => {
      let nameFilter = filterNameText !== "" ? filterNameText : "";
      let brandNameFilter =
        filterBrandNameText !== "" ? filterBrandNameText : "";

      fetchPriceGroups(page, pageSize, nameFilter, brandNameFilter);
    }, 1000);

    setTypingTimeout(newTimeout);
  }, [filterNameText, filterBrandNameText]);

  const getPriceGroupsPaged = async (page, pageSize, name, brandName) => {
    let response = await GetPriceGroupsPagedAsync(
      page,
      pageSize,
      name,
      brandName
    );
    return response.data;
  };

  const fetchPriceGroups = async (
    page,
    pageSize,
    name = "",
    brandName = ""
  ) => {
    try {
      setLoading(true);
      const response = await getPriceGroupsPaged(
        page,
        pageSize,
        name,
        brandName
      );
      setPriceGroupsView([
        ...response.items.map((item) => {
          return GetPriceGroupViewModel(item);
        }),
      ]);
      setTotalRows(response.meta.totalCount);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchPriceGroups(page, pageSize, filterNameText, filterBrandNameText);
    setPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    fetchPriceGroups(page, newPerPage, filterNameText, filterBrandNameText);
    setPage(page);
    setPageSize(newPerPage);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        fetchPriceGroups(page, pageSize);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  const subHeaderComponentMemo = useMemo(() => {
    const handleNameClear = () => {
      if (filterNameText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterNameText("");
      }
    };
    const handleBrandNameClear = () => {
      if (filterBrandNameText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterBrandNameText("");
      }
    };

    return (
      <div className="filter-header">
        <FilterComponent
          onFilter={(e) => setFilterNameText(e.target.value)}
          onClear={handleNameClear}
          filterText={filterNameText}
        />
        <FilterComponent
          placeholder="filter by brand name"
          onFilter={(e) => setFilterBrandNameText(e.target.value)}
          onClear={handleBrandNameClear}
          filterText={filterBrandNameText}
        />
      </div>
    );
  }, [filterNameText, filterBrandNameText, resetPaginationToggle]);

  const priceGroupsTableColums = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: "Price Group", // Price group name
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Brand", // Brand name
      selector: (row) => row?.brandName,
      sortable: true,
    },
    {
      name: "Default Percent", // Price group default percent
      selector: (row) => `${row?.defaultPercent} %`,
      sortable: true,
    },
    {
      name: "Default Reference", // Price group default reference
      selector: (row) => row?.defaultReference,
      sortable: true,
    },
    {
      name: "Status", // Price group status
      selector: (row) => row?.status,
      sortable: true,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <div className="actions">
          <Link to={`${row.id}/edit`}>
            <Button
              style={{
                width: "4vw",
              }}
              variant="light"
            >
              Edit
            </Button>
          </Link>
        </div>
      ),
    },
    {
      name: "Remove",
      button: true,
      cell: (row) =>
        rowToRemovePriceGroup !== row.id ? (
          <Button
            onClick={() => ConfidenceToRemovePriceGroup(row.id)}
            style={{ width: "5vw" }}
            variant="light"
          >
            Remove
          </Button>
        ) : (
          <div className="delete-container">
            <Button
              variant="light"
              style={{ width: "2vw", marginRight: "3px" }}
              onClick={() => RemovePriceGroup(rowToRemovePriceGroup)}
            >
              <AiIcons.AiOutlineCheck></AiIcons.AiOutlineCheck>
            </Button>
            <Button
              onClick={NotSureToRemovePriceGroup}
              variant="light"
              style={{ width: "2.5vw", alignItems: "center" }}
            >
              <AiIcons.AiOutlineClose></AiIcons.AiOutlineClose>
            </Button>
          </div>
        ),
    },
  ];

  async function RemovePriceGroup(id) {
    try {
      let response = await DeletePriceGroupByIdAsync(id);
      if (response.status == 200) {
        toast.success(`Price Group with id: ${id} successfully removed!`);
        fetchPriceGroups(page, pageSize, filterNameText);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.details);
      } else {
        toast.error("Bad interent connection");
      }
    }

    fetchPriceGroups(page, pageSize, filterNameText, filterBrandNameText);
    setRowToRemovePriceGroup(-1);
  }
  function NotSureToRemovePriceGroup() {
    setRowToRemovePriceGroup(-1);
  }
  function ConfidenceToRemovePriceGroup(id) {
    setRowToRemovePriceGroup(id);
  }

  return (
    <div className="items-list">
      <div className="toolBar">
        <Link to={"new"}>
          <Button className="action-button" variant="light">
            Create
          </Button>
        </Link>
      </div>

      <DataTable
        progressPending={loading}
        paginationServer
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationResetDefaultPage={resetPaginationToggle}
        persistTableHead
        customStyles={dataTableCustomStyles}
        title="Price Groups"
        columns={priceGroupsTableColums}
        data={filteredPriceGroups}
        pagination
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      ></DataTable>
    </div>
  );
}
