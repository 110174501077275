import { useEffect, useState } from "react";
import CustomerDetails from "../../../components/customers/customer-details";
import { useDispatch, useSelector } from "react-redux";
import { GetAddressView } from "../../../common/services/view-service";
import { CreateCustomerRequest } from "../../../components/customers/service/HelpService";
import { CreateCustomerAsync } from "../../../components/customers/service/CustomerService";
import { CreateAddressAsync } from "../../../common/addressServices";
import { CustomerTempContainer } from "../../../components/customers/customer-details/container";
import Loader from "../../../ui/loader";

export default function CustomerCreatePage() {
  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);
  const [loading, setLoading] = useState(false);
  const customerTempState = useSelector((state) => state.customerTempReducer);
  const statusState = useSelector((state) => state.statusReducer);
  const initForm = {
    isDealer: false,
    is3PLCustomer: false,
  };
  const [form, setForm] = useState(initForm);

  useEffect(() => {
    let warehousesView = customerTempState.warehouses.map((warehouse) => {
      let address = customerTempState.addresses.find(
        (address) => address.id == warehouse.addressId
      );
      warehouse.address = GetAddressView(address);
      return warehouse;
    });

    setForm((prevForm) => ({
      ...prevForm,
      ["warehousesView"]: warehousesView,
      ["ownedBrandsView"]: customerTempState.selectedAssociateBrands,
      ["brandAccessView"]: customerTempState.selectedBrandsAccess,
    }));

    let billingAddressId = customerTempState.billingAddressId;
    if (!!billingAddressId || billingAddressId == 0) {
      const billingAddress = customerTempState.addresses.find((address) => {
        if (address.id == billingAddressId) return address;
      });
      setForm((prevForm) => ({
        ...prevForm,
        ["billingaddress"]: GetAddressView(billingAddress),
      }));
    }

    let shippingAddressId = customerTempState.shippingAddressId;
    if (!!shippingAddressId || shippingAddressId == 0) {
      const shippingAddress = customerTempState.addresses.find((address) => {
        if (address.id == shippingAddressId) return address;
      });
      setForm((prevForm) => ({
        ...prevForm,
        ["defaultshippingaddress"]: GetAddressView(shippingAddress),
      }));
    }
  }, [customerTempState]);

  async function Save() {
    const customer = CreateCustomerRequest(
      form,
      customerTempState,
      statusState
    );

    const addressIdDict = {};
    try {
      for (const element of customer.addresses) {
        const responseAddress = await CreateAddressAsync(element);
        addressIdDict[element.id] = responseAddress.data;
      }
    } catch (error) {
      console.log(error);
    }
    customer.defaultBillingAddressId =
      addressIdDict[customer.defaultBillingAddressId];
    customer.defaultShippingAddressId =
      addressIdDict[customer.defaultShippingAddressId];
    customer.ownedWareouses.map((item) => {
      item.locationId = item.location.id;
      return item;
    });
    customer.addressIds = Object.values(addressIdDict);

    try {
      const response = await CreateCustomerAsync(customer);
      container.ClearTempCustomer();

      return response;
    } catch (error) {
      return error;
    }
  }

  return (
    <div className="items-list">
      {loading ? (
        <Loader></Loader>
      ) : (
        <CustomerDetails
          form={form}
          setForm={setForm}
          Save={Save}
          successfulMessage={`${form.name} successfully created!`}
        ></CustomerDetails>
      )}
    </div>
  );
}
