import {
  AddListSKUs,
  AddSKU,
  ClearSKU,
  RemoveSKU,
  UpdateSKU,
} from "../../../redux/actions/price-groups/types";

export const SKUTempContainer = (dispatch) => ({
  AddSKU(data) {
    dispatch(AddSKU(data));
  },
  UpdateSKU(data) {
    dispatch(UpdateSKU(data));
  },
  ClearSKU() {
    dispatch(ClearSKU());
  },
  AddListSKUs(data) {
    dispatch(AddListSKUs(data));
  },
  RemoveSKU(data) {
    dispatch(RemoveSKU(data));
  },
});
