import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import AddressBooks from "../../../../../components/customers/customer-details/address-book.list";
import { CustomerTempContainer } from "../../../../../components/customers/customer-details/container";

export default function ShippingAddressBooksPage() {
  const customerTempState = useSelector((state) => state.customerTempReducer);
  const addresses = customerTempState.addresses;
  const [addressesBookView, setAddressesBookView] = useState([]);
  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);

  const selectedId = !!customerTempState?.shippingAddressId
    ? customerTempState?.shippingAddressId
    : addresses[0]?.id;

  useEffect(() => {
    setAddressesBookView(addresses);
  }, [addressesBookView]);

  function Save(id) {
    container.SelectShippingAddress(id);
  }

  return (
    <div>
      <AddressBooks
        save={Save}
        addressesBookView={addressesBookView}
        selectedId={selectedId}
      ></AddressBooks>
    </div>
  );
}
