import React, { useEffect, useState } from "react";
import PriceGroupDetails from "../../../components/price-groups/price-groups.details";
import { useParams } from "react-router-dom";
import Loader from "../../../ui/loader";
import { useDispatch, useSelector } from "react-redux";
import { SKUTempContainer } from "../../../components/price-groups/sku.details/container";
import {
  GetPriceGroupByIdAsync,
  UpdatePriceGroupAsync,
} from "../../../common/price-groups.services";
import { CreateRequest } from "../../../common/price-groups.services/helpeService";
import { toast } from "react-toastify";
import { BrandTempContainer } from "../../../components/brands/container";
import { SSF } from "xlsx";
import { SkewLoader } from "react-spinners";

export default function PriceGroupEditPage() {
  const skuTempState = useSelector((state) => state.skuTemReducer);
  const dispatch = useDispatch();
  const brandContainer = BrandTempContainer(dispatch);
  const container = SKUTempContainer(dispatch);
  const { id } = useParams();
  const brandTempState = useSelector((state) => state.brandTempReducer);
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  async function GetPriceGroup(id) {
    return await GetPriceGroupByIdAsync(id);
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let priceGroupResponse = await GetPriceGroup(id);
        const model = priceGroupResponse.data;
        const initForm = {
          id: model.id,
          status: model.status,
          amount: model.amount,
          defaultPercent: model.defaultPercent,
          defaultReference: model.defaultReference,
          name: model.name,
          brand: model.brand.name,
          brandId: model.brand.id,
        };
        const prices = model?.prices?.map((item) => {
          return item;
        });
        if (prices != undefined)
        {
          container.AddListSKUs(prices);
        }
        else
        {
          container.AddListSKUs([]);
        }
        setForm(initForm);
        setBrands([initForm.brand]);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  async function Save() {
    form.brandEntity = {};
    const inputData = CreateRequest(form, skuTempState);

    try {
      let response = await UpdatePriceGroupAsync(inputData);
      if (form.brandId == brandTempState?.brand?.id)
      {
        const payload = {
          id: response.data.id,
          name: response.data.name,
          defaultPercent: response.data.defaultPercent,
          defaultReference: response.data.defaultReference,
          amount: response.data.amount,
          status: response.data.status,
          brandId: form.brandId,
          prices: response.data.prices,
        };
        brandContainer.UpdatePriceGroup(payload);
      }
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  return (
    <div className="items-list">
      {loading ? (
        <Loader></Loader>
      ) : (
        <PriceGroupDetails
          form={form}
          setForm={setForm}
          Save={Save}
          brands={brands}
          successfulMessage={`${form.name} successfully updated!`}
        ></PriceGroupDetails>
      )}
    </div>
  );
}
