import { locationTypes } from "../../actions/location/types";

const defaultState = {
  address: undefined,
};

export const LocationReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case locationTypes.ADD_LOCATION_ADDRESS:
      return {
        ...state,
        address: payload,
      };
    case locationTypes.CLEAR_LOCATION_STATE:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
