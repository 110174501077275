import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import BrandDetails from "../../../components/brands/brands-details";
import { GetBrandByIdAsync } from "../../../components/brands/service/BrandService";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../ui/loader";
import { UpdateBrandAsync } from "../../../components/brands/service/BrandService";
import { CreatedPrefix } from "../../../redux/constants";
import { BrandTempContainer } from "../../../components/brands/container";
import { CreateUpdateBrandRequest } from "../../../components/brands/service/HelpService";
export default function BrandEditPage() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const container = BrandTempContainer(dispatch);
  const brandTempState = useSelector((state) => state.brandTempReducer);
  const { id } = useParams();
  const [form, setForm] = useState({});
  
  const getBrand = async (id) => {
    try {
    let response = await GetBrandByIdAsync(id);
    // console.log(response);
    return response.data;
  } 
  catch (error) {
    console.log("inside of catch error of getBrand func");
    console.error(error);
  }
  };
  function setTheForm(value)
  {
    setForm(value);
  }
  useEffect(() => {
    (async () => {
      try {
          setLoading(true);
         if (brandTempState?.brand?.id != id)
          {
            let brandResponse = await getBrand(id);
            fillForm(brandResponse);
          }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    })();
  }, []);

  function fillForm(brand) {
    try{
    const modifyForm = (brand) => {
    brand.logoUrl = brand?.logoUrl === undefined || brand?.logoUrl === null ? "" : brand.logoUrl;
      return brand;
    };

    setForm(modifyForm(brand));
    const items = brand.items.map((item) => {
      return item;
    });
    const uniqueItems = [...new Set(items)];
    container.SelectItems(uniqueItems);

    const brandAccesses = brand.dealers.map((item) => {
      item.brandId = item?.brand?.id;
      return item;
    });
    const uniqueBrandAccesses = [...new Set(brandAccesses)];
    container.SelectBrandAccesses(uniqueBrandAccesses);

    const pricingLevels = brand?.pricingLevels?.map((item) => {
      return item;
    });
    const uniquePricingLevels = [...new Set(pricingLevels)];
    container.SelectPricingLevels(uniquePricingLevels);
    
    const owner = brand?.owner;
    container.AddOwner(owner);
    // container.SelectBrandAccess(brandAccesses);
    // container.SelectBrandsAccesses(brandAccesses);

    // clear unnecessary data
    brand.owner = [];
    brand.items = [];
    brand.pricingLevels = [];
    brand.brandAccesses = [];
    container.FillBrand(brand);
    
  } catch (error) {
    console.error(error);
  }
  }

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      ["brandView"]: brandTempState.brand,
      ["brandAccessView"]: brandTempState.brandAccesses,
      ["itemsView"]: brandTempState.items,
      ["ownerView"]: brandTempState.owner,
      ["pricingLevelsView"]: brandTempState.pricingLevels
    }));
  }, [brandTempState]);

  async function Save() {
    const brand = CreateUpdateBrandRequest(
      form,
      brandTempState
    );
    try {
      console.log("Save after try");
      const response = await UpdateBrandAsync(brand);
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
  return (
    <div className="items-list">
      {loading ? (
        <Loader></Loader>
      ) : (
        <BrandDetails
          form={form}
          setForm={setTheForm}
          Save={Save}
          successfulMessage={`${form.name} successfully updated!`}
        ></BrandDetails>
      )}
    </div>
  );
}
