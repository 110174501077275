import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomerTempContainer } from "../../../../../components/customers/customer-details/container";
import { useState } from "react";
import AddressDetails from "../../../../../components/customers/customer-details/address-details";
import { LocationContainer } from "../container";

export default function LocationAddressEditPage() {
  const locationState = useSelector((state) => state.locationReducer);
  const address = locationState.address;
  const initForm = !!address ? address : {};
  const [form, setForm] = useState(initForm);

  const dispatch = useDispatch();
  const container = LocationContainer(dispatch);
  function saveForm() {
    container.AddAddress(form);
  }
  return (
    <div>
      <AddressDetails
        form={form}
        setForm={setForm}
        saveForm={saveForm}
      ></AddressDetails>
    </div>
  );
}
