import "./style.css";
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { LoginAsync } from "./services/AuthService";
import { useDispatch } from "react-redux";
import { AuthContainer } from "./services/AuthContainer";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../../ui/CustomAlert";

export default function Login() {
    const [message, setMessage] = useState("");
    const [variant, setVariant] = useState("success");
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const container = AuthContainer(dispatch);
    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({});
    const [passwordValid, setPasswordValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);


    const isEmailValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isPasswordValid = (password) => {
        return password != null; // This seems to be a placeholder condition; you might need to adjust it as per your requirements
    };

    const handleInput = (event) => {
        const { name, value } = event.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
        if (name === "email") {
            setEmailValid(event.target.checkValidity());
        }

        if (name === "password") {
            setPasswordValid(event.target.checkValidity());
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const inputForm = event.currentTarget;

        if (
            inputForm.checkValidity() === true &&
            isEmailValid(form.email) &&
            emailValid &&
            isPasswordValid(form.password) &&
            passwordValid
        ) {
            try {
                let resp = await LoginAsync(form);
                console.log(resp.status);
                if (resp.status === 200) {
                    setMessage("Login successful");
                    setVariant("success");
                    setShowAlert(true);
                    navigate("/customers");
                    zeroingVariables();
                    container.loginSuccesful(resp.data);
                }
            } catch (error) {
                if (error.response) {
                    console.log(error)
                    if (error.response.status === 401 || error.response.status === 400) {
                        setMessage("Invalid email or password. Please try again.");
                    } else if (error.response.status === 403) {
                        setMessage("Access denied. Please check your credentials.");
                    } else if (error.response.status >= 500) {
                        setMessage("Server Error. Try connecting slightly later.");
                    }
                } else if (error.request) {
                    setMessage("No response from the server. Please check your internet connection.");
                } else {
                    setMessage("Unexpected error occurred. Please try again later.");
                }

                setVariant("danger");
                setShowAlert(true);
            }
        }

        setValidated(true);
    };

    const zeroingVariables = () => {
        setForm({});
    };

    return (
        <div className="login">
            <div className="wrapper">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <CustomAlert
                        show={showAlert}
                        setShow={setShowAlert}
                        message={message}
                        variant={variant}
                    ></CustomAlert>
                    <Form.Label style={{ fontSize: 38, color: "#3D3A3B" }}>
                        Login
                    </Form.Label>
                    <Form.Group style={{ marginBottom: 15, fontSize: 18 }}>
                        <Form.Label style={{ color: "#BCBEC0" }}>Email</Form.Label>
                        <Form.Control
                            style={{
                                backgroundColor: "#EDF0F5",
                                borderColor: emailValid ? "#BCBEC0" : "#dc3545",
                            }}
                            placeholder="example@gmail.com"
                            type="email"
                            required
                            onChange={handleInput}
                            value={form.email || ""}
                            name="email"
                            isInvalid={!emailValid}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.email === ""
                                ? "Please provide your email."
                                : emailValid
                                    ? "Please provide a valid email address."
                                    : "Invalid email format."}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: 20, fontSize: 18 }}>
                        <Form.Label style={{ color: "#BCBEC0" }}>Password</Form.Label>
                        <Form.Control
                            style={{
                                backgroundColor: "#EDF0F5",
                                borderColor: passwordValid ? "#BCBEC0" : "#dc3545",
                            }}
                            type="password"
                            placeholder="Password"
                            required
                            onChange={handleInput}
                            value={form.password || ""}
                            name="password"
                            isInvalid={!passwordValid}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.password === ""
                                ? "Please provide your password."
                                : isPasswordValid(form.password)
                                    ? "Please provide a valid password."
                                    : "Invalid password format."}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className="d-grid gap-2">
                        <Button
                            style={{
                                borderColor: "black",
                                fontWeight: 500,
                                height: "5vh",
                                backgroundColor: "#003465",
                            }}
                            size="lg"
                            variant="primary"
                            type="submit"
                            disabled={!emailValid || !passwordValid}
                        >
                            Sign in
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
}