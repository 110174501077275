import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

const CreatableAutocomplete = ({
  options,
  onCreate,
  label,
  name,
  form,
  setForm,
  error,
  disabled,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleCreateOption = () => {
    if (inputValue.trim() !== "") {
      onCreate(inputValue);
      setInputValue("");
    }
  };

  return (
    <Autocomplete
      name={name}
      disabled={disabled}
      {...props}
      options={options}
      inputValue={inputValue}
      value={form[name] || ""}
      onInputChange={handleInputChange}
      onChange={(e, v) => {
        setForm((prevForm) => ({
          ...prevForm,
          [name]: v,
        }));
      }}
      freeSolo
      renderInput={(params) => (
        <TextField
          error={error}
          helperText={error}
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {inputValue.trim() !== "" && !options.includes(inputValue) && (
                  <Button variant="dark" onClick={handleCreateOption}>
                    Create
                  </Button>
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CreatableAutocomplete;
