import { useParams } from "react-router-dom";
import CustomerDetails from "../../../components/customers/customer-details";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import {
  GetCustomerByIdAsync,
  UpdateCustomerAsync,
} from "../../../components/customers/service/CustomerService";
import { useDispatch, useSelector } from "react-redux";
import { CustomerTempContainer } from "../../../components/customers/customer-details/container";
import { GetAddressView } from "../../../common/services/view-service";
import { CreateUpdateCustomerRequest } from "../../../components/customers/service/HelpService";
import Loader from "../../../ui/loader";
import {
  CreateAddressAsync,
  UpdateAddressAsync,
} from "../../../common/addressServices";
import { CreatedPrefix } from "../../../redux/constants";
export default function CustomerEditPage() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);
  const customerTempState = useSelector((state) => state.customerTempReducer);
  const statusState = useSelector((state) => state.statusReducer);
  const { id } = useParams();
  const [form, setForm] = useState({});
  
  const getCustomer = async (id) => {
    let response = await GetCustomerByIdAsync(id);
    return response.data;
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (!!!customerTempState.customer) {
          let customerResponse = await getCustomer(id);
          fillForm(customerResponse);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  function fillForm(customer) {
    const modifyForm = (customer) => {
      customer.phone = customer?.phoneNumber?.number;
      customer.countrytel = `${customer.phoneNumber?.countryTelCode} ${customer.phoneNumber?.shortenedName}`;
      customer.isDealer = false;
      customer.is3PLCustomer = false;
      customer.lastTimeSyncedWithShopifyCatalog =
        !!customer.lastTimeSyncedWithShopifyCatalog
          ? moment(customer.lastTimeSyncedWithShopifyCatalog).format(
              "DD-MM-YYYY HH:mm:ss"
            )
          : "";
      if (customer.types?.includes("Dealer")) customer.isDealer = true;
      if (customer.types?.includes("ThreePLCustomer"))
        customer.is3PLCustomer = true;
      return customer;
    };

    setForm(modifyForm(customer));
    const addresses = customer.addresses.map((item) => {
      item.phone = item?.phoneNumber?.number;
      item.countrytel = `${item.phoneNumber?.countryTelCode} ${item.phoneNumber?.shortenedName}`;
      return item;
    });
    container.AddAddress(addresses);

    container.SelectBillingAddress(customer.defaultBillingAddress.id);
    container.SelectShippingAddress(customer.defaultShippingAddress.id);
    container.SelectOwnedBrands(customer.ownedBrands);
    const brandAccesses = customer.brandAccesses.map((item) => {
      item.brandId = item?.brand?.id;
      return item;
    });
    container.SelectBrandsAccesses(brandAccesses);
    const warehousesView = customer.warehouses.map((warehouse) => {
      warehouse.locationName = warehouse.location.name;
      warehouse.location = warehouse.location;
      return warehouse;
    });
    container.CreateWarehouse(warehousesView);

    // clear unnecessary data
    customer.ownedBrands = [];
    customer.brandAccesses = [];
    customer.addresses = [];
    customer.warehouses = [];
    container.FillCustomer(customer);
  }

  useEffect(() => {
    const ownedBrandsView = [
      ...customerTempState.ownedBrands,
      ...customerTempState.selectedAssociateBrands,
    ];
    setForm((prevForm) => ({
      ...prevForm,
      ["warehousesView"]: customerTempState.warehouses,
      ["ownedBrandsView"]: ownedBrandsView,
      ["brandAccessView"]: customerTempState.selectedBrandsAccess,
    }));
    let billingAddressId = customerTempState.billingAddressId;
    if (!!billingAddressId || billingAddressId == 0) {
      const billingAddress = customerTempState.addresses.find((address) => {
        if (address.id == billingAddressId) return address;
      });
      setForm((prevForm) => ({
        ...prevForm,
        ["billingaddress"]: GetAddressView(billingAddress),
      }));
    }

    let shippingAddressId = customerTempState.shippingAddressId;
    if (!!shippingAddressId || shippingAddressId == 0) {
      const shippingAddress = customerTempState.addresses.find((address) => {
        if (address.id == shippingAddressId) return address;
      });
      setForm((prevForm) => ({
        ...prevForm,
        ["defaultshippingaddress"]: GetAddressView(shippingAddress),
      }));
    }
  }, [customerTempState]);

  async function Save() {
    const customer = CreateUpdateCustomerRequest(
      form,
      customerTempState,
      statusState
    );

    try {
      customer.defaultPaymentMethodId = 0;
      customer.InvoicesIds = [];
      customer.UsersIds = [];
      const addressIdDict = {};
      customer.addresses.forEach((element) => {
        addressIdDict[element.id] = element.id;
      });
      const addressesToCreate = customer.addresses.filter((item) =>
        item.id.toString().includes(CreatedPrefix)
      );
      const addressesToUpdate = customer.addresses.filter(
        (item) => !item.id.toString().includes(CreatedPrefix)
      );

      for (const element of addressesToUpdate) {
        await UpdateAddressAsync(element);
      }

      for (const element of addressesToCreate) {
        const responseAddress = await CreateAddressAsync(element);
        addressIdDict[element.id] = responseAddress.data;
      }

      customer.defaultBillingAddressId =
        addressIdDict[customer.defaultBillingAddressId];
      customer.defaultShippingAddressId =
        addressIdDict[customer.defaultShippingAddressId];
      customer.ownedWareouses.map((item) => {
        item.addressId = addressIdDict[item.addressId];
        return item;
      });

      customer.addressIds = Object.values(addressIdDict);
      let response = await UpdateCustomerAsync(customer);
      container.ClearTempCustomer();
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  return (
    <div className="items-list">
      {loading ? (
        <Loader></Loader>
      ) : (
        <CustomerDetails
          form={form}
          setForm={setForm}
          Save={Save}
          successfulMessage={`${form.name} successfully updated!`}
        ></CustomerDetails>
      )}
    </div>
  );
}
