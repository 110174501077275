import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { CustomerTempContainer } from "../../../../../components/customers/customer-details/container";
import LocationBookList from "../../../../../components/customers/customer-details/location-book.list";
import { GetLocationsAsync } from "../../../../../common/locationServices";
import Loader from "../../../../../ui/loader";
import { LocationContainer } from "../container";
import { useParams } from "react-router-dom";

export default function LocationsBookPage() {
  const { id } = useParams();
  let warehouses = useSelector((state) => state.customerTempReducer.warehouses);
  let warehouse = warehouses.find((item) => item.id === id);
  let selectedId = !!id ? warehouse?.location.id : 1;
  const [locationsBookView, setLocationsBookView] = useState([]);

  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);
  const locationContainer = LocationContainer(dispatch);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    locationContainer.ClearLocationState();
  }, []);

  const getLocations = async () => {
    let response = await GetLocationsAsync();
    return response.data;
  };
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await getLocations();
        setLocationsBookView(response);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  function Save(id) {
    container.SelectWarehouseLocation(id);
  }
  return (
    <div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <LocationBookList
          save={Save}
          locationsBookView={locationsBookView}
          selectedId={selectedId}
        ></LocationBookList>
      )}
    </div>
  );
}
