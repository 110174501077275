import { call, put, takeEvery } from "redux-saga/effects";
import {
  AddAccessDefaultBrand,
  AddApprovalBrandStatuses,
  AddCountryCodesAddress,
  AddCurrencyCodeCustomer,
  AddPriceReferenceStatuses,
  AddRoles,
  AddVerifiedStatusCustomer,
  statusTypes,
} from "../actions/status/types";
import { GetStatusesAsync } from "../../common/statusService";

function* AddStatusesWorker() {
  try {
    let result = yield call(GetStatusesAsync);
    let data = result.data;
    if (!!data.accessDefaultStatusesBrand)
      yield put(AddAccessDefaultBrand(data.accessDefaultStatusesBrand));
    if (!!data.currencyCodesCustomer)
      yield put(AddCurrencyCodeCustomer(data.currencyCodesCustomer));
    if (!!data.verifiedStatusesCustomer)
      yield put(AddVerifiedStatusCustomer(data.verifiedStatusesCustomer));
    if (!!data.countriesStatusesAddress)
      yield put(AddCountryCodesAddress(data.countriesStatusesAddress));
    if (!!data.approvalBrandStatuses)
      yield put(AddApprovalBrandStatuses(data.approvalBrandStatuses));
    if (!!data.priceReferenceStatuses)
      yield put(AddPriceReferenceStatuses(data.priceReferenceStatuses));
    if (!!data.roles) yield put(AddRoles(data.roles));
  } catch (error) {
    console.error(error);
  }
}

export default function* statusWatcher() {
  yield takeEvery(statusTypes.ASYNC_GET_STATUSES, AddStatusesWorker);
}
