import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import DataTable from "react-data-table-component";
import { paymentTableColums } from "./colums";
import * as AiIcons from "react-icons/ai";
import { dataTableCustomStyles } from "../../../common/datatable/styles";
import routes from "../../../routes";
import { useDispatch, useSelector } from "react-redux";
import { CustomerTempContainer } from "./container";
import { GetDefaultPricingLevelView } from "../../../common/services/view-service";
import AutoComplete from "../../../ui/AutoComplete";
import PhoneTextField from "../../../ui/PhoneComponent";
import { toast } from "react-toastify";
import { Messages } from "../../../redux/constants";
import {
    SyncCustomerAsync,
    CreateOrUpdateCustomerInShopifyAccountAsync,
    PushCustomerToShopifyAsync,
} from "../service/CustomerService";
import { GetBrandByIdAsync } from "../../brands/service/BrandService";
export default function CustomerCreate({
    form,
    setForm,
    Save,
    successfulMessage,
}) {
    let disablePushToShopifyBtn = true;
    if (
        window.location.href.includes("customers") &&
        window.location.href.includes("edit")
    ) {
        disablePushToShopifyBtn = false;
    }

    const statusReducer = useSelector((state) => state.statusReducer);
    const verifiedStatus = statusReducer.verifiedStatuses;
    const currencyCode = statusReducer.currencyCodes;
    const dispatch = useDispatch();
    const container = CustomerTempContainer(dispatch);
    const [error, setError] = useState({});
    const [beenSubmitted, setBeenSubmitted] = useState(false);
    const [rowToDeleteId, setrowToDeleteId] = useState(-1);
    const [rowToDeleteWarehouseId, setrowToDeleteWarehouseId] = useState(-1);
    const [rowToDeleteBrandAccessId, setRowToDeleteBrandAccessId] = useState(-1);
    const navigate = useNavigate();
    const zeroingvaliables = () => {
        setForm({});
    };
    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const validate = () => {
        const allErrors = {};
        zeroingerrors();
        if (!form.email) {
            allErrors.email = "Please write a company email!";
        } else {
            const isEmailValid = isValidEmail(form.email);

            if (!isEmailValid) {
                allErrors.email = "Email is not valid.";
            }
        }
        if (!form.verifiedStatus) {
            allErrors.verifiedStatus = "Please choose an account status!";
        }
        if (!form.name) {
            allErrors.name = "Please write a company name!";
        }
        if (!form.phone) {
            allErrors.phone = "Please write a phone!";
        } else {
            if (form.phone.length < 9)
                allErrors.phone =
                    "Incorrect phone number! (must be at least 9 characters)";
        }
        if (!form.countrytel) {
            allErrors.phone = "Please choose a phone country code!";
        }
        if (!form.bcNo) {
            allErrors.bcNo = "Please write a bcNo!";
        }
        if (!form.currencyCode) {
            allErrors.currencyCode = "Please choose an currency status!";
        }
        if (!form.billingaddress) {
            allErrors.billingaddress = "Please write a billing address!";
        }
        if (!form.defaultshippingaddress) {
            allErrors.defaultshippingaddress =
                "Please write a default shipping address!";
        }
        setError(allErrors);
    };
    useEffect(() => {
        validate();
    }, [form]);
    const zeroingerrors = () => {
        setError({});
    };

    const warehousesTableColums = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "3PL",
            selector: (row) => !!row.is3PL + "",
            sortable: true,
        },
        {
            name: "Private",
            selector: (row) => !!row.isPrivate + "",
            sortable: true,
        },
        {
            name: "Location",
            selector: (row) => row.locationName,
            sortable: true,
        },
        {
            name: "Edit",
            button: true,
            cell: (row) => (
                <Link to={`warehouse/${row.id}/edit`}>
                    <Button style={{ width: "4vw" }} variant="light">
                        Edit
                    </Button>
                </Link>
            ),
        },
        {
            name: "Delete",
            button: true,
            cell: (row) =>
                rowToDeleteWarehouseId != row.id ? (
                    <Button
                        onClick={() => ConfidenceToDeleteWarehouse(row.id)}
                        style={{ width: "5vw" }}
                        variant="light"
                    >
                        Delete
                    </Button>
                ) : (
                    <div className="delete-container">
                        <Button
                            variant="light"
                            style={{ width: "2vw", marginRight: "3px" }}
                            onClick={() => RemoveWarehouse(rowToDeleteWarehouseId)}
                        >
                            <AiIcons.AiOutlineCheck></AiIcons.AiOutlineCheck>
                        </Button>
                        <Button
                            onClick={() => NotSureToDeleteWarehous()}
                            variant="light"
                            style={{ width: "2.5vw", alignItems: "center" }}
                        >
                            <AiIcons.AiOutlineClose></AiIcons.AiOutlineClose>
                        </Button>
                    </div>
                ),
        },
    ];

    function RemoveWarehouse(rowId) {
        container.RemoveWarehouse(rowId);
        setrowToDeleteWarehouseId(-1);
    }
    function NotSureToDeleteWarehous() {
        setrowToDeleteWarehouseId(-1);
    }
    function ConfidenceToDeleteWarehouse(id) {
        setrowToDeleteWarehouseId(id);
    }

    const ownedBrandsTableColums = [
        {
            name: "Brand",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Access Default",
            selector: (row) => row.accessDefault,
            sortable: true,
        },
        {
            name: "Allow Drop Ship",
            selector: (row) => row.dropshipAvailable + "",
            sortable: true,
        },
        {
            name: "Default Price Level",
            selector: (row) =>
                !!row.defaultPricingLevel ? row.defaultPricingLevel.name : "",
            sortable: true,
        },

        {
            name: "Default Price Level value",
            selector: (row) =>
                !!row.defaultPricingLevel
                    ? GetDefaultPricingLevelView(row.defaultPricingLevel)
                    : "",
            sortable: true,
        },
        {
            name: "Edit",
            button: true,
            cell: (row) => (
                <Link to={`ownedBrands/${row.id}/edit`}>
                    <Button style={{ width: "4vw" }} variant="light">
                        Edit
                    </Button>
                </Link>
            ),
        },
        {
            name: "Remove",
            button: true,
            cell: (row) =>
                rowToDeleteId != row.id ? (
                    <Button
                        onClick={() => ConfidenceToAssosiateBrand(row.id)}
                        style={{ width: "5vw" }}
                        variant="light"
                    >
                        Remove
                    </Button>
                ) : (
                    <div className="delete-container">
                        <Button
                            variant="light"
                            style={{ width: "2vw", marginRight: "3px" }}
                            onClick={() => RemoveAssosiateBrand(rowToDeleteId)}
                        >
                            <AiIcons.AiOutlineCheck></AiIcons.AiOutlineCheck>
                        </Button>
                        <Button
                            onClick={NotSureToAssosiateBrand}
                            variant="light"
                            style={{ width: "2.5vw", alignItems: "center" }}
                        >
                            <AiIcons.AiOutlineClose></AiIcons.AiOutlineClose>
                        </Button>
                    </div>
                ),
        },
    ];

    function RemoveAssosiateBrand(rowId) {
        container.RemoveAssosiateBrand(rowId);
        setrowToDeleteId(-1);
    }
    function NotSureToAssosiateBrand() {
        setrowToDeleteId(-1);
    }
    function ConfidenceToAssosiateBrand(id) {
        setrowToDeleteId(id);
    }

    const brandAccessTableColums = [
        {
            name: "Brand",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Access Default",
            selector: (row) => row.accessDefault,
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
        },
        {
            name: "Pricing Level",
            selector: (row) =>
                !!row.defaultPricingLevel
                    ? row.defaultPricingLevel.name
                    : !!row.pricingLevel
                        ? row.pricingLevel.name
                        : "",
            sortable: true,
        },
        {
            name: "Pricing Level value",
            selector: (row) =>
                !!row.defaultPricingLevel
                    ? GetDefaultPricingLevelView(row.defaultPricingLevel)
                    : !!row.pricingLevel
                        ? GetDefaultPricingLevelView(row.pricingLevel)
                        : "",
            sortable: true,
        },
        {
            name: "Edit",
            button: true,
            cell: (row) => (
                <Link to={`brandaccess/${row.id}/edit`}>
                    <Button style={{ width: "4vw" }} variant="light">
                        Edit
                    </Button>
                </Link>
            ),
        },
        {
            name: "Remove",
            button: true,
            cell: (row) =>
                rowToDeleteBrandAccessId != row.id ? (
                    <Button
                        onClick={() => ConfidenceToBrandAccess(row.id)}
                        style={{ width: "5vw" }}
                        variant="light"
                    >
                        Delete
                    </Button>
                ) : (
                    <div className="delete-container">
                        <Button
                            variant="light"
                            style={{ width: "2vw", marginRight: "3px" }}
                            onClick={() => RemoveBrandAccess(rowToDeleteBrandAccessId)}
                        >
                            <AiIcons.AiOutlineCheck></AiIcons.AiOutlineCheck>
                        </Button>
                        <Button
                            onClick={() => NotSureToBrandAccess()}
                            variant="light"
                            style={{ width: "2.5vw", alignItems: "center" }}
                        >
                            <AiIcons.AiOutlineClose></AiIcons.AiOutlineClose>
                        </Button>
                    </div>
                ),
        },
    ];

    function RemoveBrandAccess(rowId) {
        container.RemoveBrandAccess(rowId);
        setRowToDeleteBrandAccessId(-1);
    }
    function NotSureToBrandAccess() {
        setRowToDeleteBrandAccessId(-1);
    }
    function ConfidenceToBrandAccess(id) {
        setRowToDeleteBrandAccessId(id);
    }

    const handleInput = (event) => {
        const { target } = event;
        const value = target.type === "checkbox" ? target.checked : target.value;

        if (target.name == "phone") {
            const onlyNums = value.replace(/[^0-9]/g, "");

            if (onlyNums.length <= 10) {
                setForm((prevForm) => ({
                    ...prevForm,
                    [target.name]: onlyNums,
                }));
            }
            return;
        }

        setForm((prevForm) => ({
            ...prevForm,
            [target.name]: value,
        }));
    };

    const handleSubmit = (e) => {
        const id = toast.loading(
            "Please wait...",
            {
                closeButton: true,
            });
        let message = "Unexpected error occurred. Please try again later.";
        e.preventDefault();
        e.stopPropagation();
        setBeenSubmitted(true);
        if (Object.keys(error).length === 0) {
            (async () => {
                try {
                    console.log("Customer creation started:");
                    let response = await Save();

                    if (response.status == 200) {
                        toast.update(id, {
                            render: successfulMessage,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000,
                        });

                        zeroingvaliables();
                        setBeenSubmitted(false);
                        GoBack();
                    }

                    if (!!response?.response?.data) {
                        toast.update(id, {
                            render: response.response.data,
                            type: "error",
                            isLoading: false,
                            autoClose: 5000,
                        });
                    }
                } catch (error) {
                    console.log("During customer creating the error occured: ");
                    if (error.response && error.response.data && typeof error.response.data === 'string') {
                        console.log("Error response data is string:", error.response.data);
                        message = error.response.data;
                    } else if (error.response && error.response.data && error.response.data.message) {
                        console.log("Error response data message:", error.response.data.message);
                        message = error.response.data.message;
                    } else {
                        message = "No response from the server. Please check your internet connection.";
                    }
                }
            })();
        } else {
            toast.update(id, {
                render: String(message),
                type: "error",
                isLoading: false,
                autoClose: 5000,
            });
        }
    };
    function GoBack() {
        navigate(-1);
    }

    async function CreateOrUpdateCustomerToShopifyAccount() {
        const idToast = toast.loading("Please wait...", {
            closeButton: true,
        });

        try {
            let response = await CreateOrUpdateCustomerInShopifyAccountAsync(form.id);
            if (response.status === 200) {
                let data = response.data;
                if (data.isSuccess) {
                    toast.update(idToast, {
                        render: data.message,
                        type: "success",
                        isLoading: false,
                        autoClose: 5000,
                    });
                } else {
                    toast.update(idToast, {
                        render: data.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                    });
                }
            }
        } catch (error) {
            let message = "Unexpected error occurred. Please try again later.";
            if (error.response && error.response.data && typeof error.response.data === 'string') {
                console.log("Error response data is string:", error.response.data);
                message = error.response.data;
            } else if (error.response && error.response.data && error.response.data.message) {
                console.log("Error response data message:", error.response.data.message);
                message = error.response.data.message;
            } else {
                message = "No response from the server. Please check your internet connection.";
            }

            toast.update(idToast, {
                render: String(message),
                type: "error",
                isLoading: false,
                autoClose: 5000,
            });
        }
    }
    return (
        <div className="details-container">
            <Outlet></Outlet>
            <Form noValidate onSubmit={handleSubmit}>
                <div className="header">
                    <h1>Company Setup</h1>
                    <div>
                        <Button
                            disabled={disablePushToShopifyBtn}
                            onClick={CreateOrUpdateCustomerToShopifyAccount}
                            className="action-button"
                            style={{
                                width: "13vw",
                            }}
                            variant="light"
                        >
                            Push to shopify
                        </Button>
                        <Button
                            style={{
                                width: "13vw",
                            }}
                            variant="light"
                            onClick={async () => await SyncCustomer(form.id)}
                        >
                            Sync with shopify
                        </Button>

                        <Button onClick={GoBack} className="action-button" variant="light">
                            Back
                        </Button>
                    </div>
                </div>
                <div className="section">
                    <h2>Company Details</h2>
                    <div className="form">
                        <div className="info-input-section">
                            <div className="margin-styles">
                                <div className="form-container">
                                    <TextField
                                        className="text-field-styles"
                                        error={beenSubmitted ? error.email : null}
                                        helperText={beenSubmitted ? error.email : null}
                                        label="Email"
                                        required
                                        type="text"
                                        placeholder="customer@gmail.com"
                                        name="email"
                                        value={form.email || ""}
                                        onChange={handleInput}
                                    />
                                </div>
                            </div>
                            <div className="margin-styles">
                                <div className="form-container">
                                    <AutoComplete
                                        styles={{ width: "19.5vw" }}
                                        className="text-field-styles"
                                        error={beenSubmitted ? error.verifiedStatus : null}
                                        name={"verifiedStatus"}
                                        form={form}
                                        setForm={setForm}
                                        inputData={verifiedStatus}
                                        label={"Account Status"}
                                    ></AutoComplete>
                                    <PhoneTextField
                                        autocompleteStyles={{ width: "8vw" }}
                                        form={form}
                                        setForm={setForm}
                                        handleInput={handleInput}
                                        error={beenSubmitted ? error.phone : null}
                                    ></PhoneTextField>
                                </div>
                            </div>
                            <div className="margin-styles">
                                <div className="form-container">
                                    <TextField
                                        className="text-field-styles"
                                        error={beenSubmitted ? error.name : null}
                                        helperText={beenSubmitted ? error.name : null}
                                        label="Name"
                                        required
                                        type="text"
                                        placeholder="SG Racing"
                                        name="name"
                                        value={form.name || ""}
                                        onChange={handleInput}
                                    />

                                    <TextField
                                        className="text-field-styles"
                                        error={beenSubmitted ? error.dealerguid : null}
                                        helperText={beenSubmitted ? error.dealerguid : null}
                                        label={"Stripe Id"}
                                        type="text"
                                        placeholder="cus_Op7eqtu6k0rRP6"
                                        name="stripeId"
                                        value={form.stripeId || ""}
                                        onChange={handleInput}
                                    />
                                </div>
                            </div>
                            <div className="margin-styles">
                                <div className="form-container">
                                    <TextField
                                        className="text-field-styles"
                                        error={beenSubmitted ? error.bcNo : null}
                                        helperText={beenSubmitted ? error.bcNo : null}
                                        label={"Business Central No"}
                                        required
                                        type="text"
                                        placeholder="C12234"
                                        name="bcNo"
                                        value={form.bcNo || ""}
                                        onChange={handleInput}
                                    />
                                    <TextField
                                        className="text-field-styles"
                                        error={beenSubmitted ? error.shopifyId : null}
                                        helperText={beenSubmitted ? error.shopifyId : null}
                                        label={"Shopify Id"}
                                        type="text"
                                        placeholder="7400311193896"
                                        name="shopifyId"
                                        value={form.shopifyId || ""}
                                        onChange={handleInput}
                                    />
                                </div>
                            </div>
                            <div className="margin-styles">
                                <div className="form-container">
                                    <AutoComplete
                                        styles={{ width: "19.5vw" }}
                                        className="text-field-styles"
                                        error={beenSubmitted ? error.currencyCode : null}
                                        name={"currencyCode"}
                                        form={form}
                                        setForm={setForm}
                                        inputData={currencyCode}
                                        label={"Currency Code"}
                                    ></AutoComplete>
                                    <TextField
                                        className="text-field-styles"
                                        error={
                                            beenSubmitted ? error.shopifyCompanyLocationId : null
                                        }
                                        helperText={
                                            beenSubmitted ? error.shopifyCompanyLocationId : null
                                        }
                                        label={"Shopify Company Location Id"}
                                        type="text"
                                        placeholder="15350268200"
                                        name="shopifyCompanyLocationId"
                                        value={form.shopifyCompanyLocationId || ""}
                                        onChange={handleInput}
                                    />
                                    <TextField
                                        className="text-field-styles"
                                        label={"LastTimeSyncedWithShopifyCatalog"}
                                        type="text"
                                        name="shopifyCompanyLocationId"
                                        value={form.lastTimeSyncedWithShopifyCatalog || ""}
                                    />
                                </div>
                            </div>
                            <div className="form-container">
                                <div className="margin-styles">
                                    <div className="form-container">
                                        <TextField
                                            required
                                            error={beenSubmitted ? error.billingaddress : null}
                                            helperText={beenSubmitted ? error.billingaddress : null}
                                            label={"Billing Address"}
                                            name="billingaddress"
                                            value={form.billingaddress || ""}
                                            style={{ width: "40vw" }}
                                            type="text"
                                            placeholder="3405 SPEEDWAY  AUSTIN TX 78705-1500 USA"
                                        />
                                        <Link to={routes.addressBooks}>
                                            <Button className="action-button" variant="dark">
                                                Address Book
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="form-container">
                                <Form.Group>
                                    <div className="form-container">
                                        <TextField
                                            required
                                            error={
                                                beenSubmitted ? error.defaultshippingaddress : null
                                            }
                                            helperText={
                                                beenSubmitted ? error.defaultshippingaddress : null
                                            }
                                            label={"Default Shipping Address"}
                                            name="defaultshippingaddress"
                                            value={form.defaultshippingaddress || ""}
                                            style={{ width: "40vw" }}
                                            type="text"
                                            placeholder="3405 SPEEDWAY  AUSTIN TX 78705-1500 USA"
                                        />

                                        <Link to={routes.shippingAddressBooks}>
                                            <Button className="action-button" variant="dark">
                                                Address Book
                                            </Button>
                                        </Link>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="section">
                    <h2>Dealer Payment Terms</h2>
                    <div className="form">
                        <Form.Group className="text-field-styles">
                            <Form.Text>Dealer Features</Form.Text>
                            <Form.Check
                                name="isDealer"
                                type="checkbox"
                                label="Dealer Access"
                                value={form.isDealer}
                                checked={form.isDealer}
                                onChange={handleInput}
                            />
                        </Form.Group>
                        {form.isDealer ? (
                            <>
                                <Form.Group className="custom-table-styles">
                                    <Form.Text>Payment Methods</Form.Text>
                                    <DataTable
                                        columns={paymentTableColums}
                                        data={form.paymentMethods}
                                        pagination
                                        customStyles={dataTableCustomStyles}
                                    ></DataTable>
                                </Form.Group>
                                <Form.Group
                                    className="custom-table-styles"
                                    style={{ marginBottom: "3vh", marginTop: "3vh" }}
                                >
                                    <div>
                                        <Link to={routes.addBrandToCustomer}>
                                            <Button
                                                className="action-button"
                                                style={{
                                                    marginLeft: "85%",
                                                }}
                                                variant="light"
                                            >
                                                Add Brand
                                            </Button>
                                        </Link>

                                        <Form.Text>Brand Access</Form.Text>
                                    </div>

                                    <DataTable
                                        columns={brandAccessTableColums}
                                        data={form.brandAccessView}
                                        customStyles={dataTableCustomStyles}
                                        pagination
                                    ></DataTable>

                                    <Form.Check
                                        type="checkbox"
                                        name={"useNetTerms"}
                                        label="Use Net Terms"
                                        value={form.useNetTerms}
                                        checked={form.useNetTerms}
                                        onChange={handleInput}
                                    />
                                </Form.Group>
                            </>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
                <hr />
                <div className="section">
                    <h2>Owned Brands</h2>
                    <div className="form">
                        <Form.Group className="text-field-styles">
                            <Form.Text>3PL Logistics Features</Form.Text>
                            <Form.Check
                                type="checkbox"
                                name={"is3PLCustomer"}
                                label="Third Party Logistics"
                                value={form.is3PLCustomer}
                                checked={form.is3PLCustomer}
                                onChange={handleInput}
                            />
                        </Form.Group>
                        {form.is3PLCustomer ? (
                            <>
                                <Form.Group className="custom-table-styles">
                                    <div>
                                        <Link to={routes.associateBrandWithCustomer}>
                                            <Button
                                                className="action-button"
                                                style={{
                                                    marginLeft: "85%",
                                                }}
                                                variant="light"
                                            >
                                                Associate Brand
                                            </Button>
                                        </Link>

                                        <Form.Text>Owned Brands</Form.Text>
                                    </div>

                                    <DataTable
                                        columns={ownedBrandsTableColums}
                                        data={form.ownedBrandsView}
                                        customStyles={dataTableCustomStyles}
                                        pagination
                                    ></DataTable>
                                </Form.Group>
                                <Form.Group
                                    className="custom-table-styles"
                                    style={{ marginBottom: "3vh", marginTop: "3vh" }}
                                >
                                    <div>
                                        <Link to={routes.warehouseNew}>
                                            <Button
                                                className="action-button"
                                                style={{
                                                    marginLeft: "85%",
                                                }}
                                                variant="light"
                                            >
                                                Add Warehouse
                                            </Button>
                                        </Link>

                                        <Form.Text>Warehouses</Form.Text>
                                    </div>

                                    <DataTable
                                        columns={warehousesTableColums}
                                        data={form.warehousesView}
                                        customStyles={dataTableCustomStyles}
                                        pagination
                                    ></DataTable>
                                </Form.Group>
                            </>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
                <div className="actions-buttons">
                    <Button
                        className="action-button"
                        style={{
                            height: "4vh",
                            marginRight: "1vw",
                        }}
                        type="submit"
                        variant="dark"
                    >
                        Save
                    </Button>
                    <Button onClick={GoBack} className="action-button cancel-btn">
                        Cancel
                    </Button>
                </div>
            </Form>
        </div>
    );
}
async function SyncCustomer(id) {
    const idToast = toast.loading("Please wait...", {
        closeButton: true,
    });

    try {
        let response = await SyncCustomerAsync(id);
        if (response.status == 200) {
            toast.update(idToast, {
                render: response.data.message,
                type: "success",
                isLoading: false,
                autoClose: 5000,
            });
        }
    } catch (error) {
        let message = "Unexpected error occurred. Please try again later.";

        if (error.response) {
            if (error.response.status === 400) {
                message = "Bad Request. Please check your input data.";
            } else if (error.response.status === 401) {
                message = "Unauthorized. Please check your credentials.";
            } else if (error.response.status === 403) {
                message = "Access denied. Please check your permissions.";
            } else if (error.response.status === 404) {
                message = "Resource not found.";
            } else if (error.response.status >= 500) {
                message = "Server Error. Try connecting slightly later.";
            }

            // Extract additional information from the response if available
            const responseData = error.response.data;
            if (responseData) {
                message += ` ${String(error.response.data.message)}`;
            }
        } else if (error.request) {
            message = "No response from the server. Please check your internet connection.";
        }

        toast.update(idToast, {
            render: message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
        });
    }
}