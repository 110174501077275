import { api } from "../../api/axios";

const controller = "Location";

export async function GetLocationsAsync() {
  return await api.get(`/${controller}/GetLocations`);
}

export async function CreateLocationAsync(data) {
  return await api.post(`/${controller}/CreateLocation`, data);
}

export async function UpdateLocationAsync(data) {
  return await api.put(`/${controller}/UpdateLocation`, data);
}

export async function GetLocationByIdAsync(id) {
  return await api.get(`/${controller}/GetLocation/${id}`);
}
