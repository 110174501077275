import { Navigate, Outlet, useLocation } from "react-router-dom";
import Sidebar from "../side-bar/Sidebar";
import { useSelector } from "react-redux";
import "./Layout.css";
import { useState } from "react";
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";
export default function Layout() {
  injectStyle();
  let isLoggedIn = useSelector((state) => state.authReducer.authenticated);
  let location = useLocation();

  const [isExpanded, setExpendState] = useState(false);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isLoggedIn ? (
        <div>
          <Sidebar
            isExpanded={isExpanded}
            setExpendState={setExpendState}
          ></Sidebar>
          <div className={"content " + (isExpanded ? "open" : "closed")}>
            <Outlet />
          </div>
        </div>
      ) : (
        <Navigate to="login" state={{ from: location }} replace />
      )}
    </div>
  );
}
