import { AiFillSignal } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { CiBag1 } from "react-icons/ci";
import { RiShieldUserLine } from "react-icons/ri";
import { RiShoppingCartFill } from "react-icons/ri";
export const SidebarData = [
  {
    title: "Admins",
    path: "/admins",
    icon: <RiShieldUserLine className="img" />,
  },
  {
    title: "Customers",
    path: "/customers",
    icon: <BsFillPeopleFill className="img" />,
  },
  {
    title: "Price Groups",
    path: "/price-groups",
    icon: <AiFillSignal className="img" />,
  },
  {
    title: "Items",
    path: "/items",
    icon: <CiBag1 className="img" />,
  },
  {
    title: "Brands",
    path: "/brands",
    icon: <RiShoppingCartFill className="img" />,
  },
];
