import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import FilterComponent from "../../../ui/filter";
import { dataTableCustomStyles } from "../../../common/datatable/styles";
import { GetItemViewModel } from "../../../common/price-groups.services/helpeService";
import { GetItemsPagedAsync } from "../services";

export default function ItemsList() {
  const [loading, setLoading] = useState(false);
  const [itemsView, setItemsView] = useState([]);
  const [filterSKUText, setFilterSKUText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const filteredItems = getFiltredItems();
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    // Clear any existing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTimeout = setTimeout(() => {
      if (filterSKUText !== "") {
        fetchItems(page, pageSize, filterSKUText);
      } else {
        fetchItems(page, pageSize);
      }
    }, 1000);

    setTypingTimeout(newTimeout);
  }, [filterSKUText]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        fetchItems(page, pageSize);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  const getItemsPaged = async (page, pageSize, SKU) => {
    let response = await GetItemsPagedAsync(page, pageSize, SKU);
    return response.data;
  };

  const fetchItems = async (page, pageSize, SKU = "") => {
    try {
      setLoading(true);
      const response = await getItemsPaged(page, pageSize, SKU);
      setItemsView([
        ...response.items.map((item) => {
          return GetItemViewModel(item);
        }),
      ]);
      setTotalRows(response.meta.totalCount);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchItems(page, pageSize, filterSKUText);
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchItems(page, newPerPage, filterSKUText);
    setPage(page);
    setPageSize(newPerPage);
  };

  function getFiltredItems() {
    let items = itemsView.filter(
      (item) =>
        item.sku && item.sku.toLowerCase().includes(filterSKUText.toLowerCase())
    );

    return items;
  }
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterSKUText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterSKUText("");
      }
    };

    return (
      <div className="header">
        <FilterComponent
          placeholder="filter by sku"
          onFilter={(e) => setFilterSKUText(e.target.value)}
          onClear={handleClear}
          filterText={filterSKUText}
        />
      </div>
    );
  }, [filterSKUText, resetPaginationToggle]);

  const itemsTableColums = useMemo(() => {
    return [
      {
        name: "Id",
        selector: (row) => row?.id,
        sortable: true,
      },
      {
        name: "SKU", // item sku
        selector: (row) => row?.sku,
        sortable: true,
      },
      {
        name: "MPN", // item MfrPartNumber
        selector: (row) => row?.mpn,
        sortable: true,
      },
      {
        name: "Cost", // item Cost
        selector: (row) => row?.cost,
        sortable: true,
      },
      {
        name: "MSPR", // item MSPR
        selector: (row) => row?.mspr,
        sortable: true,
      },
      {
        name: "Title", // Product title
        selector: (row) => row?.title,
        sortable: true,
      },
      {
        name: "Brand", // Brand name
        selector: (row) => row?.brand,
        sortable: true,
      },
      {
        name: "View",
        button: true,
        cell: (row) => (
          <div className="actions">
            <Link to={`${row.id}/view`}>
              <Button
                style={{
                  width: "4vw",
                }}
                variant="light"
              >
                View
              </Button>
            </Link>
          </div>
        ),
      },
    ];
  }, []);

  return (
    <div className="items-list">
      <DataTable
        progressPending={loading}
        paginationServer
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationResetDefaultPage={resetPaginationToggle}
        persistTableHead
        customStyles={dataTableCustomStyles}
        title="Items"
        columns={itemsTableColums}
        data={filteredItems}
        pagination
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      ></DataTable>
    </div>
  );
}
