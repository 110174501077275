import React, { useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "./style.css";
import { TextField, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import DataTable from "react-data-table-component";
import { CreateUpdateBrandRequest } from "../service/HelpService";
import { UpdateBrandAsync } from "../../brands/service/BrandService";
import * as XLSX from "xlsx";
import { AiOutlinePlusCircle, AiOutlineExport } from "react-icons/ai";
import { CreatedPrefix, Messages } from "../../../redux/constants";
import { useState } from "react";
import { dataTableCustomStyles } from "../../../common/datatable/styles";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../../ui/AutoComplete";
import FileInputButton from "../../../ui/FileInputButton";
import * as AiIcons from "react-icons/ai";
import { GetCurrencyView } from "../../../common/services/view-service";
import { GetBrandByIdAsync, GetBrandsPricesAsync } from "../../brands/service/BrandService";
import { toast } from "react-toastify";
import routes from "../../../routes";
import ImageWithFallback from "../../common/image-with-fallback"
import { GetDefaultPricingLevelView } from "../../../common/services/view-service";
import { BrandTempContainer } from "../container";

export default function BrandDetails({
  form,
  setForm,
  Save,
  successfulMessage,
}) {
  const [rows, setRows] = useState(form?.pricingLevelsView || []);
  const [rowToDeleteBrandAccessId, setRowToDeleteBrandAccessId] = useState(-1);
  const [logoUrl, setLogoUrl] = useState(form?.brandView?.logoUrl ?? "https://via.placeholder.com/150x150?text=BrandLogo");
  const [accessDefault, setAccessDefault] = useState(form?.brandView?.accessDefault);
  const [isDropshipAvailable, setIsDropshipAvailable] = useState(form?.brandView?.dropshipAvailable ?? false);
  const dispatch = useDispatch();
  const container = BrandTempContainer(dispatch);
  const accessDefaultBrandStatuses = useSelector(
    (state) => state.statusReducer.accessDefault
  );
  const [beenSubmitted, setBeenSubmitted] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    setAccessDefault(form?.brandView?.accessDefault);
    console.log("inside of page effect");
  });

  const navigate = useNavigate();
  function GoBack() {
    container.ClearTempBrand();
    navigate(-1);
  }
  const zeroingvaliables = () => {
    setForm({});
};
  const handleDropshipAvailablRadioButtonChange = () => {
    setIsDropshipAvailable(!isDropshipAvailable);
    form.brandView.dropshipAvailable = !form.brandView.dropshipAvailable;
  };
  const validate = () => {
    const allErrors = {};
    zeroingerrors();
    if (!form?.brandView?.name) {
      allErrors.email = "Please write a brand name!";
    }
    if (!form?.brandView?.accessDefault) {
      allErrors.verifiedStatus = "Please choose a default access!";
    }
    if (!form?.brandView?.dropshipAvailable) {
      allErrors.name = "Please select whether dropshipping is available or not!";
    }

    setError(allErrors);
  };

  useEffect(() => {
    validate();
  }, [form]);

  const zeroingerrors = () => {
    setError({});
  };


  const pricingLevelTableColums = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "View",
      button: true,
      cell: (row) => (
        <div className="actions">
          <Link to={`/price-groups/${row.id}/edit`}>
            <Button
              style={{
                width: "4vw",
              }}
              variant="light"
              // onClick={() => brandTempState.}
            >
              View
            </Button>
          </Link>
        </div>
      ),
    },
    {
      name: "Set as default",
      button: true,
      cell: (row) => (
        <div className="actions">
          <RadioGroup
            aria-label="defaultPricingGroup"
            name="defaultPricingGroup"
            value={row.status === "Default"}
            onChange={() => handleSetDefault(row)} // Pass pricingLevelsView for reference
          >
            <FormControlLabel value="true" control={<Radio />} />
          </RadioGroup>
        </div>
      ),
    },
  ];

  const handleSetDefault = (row) => {
    if (row.status === "Regular")
    {
      form.pricingLevelsView = rows.map((pricingLevel) => 
      {
        return pricingLevel.id === row.id ? { ...pricingLevel, status: "Default" } : { ...pricingLevel, status: "Regular" };
      });

      setRows(form.pricingLevelsView);
    }
    else
    {
      form.pricingLevelsView = rows.map((pricingLevel) => 
        {
          return pricingLevel.id === row.id ? { ...pricingLevel, status: "Regular" } : pricingLevel;
        });
  
        setRows(form.pricingLevelsView);

    }
  };



  const itemTableColums = [
    {
      name: "SKU",
      selector: (row) => row?.sku,
      sortable: true,
    },
    {
      name: "ItemsAvailable",
      selector: (row) => row?.itemsAvailable,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      sortable: true,
    },
    {
      name: "EPA",
      selector: (row) => row?.epa,
      sortable: true,
    },
    {
      name: "View",
      button: true,
      cell: (row) => (
        <div className="actions">
          <Link to={`/items/${row.id}/view`}>
            <Button
              style={{
                width: "4vw",
              }}
              variant="light"
            >
              View
            </Button>
          </Link>
        </div>
      ),
    }
  ];

  const ownerTableColums = [
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
    },
    {
      name: "View",
      button: true,
      cell: (row) => (
        <div className="actions">
          <Link to={`/customers/${row.id}/edit`}>
            <Button
              style={{
                width: "4vw",
              }}
              variant="light"
            >
              Edit
            </Button>
          </Link>
        </div>
      ),
    }
  ];

  const brandAccessTableColums = [
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Access Default",
      selector: (row) => form.brandView.accessDefault,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
    },
    {
      name: "Pricing Level",
      selector: (row) =>
        !!row.defaultPricingLevel
          ? row.defaultPricingLevel.name
          : !!row.pricingLevel
            ? row.pricingLevel.name
            : "",
      sortable: true,
    },
    {
      name: "Pricing Level value",
      selector: (row) =>
        !!row.defaultPricingLevel
          ? GetDefaultPricingLevelView(row.defaultPricingLevel)
          : !!row.pricingLevel
            ? GetDefaultPricingLevelView(row.pricingLevel)
            : "",
      sortable: true,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <Link to={`brandaccess/${row.id}/edit`}>
          <Button style={{ width: "4vw" }} variant="light">
            Edit
          </Button>
        </Link>
      ),
    },
    {
      name: "Remove",
      button: true,
      cell: (row) =>
        rowToDeleteBrandAccessId != row.id ? (
          <Button
            onClick={() => ConfidenceToBrandAccess(row.id)}
            style={{ width: "5vw" }}
            variant="light"
          >
            Delete
          </Button>
        ) : (
          <div className="delete-container">
            <Button
              variant="light"
              style={{ width: "2vw", marginRight: "3px" }}
              onClick={() => RemoveBrandAccess(rowToDeleteBrandAccessId)}
            >
              <AiIcons.AiOutlineCheck></AiIcons.AiOutlineCheck>
            </Button>
            <Button
              onClick={() => NotSureToBrandAccess()}
              variant="light"
              style={{ width: "2.5vw", alignItems: "center" }}
            >
              <AiIcons.AiOutlineClose></AiIcons.AiOutlineClose>
            </Button>
          </div>
        ),
    },
  ];
  function RemoveBrandAccess(rowId) {
    container.RemoveBrandAccess(rowId);
    setRowToDeleteBrandAccessId(-1);
  }
  function NotSureToBrandAccess() {
    setRowToDeleteBrandAccessId(-1);
  }
  function ConfidenceToBrandAccess(id) {
    setRowToDeleteBrandAccessId(id);
  }


  useEffect(() => {
    validate();
  }, [form]);
  const handleInput = (event) => {
    console.log(event);
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    console.log(target.name); 
    console.log(value);
    if (target.name === "LogoUrl") {
      form.brandView.logoUrl = value;
      setLogoUrl(value);
    }
    else if (target.name === "name") {
      form.brandView.name = value;
    }
    setForm((prevForm) => ({
      ...prevForm,
      [target.name]: value
    }));
  };

  const handleAutoInput = (event) => {
    const { name, value } = event.target;
    console.log(name); 
    console.log(value);

    if (name === "accessDefault") {
      setAccessDefault(value);
      form.brandView.accessDefault = value;
    }
    
    setForm((prevForm) => ({
      ...prevForm,
      brandView: {
        ...prevForm.brandView,
        [name]: value
      }
    }));
  };

  const handleAutoCompleteChange = (name, value) => {
    handleAutoInput({ target: { name, value } });
  };
  
  const handleSubmit = (e) => {
    const id = toast.loading(
      "Please wait...",
      {
        closeButton: true,
      });
    let message = "Unexpected error occurred. Please try again later.";
    e.preventDefault();
    // e.stopPropagation();
    setBeenSubmitted(true);
    if (Object.keys(error).length === 0) {
      (async () => {
        try {
          const defPriceLevel = form.pricingLevelsView.find((x) => x.status == "Default");
          if (defPriceLevel == undefined)
            {
              throw new Error("Plese, select at least one default price level!");
            }
          container.UpdatePriceGroup(defPriceLevel);
          form.brandView.accessDefault = getDefaultAccess();
          let response = await Save();
          if (response?.status == 200) {
            toast.update(id, {
              render: successfulMessage,
              type: "success",
              isLoading: false,
              autoClose: 5000,
          });
            zeroingvaliables();
            setBeenSubmitted(false);
            GoBack();
        }

        if (!!response?.response?.data) {
            toast.update(id, {
                render: response.response.data,
                type: "error",
                isLoading: false,
                autoClose: 5000,
            });
        } else if (!!response?.message) {
          toast.update(id, {
              render: response.message,
              type: "error",
              isLoading: false,
              autoClose: 5000,
          });
      } 


        }
        catch (error) {
          console.error("During brand editing the error occured: ");
          console.error(error);
          console.error(error?.response?.data);
          console.error(error?.response?.data?.message);
          if (error.response && error.response.data && typeof error.response.data === 'string') {
            console.error("Error response data is string:", error.response.data);
            message = error.response.data;
          } else if (error.response && error.response.data && error.response.data.message) {
            console.error("Error response data message:", error.response.data.message);
            message = error.response.data.message;
          } else {
            if (error.message)
            {
              message = error.message
            }
            else
            {
              message = "No response from the server. Please check your internet connection:" + error.message;
            }
          }

          toast.update(id, {
            render: String(message),
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        }
      })();
    } 
    else {
      toast.update(id, {
        render: String(message),
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      GoBack();
    }
  };

  function getDefaultAccess()
  {
    form.accessDefault = form.accessDefault ?? accessDefault;
    const result = form.accessDefault ?? accessDefault
    return result;
  }

  return (
    <div className="details-container">
      <Outlet></Outlet>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="header">
          <h1>Brand Setup</h1>
          <div>
            <Button onClick={GoBack} className="action-button" variant="light">
              Back
            </Button>
          </div>
        </div>

        <div className="section">
          <h2>Brand Details</h2>
          <div className="row-elements-container">
            <div className="small-margin">
              <TextField
                className="text-field-styles"
                helperText={beenSubmitted ? error.name : null}
                label="LogoUrl"
                type="text"
                placeholder="Logo url"
                name="LogoUrl"
                value={form?.brandView?.logoUrl || ""}
                onChange={handleInput}
              />
              <div className="info-input-section">
                <div className="form-container">
                  <div className="small-margin">
                    <TextField
                      className="text-field-styles"
                      error={beenSubmitted ? error.name : null}
                      helperText={beenSubmitted ? error.name : null}
                      label="Name"
                      required
                      type="text"
                      placeholder="Brand name"
                      name="name"
                      value={form?.brandView?.name || ""}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="form-container">
                  <div className="small-margin">
                    <AutoComplete
                      styles={{ width: "19.5vw" }}
                      className="text-field-styles"
                      error={beenSubmitted ? error.accessDefault : null}
                      helperText={beenSubmitted ? error.accessDefault : null}
                      name={"accessDefault"}
                      form={form}
                      inputData={accessDefaultBrandStatuses}
                      label={"Access Default"}
                      value={getDefaultAccess()}
                      setForm={setForm}
                    ></AutoComplete>
                    </div>
                </div>
                <div className="form-container">
                  <div className="small-margin">
                    <RadioGroup
                      aria-label="defaultPricingGroup"
                      name="dropshipAvailable"
                      value={isDropshipAvailable}
                    >
                      <FormControlLabel
                        onClick={() => {
                          console.log('on clicking before: ', isDropshipAvailable);
                          handleDropshipAvailablRadioButtonChange();
                          console.log('on clicking after: ', isDropshipAvailable);
                        }}
                        value="true"
                        control={<Radio />}
                        label="Dropship is available"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-image">
              <ImageWithFallback
                src={logoUrl}
                alt="Brand Logo"
                fallbackSrc="https://via.placeholder.com/150x150?text=BrandLogo"
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="section">
          <h2>Brand Owner</h2>
          <div className="form">
            {form?.ownerView != undefined && form?.ownerView != null && form.ownerView?.id != 0 ? (
              <>
                <Form.Group className="custom-table-styles">
                  <div>
                    <div>
                      <div className="input-button-wrapper">
                        <TextField
                          className="text-field-styles"
                          label="Owner name"
                          type="text"
                          placeholder="Owner name"
                          name="name"
                          value={form?.ownerView?.name || ""}
                          readOnly
                        />
                        <Link to={`/customers/${form?.ownerView?.id}/edit`}>
                          <Button
                            className="button-styles"
                            variant="light"
                          >
                            View customer
                          </Button>
                        </Link>
                      </div>

                      <div className="center-button-wrapper">
                        <Link to={routes.associateCustomerWithBrand}>
                          <Button variant="light">
                            Associate New Owner
                          </Button>
                        </Link>
                      </div>

                    </div>
                    <Form.Text>Brand owner</Form.Text>
                  </div>
                </Form.Group>
              </>
            ) : (
              <div>
                <div>
                  <Link to={routes.associateCustomerWithBrand}>
                    <Button
                      className="action-button"
                      style={{
                        marginLeft: "85%",
                      }}
                      variant="light"
                    >
                      Associate Owner
                    </Button>
                  </Link>
                </div>
                <Form.Text>Brand owner</Form.Text>
              </div>
            )}
            <h2>Pricing levels</h2>
            {CheckArrayForDataForValidity(form?.pricingLevelsView) ? (
              <>
                <Form.Group
                  className="custom-table-styles"
                  style={{ marginBottom: "3vh", marginTop: "3vh" }}
                >
                  <div>
                    <DataTable
                      updatedPricingLevelsrows={rows}
                      columns={pricingLevelTableColums}
                      data={form?.pricingLevelsView}
                      customStyles={dataTableCustomStyles}
                      pagination>

                    </DataTable>
                    <Form.Text>Pricing levels</Form.Text>
                  </div>


                </Form.Group>
              </>
            ) : (
              <div>
                <Form.Text>There is no price levels assosiated with the brand.</Form.Text>
              </div>
            )}

            <h2>Items</h2>
            {CheckArrayForDataForValidity(form?.itemsView) ? (
              <Form.Group
                className="custom-table-styles"
                style={{ marginBottom: "3vh", marginTop: "3vh" }}
              >
                <div>
                  <Form.Text>Items</Form.Text>
                  <DataTable
                    columns={itemTableColums}
                    data={form?.itemsView}
                    customStyles={dataTableCustomStyles}
                    pagination>

                  </DataTable>
                </div>

              </Form.Group>
            ) : (
              <div>
                <Form.Text>There is no items assosiated with the brand</Form.Text>
              </div>
            )}

            <h2>Dealers</h2>
            {CheckArrayForDataForValidity(form?.brandAccessView) ? (
              <Form.Group
                className="custom-table-styles"
                style={{ marginBottom: "3vh", marginTop: "3vh" }}
              >

                <Form.Text>Dealers</Form.Text>
                <DataTable
                  columns={brandAccessTableColums}
                  data={form.brandAccessView}
                  customStyles={dataTableCustomStyles}
                  pagination>

                </DataTable>
                <div>
                  <Link to={routes.addBrandAccessToBrand}>
                    <Button
                      className="action-button"
                      style={{
                        marginLeft: "85%",
                      }}
                      variant="light"
                    >
                      Add Dealer
                    </Button>
                  </Link>

                  <Form.Text>Brand Access</Form.Text>
                </div>


              </Form.Group>
            ) : (
              <div>
                  <div>
                  <Link to={routes.addBrandAccessToBrand}>
                    <Button
                      className="action-button"
                      style={{
                        marginLeft: "85%",
                      }}
                      variant="light"
                    >
                      Add Dealer
                    </Button>
                  </Link>

                  <Form.Text>Brand Access</Form.Text>
                </div>
                <Form.Text>There is no brand accesses assosiated with the brand</Form.Text>
              </div>
            )}
          </div>
        </div>
        <div className="actions-buttons">
          <Button
            className="action-button"
            style={{
              height: "4vh",
              marginRight: "1vw",
            }}
            type="submit"
            variant="dark"
          >
            Save
          </Button>
          <Button onClick={GoBack} className="action-button cancel-btn">
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );

  function CheckArrayForDataForValidity(arrayToCheck) {
    return arrayToCheck != undefined && arrayToCheck != null && arrayToCheck.length > 0;
  }

}