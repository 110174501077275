export const authTypes = {
  // Synchronous
  LOGIN_SUCCESSFUL: "LOGIN_SUCCESSFUL",
  LOGOUT: "LOGOUT",

  // Asynchronous
  ASYNC_LOGIN_SUCCESSFUL: "ASYNC_LOGIN_SUCCESSFUL",
  ASYNC_LOGOUT: "ASYNC_LOGOUT",
};

export function LoginSuccessful(data) {
  return {
    type: authTypes.LOGIN_SUCCESSFUL,
    payload: data,
  };
}

export function Logout() {
  return {
    type: authTypes.LOGOUT,
  };
}
