import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "./style.css";
import { TextField, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import { AiOutlinePlusCircle, AiOutlineExport } from "react-icons/ai";
import { CreatedPrefix, Messages } from "../../../redux/constants";
import { useState } from "react";
import { dataTableCustomStyles } from "../../../common/datatable/styles";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../../ui/AutoComplete";
import { SKUTempContainer } from "../sku.details/container";
import FileInputButton from "../../../ui/FileInputButton";
import * as AiIcons from "react-icons/ai";
import { GetCurrencyView } from "../../../common/services/view-service";
import { GetPricesByBrandIdAsync } from "../../../common/price.services/PriceService";
import { toast } from "react-toastify";

export default function PriceGroupDetails({
  form,
  setForm,
  Save,
  brands,
  successfulMessage,
}) {
  let disablesBrands = false;
  if (
    window.location.href.includes("price-groups") &&
    window.location.href.includes("edit")
  ) {
    disablesBrands = true;
  }
  const [rowToDeleteId, setrowToDeleteId] = useState(-1);
  const [isDefaultPriceGroup, setIsDefaultPriceGroup] = useState(false);

  useEffect(() => {
    // Set default price group based on form status
    setIsDefaultPriceGroup(form.status === 'Default');
  }, [form.status]);

  const dispatch = useDispatch();
  const container = SKUTempContainer(dispatch);
  const pricingReferences = useSelector(
    (state) => state.statusReducer.pricingReferences
  );
  const skus = useSelector((state) => state.skuTemReducer.skus);
  const [beenSubmitted, setBeenSubmitted] = useState(false);
  const [error, setError] = useState({});
  const navigate = useNavigate();
  function GoBack() {
    navigate(-1);
  }
  const zeroingvaliables = () => {
    setForm({});
  };
  const zeroingerrors = () => {
    setError({});
  };
  const handleDefaultPricingRadioButtonChange = () => {
    setIsDefaultPriceGroup(!isDefaultPriceGroup);
    setForm({
      ...form,
      status: isDefaultPriceGroup ? 'Regular' : 'Default',
    });
  };

  const SKUTableColums = [
    {
      name: "SKU",
      selector: (row) => row.sku,
      sortable: true,
    },
    {
      name: "Minimum Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "$ Amount",
      selector: (row) => (!!row.amount ? `${GetCurrencyView(row.amount)}` : "0"),
      sortable: true,
    },
    {
      name: "Percent",
      selector: (row) => (!!row.percent ? `${row.percent} %` : "0"),
      sortable: true,
    },
    {
      name: "Reference",
      selector: (row) => row.reference,
      sortable: true,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <Button
          style={{ width: "4vw" }}
          variant="light"
          onClick={() => EditPrice(row.id)}
        >
          Edit
        </Button>
      ),
    },
    {
      name: "Remove",
      button: true,
      cell: (row) =>
        rowToDeleteId != row.id ? (
          <Button
            onClick={() => ConfidenceToSKU(row.id)}
            style={{ width: "5vw" }}
            variant="light"
          >
            Remove
          </Button>
        ) : (
          <div className="delete-container">
            <Button
              variant="light"
              style={{ width: "2vw", marginRight: "3px" }}
              onClick={() => RemoveSKU(rowToDeleteId)}
            >
              <AiIcons.AiOutlineCheck></AiIcons.AiOutlineCheck>
            </Button>
            <Button
              onClick={NotSureToRemoveSKU}
              variant="light"
              style={{ width: "2.5vw", alignItems: "center" }}
            >
              <AiIcons.AiOutlineClose></AiIcons.AiOutlineClose>
            </Button>
          </div>
        ),
    },
  ];

  function RemoveSKU(rowId) {
    container.RemoveSKU(rowId);
    setrowToDeleteId(-1);
  }
  function NotSureToRemoveSKU() {
    setrowToDeleteId(-1);
  }
  function ConfidenceToSKU(id) {
    setrowToDeleteId(id);
  }

  const validate = () => {
    const allErrors = {};
    zeroingerrors();
    if (!form.name) {
      allErrors.name = "Please write a price group name!";
    }
    if (!form.defaultReference) {
      allErrors.defaultReference = "Please choose a reference!";
    }
    if (!form.defaultPercent) {
      allErrors.defaultPercent = "Please write a percent!";
    } else {
      if (form.defaultPercent > 100) {
        allErrors.defaultPercent = "Percentage can't be more that 100";
      }
    }
    if (!form.brand) {
      allErrors.brand = "Please write a price group name!";
    }

    setError(allErrors);
  };

  useEffect(() => {
    validate();
  }, [form]);
  const handleInput = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (target.name == "defaultPercent") {
      const onlyNums = value.replace(/[^0-9]/g, "");

      if (onlyNums.length <= 3) {
        setForm((prevForm) => ({
          ...prevForm,
          [target.name]: onlyNums,
        }));
      }
      return;
    }

    setForm((prevForm) => ({
      ...prevForm,
      [target.name]: value,
    }));
  };
  const handleSubmit = (e) => {
    const id = toast.loading(
        "Please wait...",
        {
            closeButton: true,
        });
    let message = "Unexpected error occurred. Please try again later.";
    e.preventDefault();
    e.stopPropagation();
    setBeenSubmitted(true);
    if (Object.keys(error).length === 0) {
        (async () => {
            try {
                let response = await Save();

                if (response.status == 200) {
                    toast.update(id, {
                        render: successfulMessage,
                        type: "success",
                        isLoading: false,
                        autoClose: 5000,
                    });
                    container.ClearSKU();
                    zeroingvaliables();
                    setBeenSubmitted(false);
                    GoBack();
                }

                if (!!response?.response?.data) {
                    toast.update(id, {
                        render: response.response.data,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                    });
                }
            } catch (error) {
                console.log("During operation with price list the error occured: ");
                if (error.response && error.response.data && typeof error.response.data === 'string') {
                    console.log("Error response data is string:", error.response.data);
                    message = error.response.data;
                } else if (error.response && error.response.data && error.response.data.message) {
                    console.log("Error response data message:", error.response.data.message);
                    message = error.response.data.message;
                } else {
                    message = "No response from the server. Please check your internet connection.";
                }
            }
        })();
    } else {
        toast.update(id, {
            render: String(message),
            type: "error",
            isLoading: false,
            autoClose: 5000,
        });
    }
};
  function ExportSKUs() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(skus);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${form.name}_SKUs.xlsx`);
  }

  async function ExportAllBrandSKUs() {
    const brandId = form.brandId;
    try {
      // Get prices for the brand
      const pricesResponse = await GetPricesByBrandIdAsync(brandId);
      // const pricesResponse = await GetBrandsPricesAsync(brandId);
      let prices = pricesResponse.data;

      // Filter and add skus with id containing CreatedPrefix
      skus.forEach((elem) => {
        if (elem.id.toString().includes(CreatedPrefix)) {
          prices.push(elem);
        }
      });

      // Get ids of all skus
      let skusIds = skus.map((item) => item.id);

      // Map prices to find matching skus
      let matchedSkus = prices.map((item) => {
        if (skusIds.includes(item.id)) {
          let sku = skus.find((elem) => elem.id == item.id);
          return sku;
        }
        return item;
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(matchedSkus);

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, `${form.brand}_SKUs.xlsx`);
    } catch (error) {
      console.log(error);
    }
  }
  function ImportSKUs(e) {
    try {
      const reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        const isValid = validateExcelData(parsedData);
        if (!isValid) return;

        const allSkus = [];
        parsedData.forEach((elem) => {
          if (!elem.id) elem.id = `${allSkus.length}${CreatedPrefix}`;
          if (elem.amount) {
            elem.amount = parseCurrencyorFloat(elem.amount);
          }
          allSkus.push(elem);
        });

        container.AddListSKUs(allSkus);
      };
    } catch (error) {
      console.log(error);
    }
  }

  function parseCurrencyorFloat(value) {
    if (!value) return null;
    if (Number.isInteger(value)) return value;

    return tryParseCurrency(value);
  }

  function validateExcelData(data) {
    let errors = {};
    data.forEach((el) => {
      if (el.amount && el.percent) {
        errors.valueOFF =
          "Percent and amount can't be both filled, only one of them can be filled";
      }
      if (!el.amount && !el.percent) {
        errors.valueOFF = "Al least one of Percent or amount must be filled";
      }

      if (el.percent && tryParseInt(el.percent) == null) {
        errors.percent = "Percent can't be parsed to int";
        if (el.percent < 0) {
          errors.percent = "Percent can't be less than 0";
        }
        if (el.percent > 100) {
          errors.percent = "Amount can't be more than 100";
        }
      }

      if (
        el.amount &&
        tryParseInt(el.amount) == null &&
        tryParseCurrency(el.amount) == null
      ) {
        errors.amount =
          "Amount can't be parsed to int or currency. Currecy type is $11,123.00";
        if (el.amount < 0) {
          errors.amount = "Amount can't be less than 0";
        }
      }

      if (!pricingReferences.includes(el.reference)) {
        errors.reference =
          "Reference doesn't exists, please choose a valid reference. Choose one of them : " +
          pricingReferences;
      }
      if (tryParseInt(el.quantity) == null) {
        errors.quantity = "Quantity can't be parsed to int";
      }
    });

    if (Object.keys(errors).length === 0) {
      return true;
    }
    Object.keys(errors).forEach((key) => {
      // show errors
      toast.error(errors[key]);
    });
    return false;
  }
  function tryParseInt(value) {
    if (!value) return null;
    if (Number.isInteger(value)) return value;

    const radix = 10; // Base 10 (decimal)
    const parsedValue = parseInt(value, radix);

    if (!isNaN(parsedValue)) {
      return parsedValue;
    } else {
      return null; // Return null if parsing fails
    }
  }

  function tryParseCurrency(currencyString) {
    try {
      const numericString = currencyString.replace(/[^0-9.-]/g, ""); // Remove non-numeric characters
      const numericValue = parseFloat(numericString);

      if (!isNaN(numericValue)) {
        return numericValue;
      } else {
        return null;
      }
    } catch (error) {
      console.error("An error occurred while parsing currency:", error);
      return null;
    }
  }

  function AddPrice() {
    if (!form.brand) {
      toast.error(
        "Brand selection is mandatory for pricing suggestions. Please choose a brand to enable special pricing options."
      );
      return;
    }

    let brand = brands.find((b) => b.name === form.brand);

    if (!!brand) navigate(`sku/new/${brand.id}`);
    if (!!form.brandId) navigate(`sku/new/${form.brandId}`);
  }

  function EditPrice(id) {
    if (!form.brand) {
      toast.error(
        "Brand selection is mandatory for pricing suggestions. Please choose a brand to enable special pricing options."
      );
      return;
    }

    let brand = brands.find((b) => b.name === form.brand);
    if (!!brand) navigate(`${id}/edit/${brand.id}`);
    if (!!form.brandId) navigate(`${id}/edit/${form.brandId}`);
  }

  return (
    <div className="details-container">
      <Outlet></Outlet>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="header">
          <h1>Price Group Detail</h1>
          <Button onClick={GoBack} className="action-button" variant="light">
            Back
          </Button>
        </div>
        <div className="section">
          <h2>General Details</h2>
          <div className="form">
            <div className="margin-styles">
              <TextField
                style={{ width: "23vw" }}
                label="Name"
                error={beenSubmitted ? error.name : null}
                helperText={beenSubmitted ? error.name : null}
                required
                type="text"
                placeholder="Price group name"
                name="name"
                value={form.name || ""}
                onChange={handleInput}
              />
            </div>
            <div className="margin-styles">
              <div className="form-container">
                <TextField
                  error={beenSubmitted ? error.defaultPercent : null}
                  helperText={beenSubmitted ? error.defaultPercent : null}
                  required
                  label={"Default Percent"}
                  name="defaultPercent"
                  value={form.defaultPercent || ""}
                  type="text"
                  placeholder="0"
                  onChange={handleInput}
                />
                <AutoComplete
                  styles={{ width: "11vw" }}
                  error={beenSubmitted ? error.defaultReference : null}
                  name={"defaultReference"}
                  form={form}
                  setForm={setForm}
                  inputData={pricingReferences}
                  label={"Default Reference"}
                ></AutoComplete>
              </div>
            </div>
            <div className="margin-styles">
              <AutoComplete
                disabled={disablesBrands}
                styles={{ width: "11vw" }}
                error={beenSubmitted ? error.brand : null}
                name={"brand"}
                form={form}
                setForm={setForm}
                inputData={brands.map((item) => item.name)}
                label={"Brand"}
              ></AutoComplete>
            </div>
          </div>
        </div>
        <hr />
        <div className="section">
        <h2>Default Pricing Group</h2>
        <RadioGroup
        aria-label="defaultPricingGroup"
        name="defaultPricingGroup"
        value={isDefaultPriceGroup}
      >
        <FormControlLabel
        onClick={ () => {
          console.log('on clicking before: ', isDefaultPriceGroup);
          handleDefaultPricingRadioButtonChange();
          console.log('on clicking after: ', isDefaultPriceGroup);
        }
      }
          value="true"
          control={<Radio />}
          label="Make this the default pricing group"
        />
      </RadioGroup>
          <h2>SKU Specific Pricing</h2>
          <div className="form">
            <div style={{ marginLeft: "50vw" }} className="form-container">
              <Button variant="light" onClick={AddPrice}>
                <div className="button-content-container">
                  <AiOutlinePlusCircle
                    style={{ width: "20px", height: "20px" }}
                  ></AiOutlinePlusCircle>
                  <div>Add</div>
                </div>
              </Button>
              <Button variant="light" onClick={ExportAllBrandSKUs}>
                <div className="button-content-container">
                  <AiOutlineExport
                    style={{ width: "20px", height: "20px" }}
                  ></AiOutlineExport>
                  Export All SKUs
                </div>
              </Button>
              <Button variant="light" onClick={ExportSKUs}>
                <div className="button-content-container">
                  <AiOutlineExport
                    style={{ width: "20px", height: "20px" }}
                  ></AiOutlineExport>
                  <div>Export</div>
                </div>
              </Button>
              <FileInputButton handleFileChange={ImportSKUs}></FileInputButton>
            </div>
            <div style={{ width: "80vw" }}>
              <DataTable
                columns={SKUTableColums}
                data={skus}
                customStyles={dataTableCustomStyles}
                title="SKU list"
                pagination
              ></DataTable>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2vh" }} className="actions-buttons">
          <Button
            className="action-button"
            style={{
              height: "4vh",
              marginRight: "1vw",
            }}
            type="submit"
            variant="dark"
          >
            Save
          </Button>
          <Button onClick={GoBack} className="action-button cancel-btn">
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}
