import { api } from "../../../api/axios";

const controller = "AdminPortalUser";

export async function GetUsersPagedAsync(
  PageIndex,
  PageSize = 20,
  Email = "",
  SortDescending = false
) {
  return await api.get(
    `${controller}/GetAdminPortalUserPaginated?PageSize=${PageSize}&PageIndex=${PageIndex}&SortDescending=${SortDescending}&Email=${Email}`
  );
}

export async function CreateAdminUserAsync(data) {
  return await api.post(`${controller}/CreateAdmin`, data);
}

export async function UpdateAdminUserAsync(data) {
  return await api.put(`${controller}/UpdateAdmin`, data);
}

export async function GetAdminByIdAsync(id) {
  return await api.get(`${controller}/GetAdmins/${id}`);
}

export async function DeleteAdminByIdAsync(id) {
  return await api.delete(`${controller}/DeleteAdmin/${id}`);
}
