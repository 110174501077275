import { api } from "../../api/axios";

const controller = "Country";

export async function GetCountriesAsync() {
  return await api.get(`/${controller}/GetCountries`);
}

export async function GetProvincesOfCountryAsync(id) {
  return await api.get(`/${controller}/GetCountries/${id}/Provinces`);
}
