import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../ui/loader";
import { BrandTempContainer } from "../../../../../components/brands/container";
import CustomersList from "../../../../../components/brands/brands-details/customers-list";
import { GetCustomersAsync } from "../../../../../components/customers/service/CustomerService";
import { useNavigate } from "react-router-dom";
import { GetCustomerByIdAsync } from "../../../../../components/customers/service/CustomerService";
export default function AssociateCustomerListPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const container = BrandTempContainer(dispatch);
  const brandTempReducer = useSelector((state) => state.brandTempReducer);
  const [ownerId, setOwnerId] = useState(brandTempReducer?.owner?.id ?? 0);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const selectedOwner = getSelectedId(brandTempReducer);
  const getCustomers = async () => {
    let response = await GetCustomersAsync();
    return response.data;
  };
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await getCustomers();
        console.log("Fetched customers:", response);
        setCustomers(response);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  function getSelectedId(brandTempReducer) {
    return brandTempReducer?.owner;
  }

  async function Save() {
    if (ownerId != 0) {
      const newOwner = await GetCustomerByIdAsync(ownerId);
      if (newOwner.status === 200)
      {
        container.UpdateOwner(newOwner.data);
      }
      else
      {
        container.UpdateOwner(undefined);
      }
    }
    else
    {
      container.UpdateOwner(undefined);
    }

    GoBack();
  }

  function GetCustomersView()
  {
    return customers?.items?.filter((item) => {
      console.log("Filtering item:", item);
      if (!!!item.owner) return item;
    }) || [];
  }
  function GoBack() {
    navigate(-1);
  }
  // create separate for component for new, pass initial owner id set
  return (
    <div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <CustomersList
          customersView={GetCustomersView()}
          ownerId={ownerId}
          setOwnerId={setOwnerId}
          save={Save}
        ></CustomersList>
      )}
    </div>
  );
}
