import React, { useState } from "react";
import LocationDetails from "../../../../../components/customers/customer-details/location-details";
import {
  GetLocationByIdAsync,
  UpdateLocationAsync,
} from "../../../../../common/locationServices";
import { useParams } from "react-router-dom";
import Loader from "../../../../../ui/loader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocationContainer } from "../container";
import { GetAddressView } from "../../../../../common/services/view-service";
import { UpdateAddressAsync } from "../../../../../common/addressServices";
import { GetUpdatePhoneNumberModel } from "../../../../../components/customers/service/HelpService";

export default function LocationEditPage() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({});
  const locationState = useSelector((state) => state.locationReducer);
  const statusState = useSelector((state) => state.statusReducer);
  const dispatch = useDispatch();
  const container = LocationContainer(dispatch);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await GetLocationByIdAsync(id);
        fillForm(response);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);
  function fillForm(response) {
    if (locationState.address) {
      let form = response.data;
      form.address = GetAddressView(locationState.address);
      form.addressEntity = locationState.address;
      setForm(form);
    } else {
      let form = response.data;
      let oldAddress = form.address;
      form.address.phone = oldAddress?.phoneNumber?.number;
      form.address.countrytel = `${oldAddress.phoneNumber?.countryTelCode} ${oldAddress.phoneNumber?.shortenedName}`;
      form.addressEntity = oldAddress;
      form.address = GetAddressView(oldAddress);
      console.log(!!locationState.address);

      container.AddAddress(form.addressEntity);
      setForm(form);
    }
  }

  useEffect(() => {
    let address = locationState.address;
    if (!!address) {
      setForm((prevForm) => ({
        ...prevForm,
        ["address"]: GetAddressView(address),
        ["addressEntity"]: address,
      }));
    }
  }, [locationState]);
  async function Save() {
    let phoneNumber = GetUpdatePhoneNumberModel(
      form.addressEntity.phoneNumber?.id,
      form.addressEntity.countrytel,
      form.addressEntity.phone,
      statusState
    );
    form.addressEntity.phoneNumber = phoneNumber;
    form.address = form.addressEntity;
    form.addressEntity = {};

    try {
      const response = await UpdateLocationAsync(form);
      container.ClearLocationState();

      return response;
    } catch (error) {
      return error;
    }
  }
  return (
    <div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <LocationDetails
          form={form}
          setForm={setForm}
          Save={Save}
          successfulMessage={"Location updated successfully"}
        ></LocationDetails>
      )}
    </div>
  );
}
