import { api } from "../../../api/axios";
const controller = "Brand";

export async function CreateBrandAsync(brand) {
  return await api.post(`/${controller}/CreateBrand/`, brand);
}

export async function UpdateBrandAsync(brand) {
  try
  {
    return await api.post(`/${controller}/UpdateBrand`, brand);
  }
  catch(error)
  {
    console.error(error);
    console.error(error?.message);
    return error;
  }
}

export async function GetBrandsAsync() {
  return await api.get(`${controller}/GetBrands`);
}

export async function GetBrandByIdAsync(id) {
  return await api.get(`${controller}/GetBrand/${id}`);
}

export async function GetBrandsPagedAsync(
  PageIndex,
  PageSize = 20,
  OwnerName, 
  Name,
  SortDescending = false
) {
  return await api.get(
    `${controller}/GetBrandsPaginated?PageSize=${PageSize}
    &PageIndex=${PageIndex}&SortDescending=${SortDescending}&Name=${Name}&OwnerName=${OwnerName}`
  );
}

export async function DeleteBrandByIdAsync(id) {
  return await api.delete(`${controller}/DeleteBrand/${id}`);
}

export async function GetBrandNamesAndIdsAsync() {
  return await api.get(`${controller}/GetBrandNamesAndIds`);
}

export async function UpdateBrandPriceGroupAsync(data) {
  return await api.put(`${controller}/UpdateBrandPriceGroup`, data);
}

export async function GetBrandsPricesAsync(id) {
  return await api.get(`${controller}/GetBrandsPrices/${id}`);
}

export async function GetPricingGroupsOfBrand(id) {
  return await api.get(`${controller}/GetBrandsPriceGroups/${id}`);
}
