import React from "react";
import { Button, Form } from "react-bootstrap";
import "./style.css";
export default function FilterComponent({
  onFilter,
  onClear,
  filterText,
  placeholder = "filter by name",
}) {
  return (
    <div className="filter-header">
      <Form.Control
        value={filterText || ""}
        placeholder={placeholder}
        onChange={onFilter}
      ></Form.Control>
      <Button variant="light" onClick={onClear}>
        X
      </Button>
    </div>
  );
}
