export function GetAddressView(address) {
  try {
    const line2 = address.addressLine2 || "";
    const separatorBeforeLine2 = line2 ? " " : "";
    const result = `${address.addressLine1}${separatorBeforeLine2}${line2} ${address.country} ${address.province} ${address.city}`;
    return result;
  } catch (error) {
    console.log(error);
    return "";
  }
}


export function GetDefaultPricingLevelView(pricingLevel) {
  try {
    const result = `${pricingLevel?.defaultReference} ${pricingLevel?.defaultPercent}%(${pricingLevel?.status})`;
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return "";
  }
}

export function GetPhoneNumberView(phoneNumber) {
  try {
    const result = `${phoneNumber.countryTelCode} ${phoneNumber.number}`;
    return result;
  } catch (error) {
    console.log(error);
    return "";
  }
}

export function GetCurrencyView(number) {
  try {
    const result = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
    return result;
  } catch (error) {
    console.log(error);
    return "";
  }
}
