import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as FiIcons from "react-icons/fi";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import { Messages } from "../../../../redux/constants";
import { TextField } from "@mui/material";
import { useEffect } from "react";
export default function LocationDetails({
  form,
  setForm,
  Save,
  successfulMessage,
}) {
  const navigate = useNavigate();
  function GoBack() {
    navigate(-1);
  }
  const [beenSubmitted, setBeenSubmitted] = useState(false);
  const [error, setError] = useState({});
  const validate = () => {
    const allErrors = {};
    zeroingerrors();

    if (!form.name) {
      allErrors.name = "Please write a location name!";
    }
    if (!form.address) {
      allErrors.address = "Please choose an address!";
    }

    setError(allErrors);
  };
  useEffect(() => {
    validate();
  }, [form]);
  const zeroingerrors = () => {
    setError({});
  };
  const zeroingvaliables = () => {
    setForm({});
  };
  const handleInput = (event) => {
    const { target } = event;
    const value = target.value;

    setForm((prevForm) => ({
      ...prevForm,
      [target.name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setBeenSubmitted(true);

    if (Object.keys(error).length === 0) {
      (async () => {
        try {
          const id = toast.loading("Please wait...", {
            closeButton: true, // Add this option to show a close button
          });
          let response = await Save();
          if (response.status == 200) {
            toast.update(id, {
              render: successfulMessage,
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
            zeroingvaliables();
            setBeenSubmitted(false);
            GoBack();
          }
          if (!!response.response.data) {
            toast.update(id, {
              render: response.response.data.details,
              type: "error",
              isLoading: false,
              autoClose: 5000,
            });
          }
        } catch (error) {
          console.log(error);
        }
      })();
    } else {
      toast.error(Messages.FillFields);
    }
  };

  function GetPurposedRoute() {
    if (form.address) return `addressBooks/${form.addressEntity.id}/edit`;
    return `addressBooks/new`;
  }
  return (
    <div>
      <div className="header">
        <div className="header-contaner">
          <h3>Location Details</h3>
          <h3>{form.name}</h3>
        </div>
        <div onClick={GoBack} className="button-content-add-brand">
          <FiIcons.FiPlus className="close-btn" />
        </div>
      </div>
      <div className="body">
        <Form noValidate onSubmit={handleSubmit}>
          <div className="margin-styles">
            <TextField
              className="text-field-styles"
              error={beenSubmitted ? error.name : null}
              helperText={beenSubmitted ? error.name : null}
              label="Name"
              required
              type="text"
              placeholder="Location Name"
              name="name"
              value={form.name || ""}
              onChange={handleInput}
            />
          </div>
          <div className="form-container">
            <Form.Group>
              <div className="form-container">
                <TextField
                  required
                  error={beenSubmitted ? error.address : null}
                  helperText={beenSubmitted ? error.address : null}
                  label={"Location address"}
                  name="address"
                  value={form.address || ""}
                  style={{ width: "15vw" }}
                  type="text"
                  placeholder="3405 SPEEDWAY  AUSTIN TX 78705-1500 USA"
                />

                <Link to={GetPurposedRoute()}>
                  <Button className="action-button" variant="dark">
                    Address
                  </Button>
                </Link>
              </div>
            </Form.Group>
          </div>
          <div className="btn-container">
            <Button className="action-button" variant="light" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
