import React, { useEffect } from "react";
import WarehouseDetails from "../../../../../components/customers/customer-details/warehouse-details";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomerTempContainer } from "../../../../../components/customers/customer-details/container";
import { CreatedPrefix } from "../../../../../redux/constants";

export default function WarehouseCreatePage() {
  const customerTempState = useSelector((state) => state.customerTempReducer);
  const warehouses = customerTempState.warehouses;
  const id = !!warehouses.length ? warehouses.length : 0;
  const preSaveWarehouse = customerTempState.tempWarehouse;
  const initForm = !!preSaveWarehouse
    ? preSaveWarehouse
    : { id: `${id}${CreatedPrefix}` };
  const [form, setForm] = useState(initForm);

  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);

  useEffect(() => {
    let warehouseLocation = customerTempState.warehouseLocation;
    if (!!warehouseLocation) {
      setForm((prevForm) => ({
        ...prevForm,
        ["location"]: warehouseLocation,
        ["locationName"]: warehouseLocation.name,
      }));
    }
  }, [customerTempState]);

  function Save() {
    container.CreateWarehouse(form);
    container.ClearWarehouseLocation();
    container.ClearTempWarehouse();
  }

  function PreSave() {
    container.SaveTempWarehouseData(form);
  }

  return (
    <div>
      <WarehouseDetails
        form={form}
        setForm={setForm}
        Save={Save}
        PreSave={PreSave}
      ></WarehouseDetails>
    </div>
  );
}
