import React from "react";
import ItemDetails from "../../../components/items/item.details";
import { useState } from "react";
import Loader from "../../../ui/loader";
import { useEffect } from "react";
import { GetItemByIdAsync } from "../../../components/items/services";
import { useParams } from "react-router-dom";

export default function ItemViewPage() {
  const { id } = useParams();
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  async function GetItemAsync(id) {
    const response = await GetItemByIdAsync(id);
    return response.data;
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await GetItemAsync(id);
        response.brandName = response.brand.name;
        console.log(response);
        const subCategory = response.subcategory;
        response.categoryName = subCategory.category.name;
        response.aaiaCategoryId = subCategory.category.aaiaCategoryId;
        response.subCategoryName = subCategory.name;
        response.aaiaSubCategoryId = subCategory.aaiaSubCategoryId;

        setForm(response);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);
  function Save() {
    console.log(form);
  }
  return (
    <div className="items-list">
      {loading ? (
        <Loader></Loader>
      ) : (
        <ItemDetails form={form} setForm={setForm} Save={Save} />
      )}
    </div>
  );
}
