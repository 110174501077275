const routes = Object.freeze({
  customers: "customers",
  customerNew: "customers/new",
  customerEdit: "customers/:id/edit",
  login: "login",
  priceGroups: "price-groups",
  priceGroupNew: "price-groups/new",
  priceGroupNewSKUNew: "sku/new/:brandId",
  priceGroupNewSKUEdit: ":id/edit/:brandId",
  priceGroupEdit: "price-groups/:id/edit",
  brands: "brands",
  brandNew: "brands/new",
  brandEdit: "brands/:id/edit",
  items: "items",
  itemsView: "items/:id/view",
  adminList: "admins",
  adminNew: "admins/new",
  adminEdit: "admins/:id/edit",
  addressBooks: "addressBooks",
  shippingAddressBooks: "shippingAddressBooks",
  locationBook: "locationBook",
  locationNew: "locationBook/new",
  locationEdit: "locationBook/:id/edit",
  warehouseNew: "warehouse/new",
  warehouseEdit: "warehouse/:id/edit",
  addressBookNew: "addressBooks/new",
  addBrandToCustomer: "addbrandaccesstocustomer",
  brandAccessEdit: "brandaccess/:id/edit",
  ownedBrandsEdit: "ownedBrands/:id/edit",
  associateBrandWithCustomer: "associatebrandwithcustomer",
  associateCustomerWithBrand: "associatecustomerwithbrand",
  addressBookEdit: "addressBooks/:id/edit",
  shippingAddressBookEdit: "shippingAddressBooks/:id/edit",
  shippingAddressBookNew: "shippingAddressBooks/new",
  someBulshit: "someBulshit",
  addBrandAccessToBrand: "addBrandAccessToBrand"
});

export default routes;
