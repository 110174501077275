import React, { useEffect, useState } from "react";
import WarehouseDetails from "../../../../../components/customers/customer-details/warehouse-details";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CustomerTempContainer } from "../../../../../components/customers/customer-details/container";
import { GetAddressView } from "../../../../../common/services/view-service";

export default function WarehouseEditPage() {
  const { id } = useParams();
  const customerTempState = useSelector((state) => state.customerTempReducer);
  const warehouses = customerTempState.warehouses;
  const warehouse = warehouses.find((item) => item.id == id);
  const initForm = !!warehouse ? warehouse : {};
  const [form, setForm] = useState(initForm);
  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);

  useEffect(() => {
    let warehouseLocation = customerTempState.warehouseLocation;
    if (!!warehouseLocation) {
      setForm((prevForm) => ({
        ...prevForm,
        ["location"]: warehouseLocation,
        ["locationName"]: warehouseLocation.name,
      }));
    }
  }, [customerTempState]);

  function Save() {
    container.UpdateWarehouse(form);
    container.ClearWarehouseLocation();
  }
  return (
    <div>
      <WarehouseDetails
        form={form}
        setForm={setForm}
        Save={Save}
      ></WarehouseDetails>
    </div>
  );
}
