import { api } from "../../../api/axios";

const controller = "Item";

export async function GetItemsPagedAsync(
  PageIndex,
  PageSize = 20,
  SKU = "",
  SortDescending = false
) {
  return await api.get(
    `${controller}/GetItemsPaginated?PageSize=${PageSize}&PageIndex=${PageIndex}&SortDescending=${SortDescending}&SKU=${SKU}`
  );
}

export async function GetItemByIdAsync(id) {
  return await api.get(`${controller}/GetItems/${id}`);
}

export async function GetItemsPaginatedByBrandId(
  PageIndex,
  BrandId,
  PageSize = 20,
  SKU = "",
  SortDescending = false,
) {
  return await api.get(`${controller}/GetItemsPaginatedByBrandId/?PageSize=${PageSize}&PageIndex=${PageIndex}&SortDescending=${SortDescending}&SKU=${SKU}&BrandId=${BrandId}`);
}
export async function GetItemsIdsandSKUsByBrandIdAsync(id) {
  return await api.get(`${controller}/GetItemNamesAndIdsByBrandId/${id}`);
}
