export const dataTableCustomStyles = {
  headCells: {
    style: {
      backgroundColor: "#E6E3E3",
      border: "1px solid #DAD6D6",
    },
  },
  rows: {},
  cells: {
    style: {
      border: "1px solid #DAD6D6",
      borderTop: "none",
    },
  },
};
