import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import "./style.css";
import { useSelector } from "react-redux";
export default function PhoneTextField({
  form,
  setForm,
  handleInput,
  error,
  autocompleteStyles,
  textFieldStyles,
}) {
  const phoneNumberStatuses = useSelector(
    (state) => state.statusReducer.countryCodes
  );
  const phoneNumberView = phoneNumberStatuses.map(
    (item) => `${item.telephoneCode} ${item.shortenedName}`
  );
  return (
    <div className="container-phone">
      <Autocomplete
        style={autocompleteStyles}
        options={phoneNumberView}
        value={form.countrytel || ""}
        name="countrytel"
        onChange={(e, v) => {
          setForm((prevForm) => ({
            ...prevForm,
            ["countrytel"]: v,
          }));
        }}
        renderInput={(params) => (
          <TextField error={!!error} {...params} placeholder="Phone code" />
        )}
      ></Autocomplete>
      <TextField
        error={!!error}
        helperText={error}
        label="Phone Number"
        required
        type="text"
        placeholder="222-222-22-22"
        name="phone"
        value={form.phone || ""}
        onChange={handleInput}
        inputProps={{
          style: textFieldStyles,
        }}
      />
    </div>
  );
}
