import {
  AddLocationAddress,
  ClearLocationState,
} from "../../../../redux/actions/location/types";

export const LocationContainer = (dispatch) => ({
  AddAddress(data) {
    dispatch(AddLocationAddress(data));
  },
  ClearLocationState() {
    dispatch(ClearLocationState());
  },
});
