import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as FiIcons from "react-icons/fi";
import "./style.css";
import { useSelector } from "react-redux";
import AutoComplete from "../../../../ui/AutoComplete";
import { TextField } from "@mui/material";
export default function BrandAccessDetails({
  form,
  setForm,
  Save,
  priceGroups,
}) {
  const statusState = useSelector((state) => state.statusReducer);
  const brandAccessStatus = statusState.approvalBrandstatuses;
  const [beenSubmitted, setBeenSubmitted] = useState(false);
  const [error, setError] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    validate();
  }, [form]);

  const zeroingerrors = () => {
    setError({});
  };
  const zeroingvaliables = () => {
    setForm({});
  };

  const validate = () => {
    const allErrors = {};
    zeroingerrors();
    if (!form.status) {
      allErrors.status = "Please choose a brans access status.";
    }
    if (!form.name) {
      allErrors.name = "Name is required.";
    }
    setError(allErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setBeenSubmitted(true);

    if (Object.keys(error).length === 0) {
      Save();
      zeroingvaliables();
      setBeenSubmitted(false);
      GoBack();
    }
  };

  function GoBack() {
    navigate(-1);
  }

  const handleInput = (event) => {
    const { target } = event;
    const value = target.value;
    setForm((prevForm) => ({
      ...prevForm,
      [target.name]: value,
    }));
  };

  return (
    <div>
      <div className="header">
        <div className="header-contaner">
          <h3>Brand Access Details</h3>
          <h3>{form.name}</h3>
        </div>
        <div onClick={GoBack} className="button-content-add-brand">
          <FiIcons.FiPlus className="close-btn" />
        </div>
      </div>
      <div className="body">
        <Form noValidate onSubmit={handleSubmit}>
          <div className="margin-styles">
            <AutoComplete
              error={beenSubmitted ? error.status : null}
              className="text-field-styles"
              name="status"
              form={form}
              setForm={setForm}
              inputData={brandAccessStatus}
              label={"Status"}
            />
          </div>
          <div className="margin-styles">
            <AutoComplete
              error={beenSubmitted ? error.pricingLevel : null}
              className="text-field-styles"
              name="pricingLevel"
              form={form}
              setForm={setForm}
              inputData={priceGroups.map((item) => {
                if (item.status == "Default") return item.name + " (Default)";
                return item.name;
              })}
              label="Pricing Level"
            />
          </div>
          <div className="margin-styles">
          <TextField
                helperText={beenSubmitted ? error.name : null}
                label="Name"
                type="text"
                placeholder="Name"
                name="name"
                value={form?.name || ""}
                onChange={handleInput}
              />
            </div>
          <div className="btn-container">
            <Button className="action-button" variant="light" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
