import { all, put, takeEvery } from "redux-saga/effects";
import { LoginSuccessful, Logout, authTypes } from "../actions/auth/types";
import { removeAccessToken, setAccessToken } from "../../utils/accessToken";
import jwtDecode from "jwt-decode";
import { EmailClaim, RoleClaim, TOKEN, UserIdClaim } from "../constants";
import { statusActionTypes } from "../actions/status";

function* LoginSuccessWorker({ payload }) {
  let data = payload.data;
  const decoded = jwtDecode(data.accessToken);
  setAccessToken(data.accessToken);
  data.user = {
    Id: decoded[UserIdClaim],
    Roles: decoded[RoleClaim],
    Email: decoded[EmailClaim],
  };
  yield all([put(statusActionTypes.AddStatusesAsync())]);
  yield put(LoginSuccessful(data));
}

function* LogoutWorker() {
  removeAccessToken();
  yield put(Logout());
}

export default function* authWatcher() {
  yield takeEvery(authTypes.ASYNC_LOGIN_SUCCESSFUL, LoginSuccessWorker);
  yield takeEvery(authTypes.ASYNC_LOGOUT, LogoutWorker);
}
