import React from "react";
import AdminDetails from "../../../components/admin/admin-details";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  GetAdminByIdAsync,
  UpdateAdminUserAsync,
} from "../../../components/admin/services";
import Loader from "../../../ui/loader";

export default function AdminEditPage() {
  const { id } = useParams();
  const roles = useSelector((state) => state.statusReducer.roles);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ roles: [] });
  const user = useSelector((state) => state.authReducer.user);
  const [disabledPassword, setDisabledPassword] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await GetAdminByIdAsync(id);
        setForm(response.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  function RolesFilter(roles) {
    const isAdmin = user.Roles.includes("Admin");
    return roles.filter((item) => {
      if (isAdmin && item === "Admin") {
        return true;
      }
      if (!isAdmin && item === "Admin") {
        return false;
      }

      return true;
    });
  }
  async function Save() {
    try {
      let response = await UpdateAdminUserAsync(form);
      return response;
    } catch (error) {
      return error;
    }
  }
  return (
    <div className="items-list">
      {loading ? (
        <Loader></Loader>
      ) : (
        <AdminDetails
          form={form}
          setForm={setForm}
          Save={Save}
          roles={RolesFilter(roles)}
          successfulMessage={`User ${form.email} updated successfully!`}
          disabledPassword={disabledPassword}
          setDisabledPassword={setDisabledPassword}
        ></AdminDetails>
      )}
    </div>
  );
}
