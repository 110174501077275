import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as FiIcons from "react-icons/fi";
import { useSelector } from "react-redux";
import AutoComplete from "../../../ui/AutoComplete";
import { useNavigate } from "react-router-dom";

export default function SKUDetails({ form, setForm, Save, Items }) {
  const pricingReferences = useSelector(
    (state) => state.statusReducer.pricingReferences
  );
  const navigate = useNavigate();
  const [beenSubmitted, setBeenSubmitted] = useState(false);
  const [error, setError] = useState({});
  const zeroingvaliables = () => {
    setForm({});
  };
  const zeroingerrors = () => {
    setError({});
  };
  function GoBack() {
    navigate(-1);
  }
  const validate = () => {
    const allErrors = {};
    zeroingerrors();
    if (!form.sku) {
      allErrors.sku = "Please write sku!";
    }
    if (!form.quantity) {
      allErrors.quantity = "Please write minimumQuantity!";
    }
    if (!form.amount && !form.percent) {
      allErrors.amount = "Please write amount or percent!";
      allErrors.discountType = "Please choose amount or percent!";
    } else {
      if (form.discountType == "percent" && form.amount > 100) {
        allErrors.amount = "Percentage can't be more that 100";
        allErrors.discountType = "Please choose amount or percent!";
      }
    }
    if (!form.reference) {
      allErrors.reference = "Please write reference!";
    }

    setError(allErrors);
  };

  useEffect(() => {
    validate();
  }, [form]);

  const handleInput = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (target.name == "amount") {
      const double = value.replace(/[^-?\d*\.?\d+]/g, "");
      setForm((prevForm) => ({
        ...prevForm,
        [target.name]: double,
      }));
      return;
    }
    if (target.name == "quantity") {
      const onlyNums = value.replace(/[^0-9]/g, "");
      setForm((prevForm) => ({
        ...prevForm,
        [target.name]: onlyNums,
      }));
      return;
    }

    setForm((prevForm) => ({
      ...prevForm,
      [target.name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setBeenSubmitted(true);
    if (Object.keys(error).length === 0) {
      Save();
      zeroingvaliables();
      setBeenSubmitted(false);
      GoBack();
    }
  };

  return (
    <div>
      <div className="header">
        <h3>SKU Details</h3>
        <div onClick={GoBack} className="button-content-address-book">
          <FiIcons.FiPlus className="close-btn" />
        </div>
      </div>
      <body className="body">
        <Form noValidate onSubmit={handleSubmit}>
          <div className="margin-styles">
            <AutoComplete
              styles={{ width: "11vw" }}
              error={beenSubmitted ? error.sku : null}
              name={"sku"}
              form={form}
              setForm={setForm}
              inputData={Items.map((item) => item.sku)}
              label={"SKU"}
            ></AutoComplete>
          </div>
          <div className="margin-styles">
            <TextField
              error={beenSubmitted ? error.quantity : null}
              helperText={beenSubmitted ? error.quantity : null}
              label="Minimum Quantity"
              required
              type="text"
              placeholder="10"
              name="quantity"
              value={form.quantity || ""}
              onChange={handleInput}
            />
          </div>
          <div className="margin-styles">
            <div className="form-container">
              <FormControl>
                <TextField
                  error={beenSubmitted ? error.amount : null}
                  label="Discount number"
                  required
                  type="text"
                  placeholder="10.5"
                  name="amount"
                  value={form.amount || ""}
                  onChange={handleInput}
                />
                <FormHelperText error type={error}>
                  {beenSubmitted ? error.amount : null}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <Select
                  style={{ width: "11vw" }}
                  value={form.discountType || ""}
                  error={beenSubmitted ? error.discountType : null}
                  onChange={handleInput}
                  name="discountType"
                >
                  <MenuItem value={"amount"}>Amount $</MenuItem>
                  <MenuItem value={"percent"}>Percent %</MenuItem>
                </Select>
                <FormHelperText error type={error}>
                  {beenSubmitted ? error.discountType : null}
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className="margin-styles">
            <FormControl>
              <Select
                style={{ width: "11vw" }}
                value={form.reference || ""}
                error={beenSubmitted ? error.reference : null}
                onChange={handleInput}
                name="reference"
              >
                {pricingReferences.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error type={error}>
                {beenSubmitted ? error.reference : null}
              </FormHelperText>
            </FormControl>
          </div>
          <div className="btn-container">
            <Button className="action-button" variant="light" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </body>
    </div>
  );
}
