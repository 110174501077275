import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../../../../ui/AutoComplete";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import * as FiIcons from "react-icons/fi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Messages } from "../../../../redux/constants";

export default function OwnedBrandDetails({
  form,
  setForm,
  Save,
  priceGroups,
}) {
  const statusState = useSelector((state) => state.statusReducer);
  const accessDefault = statusState.accessDefault;
  const [beenSubmitted, setBeenSubmitted] = useState(false);
  const [error, setError] = useState({});
  const navigate = useNavigate();
  function GoBack() {
    navigate(-1);
  }

  const zeroingerrors = () => {
    setError({});
  };
  const zeroingvaliables = () => {
    setForm({});
  };

  const handleInput = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setForm((prevForm) => ({
      ...prevForm,
      [target.name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setBeenSubmitted(true);

    if (Object.keys(error).length === 0) {
      Save();
      zeroingvaliables();
      setBeenSubmitted(false);
      GoBack();
    } else {
      toast.error(Messages.FillFields);
    }
  };

  return (
    <div>
      <div className="header">
        <div className="header-contaner">
          <h3>Brand Access Details</h3>
          <h3>{form.name}</h3>
        </div>
        <div onClick={GoBack} className="button-content-add-brand">
          <FiIcons.FiPlus className="close-btn" />
        </div>
      </div>
      <div className="body">
        <Form noValidate onSubmit={handleSubmit}>
          <div className="margin-styles">
            <AutoComplete
              error={beenSubmitted ? error.status : null}
              className="text-field-styles"
              name="accessDefault"
              form={form}
              setForm={setForm}
              inputData={accessDefault}
              label={"Default Access"}
            />
          </div>
          <div className="margin-styles">
            <FormControlLabel
              name="dropshipAvailable"
              type="checkbox"
              control={
                <Checkbox
                  name="dropshipAvailable"
                  checked={!!form.dropshipAvailable}
                  onChange={handleInput}
                />
              }
              label="Allow Dropship"
            />
          </div>
          <div className="margin-styles">
            <AutoComplete
              error={beenSubmitted ? error.pricingLevel : null}
              name="pricingLevel"
              form={form}
              setForm={setForm}
              inputData={priceGroups.map((item) => {
                if (item.status == "Default") return item.name + " (Default)";
                return item.name;
              })}
              label="Pricing Level"
            />
          </div>

          <div className="btn-container">
            <Button className="action-button" variant="light" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
