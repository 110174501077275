import axios from "axios";
import { getAccessToken } from "../utils/accessToken";
import useRefreshToken from "../hooks/useRefreshToken";
import { LOGGED_USER_ID } from "../redux/constants";
import { store } from "../redux/storage";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

api.interceptors.request.use(
  (config) => {
    const user = store.getState().authReducer.user;
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers[LOGGED_USER_ID] = `${user.Id}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const refresh = useRefreshToken(api);
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const newAccessToken = await refresh();
      prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
      api(prevRequest);
    }
    return Promise.reject(error);
  }
);
