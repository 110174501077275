import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import BrandDetails from "../../../components/brands/brands-details";
import { GetBrandByIdAsync } from "../../../components/brands/service/BrandService";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../ui/loader";
import { CreateBrandAsync } from "../../../components/brands/service/BrandService";
import { CreatedPrefix } from "../../../redux/constants";
import { BrandTempContainer } from "../../../components/brands/container";
import { CreateUpdateBrandRequest, GetBrandViewModel, GetBrandOwnerView, GetItemsViewModel, GetPricingLevelsViewModel, GetBrandAccessesViewModel } from "../../../components/brands/service/HelpService";
export default function BrandCreatePage() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const container = BrandTempContainer(dispatch);
  const brandTempState = useSelector((state) => state.brandTempReducer);
  const [form, setForm] = useState({});
  
  function setTheForm(value)
  {
    setForm(value);
  }

  useEffect(() => {
    (async () => {
      try {
          setLoading(true);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
      container.ClearTempBrand();
    })();
  }, []);

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      ["brandView"]: GetBrandViewModel(brandTempState?.brand, brandTempState?.accessDefault, brandTempState?.owner?.id),
      ["brandAccessView"]: GetBrandAccessesViewModel(brandTempState?.brandAccesses),
      ["itemsView"]: GetItemsViewModel(brandTempState?.items),
      ["ownerView"]: GetBrandOwnerView(brandTempState?.owner),
      ["pricingLevelsView"]: GetPricingLevelsViewModel(brandTempState?.pricingLevels)
    }));
  }, [brandTempState]);

  async function Save() {
    const brand = CreateUpdateBrandRequest(
      form,
      brandTempState
    );
    try {
      console.log("Save after try");
      const response = await CreateBrandAsync(brand);
      container.ClearTempBrand();
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
  return (
    <div className="items-list">
      {loading ? (
        <Loader></Loader>
      ) : (
        <BrandDetails
          form={form}
          setForm={setTheForm}
          Save={Save}
          successfulMessage={`${form.name} successfully created!`}
        ></BrandDetails>
      )}
    </div>
  );
}
