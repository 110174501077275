import { api } from "../../api/axios";

const controller = "Address";

export async function CreateAddressAsync(address) {
  return await api.post(`/${controller}/CreateAddress/`, address);
}

export async function UpdateAddressAsync(address) {
  return await api.put(`/${controller}/UpdateAddress/`, address);
}
