import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import CustomerPage from "./pages/customers/customer.page";
import NotFoundPage from "./pages/not.found.page";
import CustomerCreatePage from "./pages/customers/customer-create.page";
import LoginPage from "./pages/login/LoginPage";
import routes from "./routes";
import PriceGroupsPage from "./pages/price-groups/price-groups-list.page";
import ItemsPage from "./pages/items/items-list.page";
import Modal from "./ui/Modal";
import AssociateBrandListPage from "./pages/customers/shared/brands/associatebrandlist.page";
import AddressCreatePage from "./pages/customers/shared/addresses/address-create.page";
import AddressEditPage from "./pages/customers/shared/addresses/address-edit.page";
import BrandListPage from "./pages/customers/shared/brands/brandlist.page";
import BillingAddressBooksPage from "./pages/customers/shared/addresses/address-book-billing-list.page";
import ShippingAddressBooksPage from "./pages/customers/shared/addresses/address-book-shipping-list.page";
import WarehouseCreatePage from "./pages/customers/shared/warehouses/warehousecreate.page";
import WarehouseEditPage from "./pages/customers/shared/warehouses/warehouseedit.page";
import BrandAccessEditPage from "./pages/customers/shared/brandaccess/brandaccess.edit";
import OwnedBrandsEditPage from "./pages/customers/shared/ownedbrands/ownedbrands.edit";
import CustomerEditPage from "./pages/customers/customer-edit.page";
import "./global.css";
import BrandsPage from "./pages/brands/brands-list.page";
import BrandCreatePage from "./pages/brands/brands-create.page";
import BrandEditPage from "./pages/brands/brands-edit.page";
import PriceGroupCreatePage from "./pages/price-groups/price-groups-create.page";
import PriceGroupEditPage from "./pages/price-groups/price-groups-edit.page";
import SKUCreatePage from "./pages/price-groups/sku-create.page";
import SKUEditPage from "./pages/price-groups/sku-edit.page";
import AdminListPage from "./pages/admin/admin-list.page";
import ItemViewPage from "./pages/items/item-view.page";
import AdminCreatePage from "./pages/admin/admin-create.page";
import AdminEditPage from "./pages/admin/admin-edit.page";
import LocationsBookPage from "./pages/customers/shared/locations/location-book-list.page";
import LocationCreatePage from "./pages/customers/shared/locations/location-create.page";
import LocationEditPage from "./pages/customers/shared/locations/location-edit.page";
import LocationAddressEditPage from "./pages/customers/shared/locations/location-address-edit.page";
import LocationAddressCreatePage from "./pages/customers/shared/locations/location-address-create.page";
import AssociateCustomerListPage from "./pages/brands/shared/customers/associate-customer-list.page";
import BrandAccessBrandEditPage from "./pages/brands/shared/brandaccess.edit";
import AddBrandAccessPage from "./pages/brands/shared/brandaccess.create";
function App() {
    return (
        <div>
            <Routes>
                <Route path={routes.login} element={<LoginPage />} />
                <Route path="/" element={<Layout />}>
                    <Route path={routes.customers} element={<CustomerPage />} />
                    <Route path={routes.customerNew} element={<CustomerCreatePage />}>
                        <Route
                            path={routes.shippingAddressBookEdit}
                            element={
                                <Modal>
                                    <AddressEditPage></AddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.shippingAddressBookNew}
                            element={
                                <Modal>
                                    <AddressCreatePage></AddressCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.warehouseNew}
                            element={
                                <Modal>
                                    <WarehouseCreatePage></WarehouseCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.warehouseEdit}
                            element={
                                <Modal>
                                    <WarehouseEditPage></WarehouseEditPage>
                                </Modal>
                            }
                        />

                        <Route
                            path={`${routes.warehouseNew}/${routes.locationBook}`}
                            element={
                                <Modal>
                                    <LocationsBookPage></LocationsBookPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationNew}`}
                            element={
                                <Modal>
                                    <LocationCreatePage></LocationCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationNew}/${routes.addressBookNew}`}
                            element={
                                <Modal>
                                    <LocationAddressCreatePage></LocationAddressCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationNew}/${routes.addressBookEdit}`}
                            element={
                                <Modal>
                                    <LocationAddressEditPage></LocationAddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationEdit}/${routes.addressBookEdit}`}
                            element={
                                <Modal>
                                    <LocationAddressEditPage></LocationAddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationEdit}`}
                            element={
                                <Modal>
                                    <LocationEditPage></LocationEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationBook}`}
                            element={
                                <Modal>
                                    <LocationsBookPage></LocationsBookPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationNew}`}
                            element={
                                <Modal>
                                    <LocationCreatePage></LocationCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationEdit}`}
                            element={
                                <Modal>
                                    <LocationEditPage></LocationEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationNew}/${routes.addressBookNew}`}
                            element={
                                <Modal>
                                    <LocationAddressCreatePage></LocationAddressCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationNew}/${routes.addressBookEdit}`}
                            element={
                                <Modal>
                                    <LocationAddressEditPage></LocationAddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationEdit}/${routes.addressBookEdit}`}
                            element={
                                <Modal>
                                    <LocationAddressEditPage></LocationAddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.addressBooks}
                            element={
                                <Modal>
                                    <BillingAddressBooksPage></BillingAddressBooksPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.addressBookNew}
                            element={
                                <Modal>
                                    <AddressCreatePage></AddressCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.addressBookEdit}
                            element={
                                <Modal>
                                    <AddressEditPage></AddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.shippingAddressBooks}
                            element={
                                <Modal>
                                    <ShippingAddressBooksPage></ShippingAddressBooksPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.addBrandToCustomer}
                            element={
                                <Modal>
                                    <BrandListPage></BrandListPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.associateBrandWithCustomer}
                            element={
                                <Modal>
                                    <AssociateBrandListPage></AssociateBrandListPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.brandAccessEdit}
                            element={
                                <Modal>
                                    <BrandAccessEditPage></BrandAccessEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.ownedBrandsEdit}
                            element={
                                <Modal>
                                    <OwnedBrandsEditPage></OwnedBrandsEditPage>
                                </Modal>
                            }
                        />
                    </Route>
                    <Route path={routes.customerEdit} element={<CustomerEditPage />}>
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationBook}`}
                            element={
                                <Modal>
                                    <LocationsBookPage></LocationsBookPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationNew}`}
                            element={
                                <Modal>
                                    <LocationCreatePage></LocationCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationNew}/${routes.addressBookNew}`}
                            element={
                                <Modal>
                                    <LocationAddressCreatePage></LocationAddressCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationNew}/${routes.addressBookEdit}`}
                            element={
                                <Modal>
                                    <LocationAddressEditPage></LocationAddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationEdit}/${routes.addressBookEdit}`}
                            element={
                                <Modal>
                                    <LocationAddressEditPage></LocationAddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationEdit}`}
                            element={
                                <Modal>
                                    <LocationEditPage></LocationEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationBook}`}
                            element={
                                <Modal>
                                    <LocationsBookPage></LocationsBookPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationNew}`}
                            element={
                                <Modal>
                                    <LocationCreatePage></LocationCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationEdit}`}
                            element={
                                <Modal>
                                    <LocationEditPage></LocationEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationNew}/${routes.addressBookNew}`}
                            element={
                                <Modal>
                                    <LocationAddressCreatePage></LocationAddressCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationNew}/${routes.addressBookEdit}`}
                            element={
                                <Modal>
                                    <LocationAddressEditPage></LocationAddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseEdit}/${routes.locationEdit}/${routes.addressBookEdit}`}
                            element={
                                <Modal>
                                    <LocationAddressEditPage></LocationAddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.shippingAddressBooks}
                            element={
                                <Modal>
                                    <ShippingAddressBooksPage></ShippingAddressBooksPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.warehouseNew}
                            element={
                                <Modal>
                                    <WarehouseCreatePage></WarehouseCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={`${routes.warehouseNew}/${routes.locationBook}`}
                            element={
                                <Modal>
                                    <LocationsBookPage></LocationsBookPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.warehouseEdit}
                            element={
                                <Modal>
                                    <WarehouseEditPage></WarehouseEditPage>
                                </Modal>
                            }
                        />

                        <Route
                            path={routes.addressBooks}
                            element={
                                <Modal>
                                    <BillingAddressBooksPage></BillingAddressBooksPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.addressBookNew}
                            element={
                                <Modal>
                                    <AddressCreatePage></AddressCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.addressBookEdit}
                            element={
                                <Modal>
                                    <AddressEditPage></AddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.shippingAddressBookEdit}
                            element={
                                <Modal>
                                    <AddressEditPage></AddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.shippingAddressBookNew}
                            element={
                                <Modal>
                                    <AddressCreatePage></AddressCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.addressBookEdit}
                            element={
                                <Modal>
                                    <AddressEditPage></AddressEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.addBrandToCustomer}
                            element={
                                <Modal>
                                    <BrandListPage></BrandListPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.associateBrandWithCustomer}
                            element={
                                <Modal>
                                    <AssociateBrandListPage></AssociateBrandListPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.brandAccessEdit}
                            element={
                                <Modal>
                                    <BrandAccessEditPage></BrandAccessEditPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.ownedBrandsEdit}
                            element={
                                <Modal>
                                    <OwnedBrandsEditPage></OwnedBrandsEditPage>
                                </Modal>
                            }
                        />
                    </Route>
                    <Route path={routes.priceGroups} element={<PriceGroupsPage />} />
                    <Route path={routes.priceGroupNew} element={<PriceGroupCreatePage />}>
                        <Route
                            path={routes.priceGroupNewSKUNew}
                            element={
                                <Modal>
                                    <SKUCreatePage></SKUCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.priceGroupNewSKUEdit}
                            element={
                                <Modal>
                                    <SKUEditPage></SKUEditPage>
                                </Modal>
                            }
                        />
                    </Route>
                    <Route path={routes.priceGroupEdit} element={<PriceGroupEditPage />}>
                        <Route
                            path={routes.priceGroupNewSKUNew}
                            element={
                                <Modal>
                                    <SKUCreatePage></SKUCreatePage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.priceGroupNewSKUEdit}
                            element={
                                <Modal>
                                    <SKUEditPage></SKUEditPage>
                                </Modal>
                            }
                        />
                    </Route>
                    <Route path={routes.brands} element={<BrandsPage />} />
                    <Route path={routes.brandNew} element={<BrandCreatePage />}>

                    <Route
                            path={routes.associateCustomerWithBrand}
                            element={
                                <Modal>
                                    <AssociateCustomerListPage></AssociateCustomerListPage>
                                </Modal>
                            }
                        />
                        <Route
                            path={routes.addBrandAccessToBrand}
                            element={
                                <Modal>
                                    <AddBrandAccessPage></AddBrandAccessPage>
                                </Modal>
                            }
                        />
                        
                    </Route>
                    <Route path={routes.brandEdit} element={<BrandEditPage />}>
                    <Route
                            path={routes.addBrandAccessToBrand}
                            element={
                                <Modal>
                                    <AddBrandAccessPage></AddBrandAccessPage>
                                </Modal>
                            }
                        />
                    <Route
                            path={routes.ownedBrandsEdit}
                            element={
                                <Modal>
                                    <AssociateBrandListPage></AssociateBrandListPage>
                                </Modal>
                            }
                            
                        />
                    <Route
                            path={routes.brandAccessEdit}
                            element={
                                <Modal>
                                    <BrandAccessBrandEditPage></BrandAccessBrandEditPage>
                                </Modal>
                            }
                        />
                    <Route
                            path={routes.associateCustomerWithBrand}
                            element={
                                <Modal>
                                    <AssociateCustomerListPage></AssociateCustomerListPage>
                                </Modal>
                            }
                        />
                        
                    </Route>
                    <Route path={routes.items} element={<ItemsPage />} />
                    <Route path={routes.itemsView} element={<ItemViewPage />} />
                    <Route path={routes.adminList} element={<AdminListPage />} />
                    <Route path={routes.adminNew} element={<AdminCreatePage />} />
                    <Route path={routes.adminEdit} element={<AdminEditPage />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
