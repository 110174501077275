import { customerTempTypes } from "../../actions/customers/customer-temp/types";
import { removeItemsNames } from "../../constants";

const defaultState = {
  customer: undefined,
  addresses: [],
  billingAddressId: undefined,
  shippingAddressId: undefined,
  warehouseLocation: undefined,
  selectedBrandsAccess: [],
  selectedAssociateBrands: [],
  warehouses: [],
  tempWarehouse: undefined,
  ownedBrands: [],
  itemsToRemove: [],
};

export const CustomerTempReducer = (
  state = defaultState,
  { type, payload }
) => {
  switch (type) {
    case customerTempTypes.FILL_TEMP_CUSTOMER:
      return {
        ...state,
        customer: payload,
      };
    case customerTempTypes.CLEAR_STATE:
      return {
        ...defaultState,
      };
    case customerTempTypes.PRESAVE_WAREHOUSE:
      return {
        ...state,
        tempWarehouse: payload,
      };
    case customerTempTypes.CLEAR_TEMP_WAREHOUSE:
      return {
        ...state,
        tempWarehouse: undefined,
      };
    case customerTempTypes.REMOVE_ASSOCIATE_BRAND:
      return {
        ...state,
        itemsToRemove: [
          ...state.itemsToRemove,
          { item: removeItemsNames.OwnedBrand, id: payload },
        ],
        ownedBrands: [
          ...state.ownedBrands.filter((item) => {
            if (item.id != payload) return true;
            return false;
          }),
        ],
        selectedAssociateBrands: [
          ...state.selectedAssociateBrands.filter((item) => {
            if (item.id != payload) return true;
            return false;
          }),
        ],
      };
    case customerTempTypes.REMOVE_BRAND_ACCESS:
      return {
        ...state,
        itemsToRemove: [
          ...state.itemsToRemove,
          { item: removeItemsNames.BrandAccess, id: payload },
        ],
        selectedBrandsAccess: [
          ...state.selectedBrandsAccess.filter((item) => {
            if (item.id != payload) return true;
            return false;
          }),
        ],
      };
    case customerTempTypes.REMOVE_WAREHOUSE:
      return {
        ...state,
        itemsToRemove: [
          ...state.itemsToRemove,
          { item: removeItemsNames.Warehouse, id: payload },
        ],
        warehouses: [
          ...state.warehouses.filter((item) => {
            if (item.id != payload) return true;
            return false;
          }),
        ],
      };
    case customerTempTypes.REMOVE_ADDRESS:
      return {
        ...state,
        itemsToRemove: [
          ...state.itemsToRemove,
          { item: removeItemsNames.Address, id: payload },
        ],
        addresses: [
          ...state.addresses.filter((item) => {
            if (item.id != payload) return true;
            return false;
          }),
        ],
      };
    case customerTempTypes.SELECT_OWNED_BRANDS:
      return { ...state, ownedBrands: [...state.ownedBrands, ...payload] };
    case customerTempTypes.ADD_ADDRESS:
      if (Array.isArray(payload))
        return { ...state, addresses: [...state.addresses, ...payload] };
      return { ...state, addresses: [...state.addresses, payload] };
    case customerTempTypes.ADD_WAREHOUSE:
      if (Array.isArray(payload))
        return { ...state, warehouses: [...state.warehouses, ...payload] };
      return { ...state, warehouses: [...state.warehouses, payload] };
    case customerTempTypes.CLEAR_WAREHOUSE_LOCATION:
      return { ...state, warehouseLocation: undefined };
    case customerTempTypes.UPDATE_ADDRESS:
      return {
        ...state,
        addresses: [
          ...state.addresses.map((item) => {
            if (item.id == payload.id) return payload;
            return item;
          }),
        ],
      };
    case customerTempTypes.UPDATE_WAREHOUSE:
      return {
        ...state,
        warehouses: [
          ...state.warehouses.map((item) => {
            if (item.id == payload.id) return payload;
            return item;
          }),
        ],
      };
    case customerTempTypes.UPDATE_BRAND_ACCESS:
      return {
        ...state,
        selectedBrandsAccess: [
          ...state.selectedBrandsAccess.map((item) => {
            if (item.id == payload.id) return payload;
            return item;
          }),
        ],
      };
    case customerTempTypes.UPDATE_OWNED_BRAND:
      return {
        ...state,
        ownedBrands: [
          ...state.ownedBrands.map((item) => {
            if (item.id == payload.id) return payload;
            return item;
          }),
        ],
        selectedAssociateBrands: [
          ...state.selectedAssociateBrands.map((item) => {
            if (item.id == payload.id) return payload;
            return item;
          }),
        ],
      };
    case customerTempTypes.SELECT_BILLING_ADDRESS:
      return {
        ...state,
        billingAddressId: payload,
      };
    case customerTempTypes.SELECT_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddressId: payload,
      };
    case customerTempTypes.SELECT_WAREHOUSE_LOCATION:
      return {
        ...state,
        warehouseLocation: payload,
      };
    case customerTempTypes.SELECT_BRAND_ACCESSES:
      return {
        ...state,
        selectedBrandsAccess: [...payload],
      };
    case customerTempTypes.SELECT_ASSOCIATE_BRAND_ACCESSES:
      return {
        ...state,
        selectedAssociateBrands: [...payload],
      };
    default:
      return state;
  }
};
