import React from "react";
import AdminList from "../../../components/admin/admin-list";

export default function AdminListPage() {
  return (
    <div>
      <AdminList></AdminList>
    </div>
  );
}
