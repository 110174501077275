export const customerTempTypes = {
  // Synchronous
  ADD_ADDRESS: "ADD_ADDRESS",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  SELECT_BILLING_ADDRESS: "SELECT_BILLING_ADDRESS",
  SELECT_SHIPPING_ADDRESS: "SELECT_SHIPPING_ADDRESS",
  SELECT_BRAND_ACCESSES: "SELECT_BRAND_ACCESSES",
  SELECT_ASSOCIATE_BRAND_ACCESSES: "SELECT_ASSOCIATE_BRAND_ACCESSES",
  SELECT_OWNED_BRANDS: "SELECT_OWNED_BRANDS",
  UPDATE_BRAND_ACCESS: "UPDATE_BRAND_ACCESS",
  UPDATE_OWNED_BRAND: "UPDATE_OWNED_BRAND",
  REMOVE_ASSOCIATE_BRAND: "REMOVE_ASSOCIATE_BRAND",
  REMOVE_BRAND_ACCESS: "REMOVE_BRAND_ACCESS",
  REMOVE_ADDRESS: "REMOVE_ADDRESS",
  // Warehouse
  ADD_WAREHOUSE: "ADD_WAREHOUSE",
  UPDATE_WAREHOUSE: "UPDATE_WAREHOUSE",
  REMOVE_WAREHOUSE: "REMOVE_WAREHOUSE",
  SELECT_WAREHOUSE_LOCATION: "SELECT_WAREHOUSE_LOCATION",
  CLEAR_WAREHOUSE_LOCATION: "CLEAR_WAREHOUSE_LOCATION",
  CLEAR_TEMP_WAREHOUSE: "CLEAR_TEMP_WAREHOUSE",
  PRESAVE_WAREHOUSE: "PRESAVE_WAREHOUSE",
  FILL_TEMP_CUSTOMER: "FILL_TEMP_CUSTOMER",

  CLEAR_STATE: "CLEAR_STATE",
};

export function ClearTempCustomerState() {
  return {
    type: customerTempTypes.CLEAR_STATE,
  };
}

export function SaveTempWarehouseDataToCustomer(payload) {
  return {
    type: customerTempTypes.PRESAVE_WAREHOUSE,
    payload: payload,
  };
}

export function RemoveBrandAccessFromCustomer(payload) {
  return {
    type: customerTempTypes.REMOVE_BRAND_ACCESS,
    payload: payload,
  };
}

export function RemoveAddressFromCustomer(payload) {
  return {
    type: customerTempTypes.REMOVE_ADDRESS,
    payload: payload,
  };
}
export function RemoveAssosiateBrandFromCustomer(payload) {
  return {
    type: customerTempTypes.REMOVE_ASSOCIATE_BRAND,
    payload: payload,
  };
}

export function RemoveWarehouseFromCustomer(payload) {
  return {
    type: customerTempTypes.REMOVE_WAREHOUSE,
    payload: payload,
  };
}
export function UpdateBrandAccessToTempCustomer(payload) {
  return {
    type: customerTempTypes.UPDATE_BRAND_ACCESS,
    payload: payload,
  };
}
export function UpdateOwnedBrandToTempCustomer(payload) {
  return {
    type: customerTempTypes.UPDATE_OWNED_BRAND,
    payload: payload,
  };
}
export function ClearTempWarehouseToTempCustomer() {
  return {
    type: customerTempTypes.CLEAR_TEMP_WAREHOUSE,
  };
}
export function ClearWarehouseAddressToTempCustomer() {
  return {
    type: customerTempTypes.CLEAR_WAREHOUSE_LOCATION,
  };
}
export function UpdateWarehouseToTempCustomer(payload) {
  return {
    type: customerTempTypes.UPDATE_WAREHOUSE,
    payload: payload,
  };
}
export function CreateWarehouseToTempCustomer(payload) {
  return {
    type: customerTempTypes.ADD_WAREHOUSE,
    payload: payload,
  };
}
export function SelectAssociateBrandsToTempCustomer(payload) {
  return {
    type: customerTempTypes.SELECT_ASSOCIATE_BRAND_ACCESSES,
    payload: payload,
  };
}
export function SelectOwnedBrandsToTempCustomer(payload) {
  return {
    type: customerTempTypes.SELECT_OWNED_BRANDS,
    payload: payload,
  };
}

export function SelectBrandsAccessesToTempCustomer(payload) {
  return {
    type: customerTempTypes.SELECT_BRAND_ACCESSES,
    payload: payload,
  };
}

export function AddAddressToTempCustomer(payload) {
  return {
    type: customerTempTypes.ADD_ADDRESS,
    payload: payload,
  };
}

export function SelectBillingAddress(payload) {
  return {
    type: customerTempTypes.SELECT_BILLING_ADDRESS,
    payload: payload,
  };
}

export function FillTempCustomer(payload) {
  return {
    type: customerTempTypes.FILL_TEMP_CUSTOMER,
    payload: payload,
  };
}

export function SelectShippingAddress(payload) {
  return {
    type: customerTempTypes.SELECT_SHIPPING_ADDRESS,
    payload: payload,
  };
}

export function SelectWarehouseLocation(payload) {
  return {
    type: customerTempTypes.SELECT_WAREHOUSE_LOCATION,
    payload: payload,
  };
}

export function UpdateAddressToTempCustomer(payload) {
  return {
    type: customerTempTypes.UPDATE_ADDRESS,
    payload: payload,
  };
}
