import React from "react";
import AddressBooks from "../../../../../components/customers/customer-details/address-book.list";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { CustomerTempContainer } from "../../../../../components/customers/customer-details/container";

export default function BillingAddressBooksPage() {
  const customerTempState = useSelector((state) => state.customerTempReducer);
  const addresses = customerTempState.addresses;
  const [addressesBookView, setAddressesBookView] = useState([]);
  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);
  useEffect(() => {
    setAddressesBookView(addresses);
  }, [customerTempState]);

  const selectedId = !!customerTempState?.billingAddressId
    ? customerTempState?.billingAddressId
    : addresses[0]?.id;

  function Save(id) {
    container.SelectBillingAddress(id);
  }
  return (
    <div>
      <AddressBooks
        save={Save}
        addressesBookView={addressesBookView}
        selectedId={selectedId}
      ></AddressBooks>
    </div>
  );
}
