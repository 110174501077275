import { CreatedPrefix } from "../../redux/constants";

export function CreateRequest(form, skuTempState) {
  const skus = skuTempState.skus;
  const itemsToRemove = skuTempState.itemsToRemove;
  let request = {
    brandId: form.brandEntity.id,
    prices: [],
  };
  if (form.id.toString().includes(CreatedPrefix)) {
    request.id = 0;
  } else {
    request.id = form.id;
  }
  request.defaultPercent = form.defaultPercent;
  request.name = form.name;
  request.defaultReference = form.defaultReference;
  request.prices = skus;

  request.prices.map((item) => {
    if (item.id.toString().includes(CreatedPrefix)) {
      item.id = 0;
    }
    item.SKU = item.sku;
    return item;
  });

  // only for update, create endpoint doesn't contain such a field
  request.itemsToRemove = itemsToRemove.map((item) => {
    return {
      key: item.item,
      value: item.id,
    };
  });

  request.status = form.status;

  return request;
}

export function GetPriceGroupViewModel(inputModel) {
  return {
    id: inputModel.id,
    name: inputModel.name,
    brandName: inputModel.brand.name,
    defaultPercent: inputModel.defaultPercent,
    defaultReference: inputModel.defaultReference,
    status: inputModel.status,
  };
}

export function GetBrandViewModel(inputModel) {
  return {
    id: inputModel.id,
    name: inputModel.owner ? inputModel.owner.name : null,
    brand: inputModel.name,
    dropshipAvailable: inputModel.dropshipAvailable,
    accessDefault: inputModel.accessDefault,
  };
}


export function GetItemViewModel(inputModel) {
  return {
    id: inputModel.id,
    sku: inputModel.sku,
    brand: inputModel.brand.name,
    mpn: inputModel.mfrPartNumber,
    title: inputModel.title,
    cost: inputModel.cost,
    mspr: inputModel.mspr,
  };
}
