import React, { useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { dataTableCustomStyles } from "../../../common/datatable/styles";
import { useMemo } from "react";
import FilterComponent from "../../../ui/filter";
import { useEffect } from "react";
import { GetUserViewModel } from "../services/helpingService";
import { DeleteAdminByIdAsync, GetUsersPagedAsync } from "../services";
import { useSelector } from "react-redux";
import * as AiIcons from "react-icons/ai";
import { toast } from "react-toastify";

export default function AdminList() {
  const user = useSelector((state) => state.authReducer.user);
  const [loading, setLoading] = useState(false);
  const [usersView, setUsersView] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const filteredUsers = getFiltredItems();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [rowToRemoveAdmin, setRowToRemoveAdmin] = useState(-1);

  useEffect(() => {
    // Clear any existing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTimeout = setTimeout(() => {
      if (filterText !== "") {
        fetchUsers(page, pageSize, filterText);
      } else {
        fetchUsers(page, pageSize);
      }
    }, 1000);

    setTypingTimeout(newTimeout);
  }, [filterText]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        fetchUsers(page, pageSize, filterText);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  const getUsersPaged = async (page, pageSize, email = "") => {
    let response = await GetUsersPagedAsync(page, pageSize, email);
    return response.data;
  };

  const fetchUsers = async (page, pageSize, email = "") => {
    try {
      setLoading(true);
      const response = await getUsersPaged(page, pageSize, email);
      setUsersView([
        ...response.items.map((item) => {
          return GetUserViewModel(item);
        }),
      ]);
      setTotalRows(response.meta.totalCount);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  function getFiltredItems() {
    let items = usersView.filter(
      (item) =>
        item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase())
    );

    return items;
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="header">
        <FilterComponent
          placeholder="filter by email"
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const usersTableColums = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Roles",
      selector: (row) => row?.roles,
      sortable: true,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <div className="actions">
          <Link to={`${row.id}/edit`}>
            <Button
              style={{
                width: "4vw",
              }}
              variant="light"
            >
              Edit
            </Button>
          </Link>
        </div>
      ),
    },
    {
      name: "Remove",
      button: true,
      cell: (row) =>
        rowToRemoveAdmin !== row.id ? (
          <Button
            onClick={() => ConfidenceToRemoveAdmin(row.id)}
            style={{ width: "5vw" }}
            variant="light"
          >
            Remove
          </Button>
        ) : (
          <div className="delete-container">
            <Button
              variant="light"
              style={{ width: "2vw", marginRight: "3px" }}
              onClick={() => RemoveAdmin(rowToRemoveAdmin)}
            >
              <AiIcons.AiOutlineCheck></AiIcons.AiOutlineCheck>
            </Button>
            <Button
              onClick={NotSureToRemoveAdmin}
              variant="light"
              style={{ width: "2.5vw", alignItems: "center" }}
            >
              <AiIcons.AiOutlineClose></AiIcons.AiOutlineClose>
            </Button>
          </div>
        ),
    },
  ];

  async function RemoveAdmin(id) {
    try {
      let response = await DeleteAdminByIdAsync(id);
      if (response.status == 200) {
        toast.success(`User with id: ${id} successfully deleted!`);
        fetchUsers(page, pageSize, filterText);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.details);
      } else {
        toast.error("Bad interent connection");
      }
    }

    setRowToRemoveAdmin(-1);
  }
  function NotSureToRemoveAdmin() {
    setRowToRemoveAdmin(-1);
  }
  function ConfidenceToRemoveAdmin(id) {
    setRowToRemoveAdmin(id);
  }

  const handlePageChange = (page) => {
    fetchUsers(page, pageSize, filterText);
    setPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    fetchUsers(page, newPerPage, filterText);
    setPage(page);
    setPageSize(newPerPage);
  };

  return (
    <div className="items-list">
      <div className="toolBar">
        <Link
          to="new"
          style={{ display: user.Roles.includes("Admin") ? "block" : "none" }}
        >
          <Button className="action-button" variant="light">
            Create
          </Button>
        </Link>
      </div>
      <DataTable
        title="Admins"
        progressPending={loading}
        paginationServer
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationResetDefaultPage={resetPaginationToggle}
        persistTableHead
        customStyles={dataTableCustomStyles}
        columns={usersTableColums}
        data={filteredUsers}
        pagination
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      ></DataTable>
    </div>
  );
}
