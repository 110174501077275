import { statusTypes } from "../../actions/status/types";

const defaultState = {
  verifiedStatuses: [],
  currencyCodes: [],
  accessDefault: [],
  approvalBrandstatuses: [],
  countryCodes: [],
  pricingReferences: [],
  roles: [],
};

export const StatusReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case statusTypes.GET_ROLES:
      return { ...state, roles: [...payload] };
    case statusTypes.GET_ACCESSDEFAULDBRAND:
      return { ...state, accessDefault: [...payload] };
    case statusTypes.GET_CURRENCYCODESCUSTOMER:
      return { ...state, currencyCodes: [...payload] };
    case statusTypes.GET_VERIFIEDSTATUSESCUSTOMER:
      return {
        ...state,
        verifiedStatuses: [...payload],
      };
    case statusTypes.GET_COUNTYCODESADDRESS:
      return {
        ...state,
        countryCodes: [...payload],
      };
    case statusTypes.GET_APPROVALBRANDSTATUSES:
      return {
        ...state,
        approvalBrandstatuses: [...payload],
      };
    case statusTypes.GET_PRICEREFERENCESTATUSES:
      return {
        ...state,
        pricingReferences: [...payload],
      };
    default:
      return state;
  }
};
