import { setAccessToken } from "../utils/accessToken";
import { store } from "../redux/storage";

/**
 * A function that returns a refresh token.
 *
 * @param {Object} api - The API object used to make requests.
 * @return {Promise} A promise that resolves to the refresh token.
 */
const useRefreshToken = (api, tokenENV) => {
  const state = store.getState();
  const user = state.authReducer.user;
  const refresh = async () => {
    if (user && user.Id) {
      const response = await api.get(`/AdminAuth/Refresh?userId=${user.Id}`, {
        withCredentials: true,
      });
      setAccessToken(tokenENV, response.data.accessToken);
      return response.data.accessToken;
    }
  };
  return refresh;
};

export default useRefreshToken;
