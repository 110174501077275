import React from "react";
import LocationDetails from "../../../../../components/customers/customer-details/location-details";
import { useState } from "react";
import { useEffect } from "react";
import { GetAddressView } from "../../../../../common/services/view-service";
import { useDispatch, useSelector } from "react-redux";
import { GetUpdatePhoneNumberModel } from "../../../../../components/customers/service/HelpService";
import { CreateLocationAsync } from "../../../../../common/locationServices";
import { LocationContainer } from "../container";

export default function LocationCreatePage() {
  const locationState = useSelector((state) => state.locationReducer);
  const statusState = useSelector((state) => state.statusReducer);
  const [form, setForm] = useState({});
  const dispatch = useDispatch();
  const container = LocationContainer(dispatch);

  useEffect(() => {
    let address = locationState.address;
    if (!!address) {
      setForm((prevForm) => ({
        ...prevForm,
        ["address"]: GetAddressView(address),
        ["addressEntity"]: address,
      }));
    }
  }, [locationState]);
  async function Save() {
    let phoneNumber = GetUpdatePhoneNumberModel(
      form.addressEntity.phoneNumber?.id,
      form.addressEntity.countrytel,
      form.addressEntity.phone,
      statusState
    );
    form.id = 0;
    form.addressEntity.id = 0;
    form.addressEntity.phoneNumber = phoneNumber;
    form.address = form.addressEntity;

    try {
      const response = await CreateLocationAsync(form);
      container.ClearLocationState();

      return response;
    } catch (error) {
      return error;
    }
  }
  return (
    <div>
      <LocationDetails
        form={form}
        setForm={setForm}
        Save={Save}
        successfulMessage={"Location created successfully"}
      ></LocationDetails>
    </div>
  );
}
