import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Messages } from "../../../redux/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AdminDetails({
  form,
  setForm,
  Save,
  roles,
  successfulMessage,
  disabledPassword,
  setDisabledPassword,
}) {
  const [beenSubmitted, setBeenSubmitted] = useState(false);
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    const allErrors = {};
    zeroingerrors();
    if (!form.email) {
      allErrors.email = "Please write email!";
    } else {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(form.email) === false) {
        allErrors.email = "Email isn't correct";
      }
    }

    if (!disabledPassword) {
      if (!form.password) {
        allErrors.password = "Please write password!";
      } else {
        if (form.password.length < 8) {
          allErrors.password = "Password must be at least 8 characters";
        } else {
          // Check for at least one uppercase letter
          if (!/[A-Z]/.test(form.password)) {
            allErrors.password =
              "Password must contain at least 1 uppercase letter";
          } else {
            // Check for at least one digit
            if (!/\d/.test(form.password)) {
              allErrors.password = "Password must contain at least 1 digit";
            }
          }
        }
      }
    }

    if (form.roles.length === 0) {
      allErrors.roles = "Please choose at least one role!";
    }
    setError(allErrors);
  };

  useEffect(() => {
    validate();
  }, [form]);

  function GoBack() {
    navigate(-1);
  }
  const zeroingvaliables = () => {
    setForm({ roles: [] });
  };
  const zeroingerrors = () => {
    setError({});
  };

  const handleInput = (event) => {
    const { target } = event;
    const value = target.value;

    if (target.name === "roles") {
      setForm((prevForm) => ({
        ...prevForm,
        [target.name]: typeof value === "string" ? value.split(",") : value,
      }));
      return;
    }

    setForm((prevForm) => ({
      ...prevForm,
      [target.name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setBeenSubmitted(true);
    if (Object.keys(error).length === 0) {
      (async () => {
        try {
          const id = toast.loading("Please wait...", {
            closeButton: true, // Add this option to show a close button
          });
          let response = await Save();
          if (response.status == 200) {
            toast.update(id, {
              render: successfulMessage,
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
            zeroingvaliables();
            setBeenSubmitted(false);
            GoBack();
          }
          if (!!response.response.data) {
            toast.update(id, {
              render: response.response.data.details,
              type: "error",
              isLoading: false,
              autoClose: 5000,
            });
          }
        } catch (error) {
          console.error(error);
        }
      })();
    } else {
      toast.error(Messages.FillFields);
    }
  };

  return (
    <div className="details-container">
      <Form noValidate onSubmit={handleSubmit}>
        <div className="header">
          <h1>Admin Details</h1>
          <Button onClick={GoBack} className="action-button" variant="light">
            Back
          </Button>
        </div>
        <div className="section">
          <h2>General Details</h2>
          <div className="form">
            <div className="margin-styles">
              <TextField
                error={beenSubmitted ? error.email : null}
                helperText={beenSubmitted ? error.email : null}
                style={{ width: "15vw" }}
                label="Email"
                required
                type="text"
                placeholder="user@example.com"
                name="email"
                value={form.email || ""}
                onChange={handleInput}
              />
            </div>
            <div className="margin-styles">
              <FormControl
                style={{ width: "15vw" }}
                error={beenSubmitted ? error.roles : null}
              >
                <InputLabel id="demo-multiple-checkbox-label">Roles</InputLabel>
                <Select
                  name="roles"
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={form.roles}
                  onChange={handleInput}
                  input={<OutlinedInput label="Roles" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {roles.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={form.roles.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {beenSubmitted ? error.roles : ""}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="margin-styles">
              <div className="form-container">
                <TextField
                  disabled={disabledPassword}
                  error={beenSubmitted ? error.password : null}
                  helperText={beenSubmitted ? error.password : null}
                  style={{ width: "15vw" }}
                  required
                  label="Password"
                  name="password"
                  value={form.password || ""}
                  type={showPassword ? "text" : "password"}
                  onChange={handleInput}
                />
                <FormControlLabel
                  disabled={disabledPassword}
                  control={
                    <Checkbox onChange={() => setShowPassword(!showPassword)} />
                  }
                  label="Show Password"
                />
              </div>
            </div>
            <div className="margin-styles">
              <Button
                style={{ display: disabledPassword ? "block" : "none" }}
                variant="dark"
                onClick={() => setDisabledPassword(false)}
              >
                Change Password
              </Button>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2vh" }} className="actions-buttons">
          <Button
            className="action-button"
            style={{
              height: "4vh",
              marginRight: "1vw",
            }}
            type="submit"
            variant="dark"
          >
            Save
          </Button>
          <Button onClick={GoBack} className="action-button cancel-btn">
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}
