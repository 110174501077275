import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./style.css";
export default function AutoComplete({
  inputData,
  label,
  styles,
  name,
  form,
  setForm,
  error,
  disabled = false,
}) {
  return (
    <div>
      <Autocomplete
        disabled={disabled}
        name={name}
        fullWidth={true}
        disablePortal
        options={inputData}
        value={form[name] || ""}
        style={{ height: "100%", ...styles }}
        onChange={(e, v) => {
          setForm((prevForm) => ({
            ...prevForm,
            [name]: v,
          }));
        }}
        renderInput={(params) => (
          <TextField
            style={{ fontSize: "20px" }}
            error={error}
            helperText={error}
            {...params}
            label={label}
          />
        )}
      />
    </div>
  );
}
