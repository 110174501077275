import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function ItemDetails({ form, setForm, Save }) {
  const navigate = useNavigate();

  function GoBack() {
    navigate(-1);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Save();
  };

  return (
    <div className="details-container">
      <Form noValidate onSubmit={handleSubmit}>
        <div className="header">
          <h1>Item Detail</h1>
          <Button onClick={GoBack} className="action-button" variant="light">
            Back
          </Button>
        </div>
        <div className="section">
          <h2>General Details</h2>
          <div className="form">
            <div className="margin-styles">
              <div className="form-container">
                <TextField
                  label="Name"
                  type="text"
                  name="sku"
                  value={form.sku || ""}
                />
                <TextField
                  label={"Units Per SKU"}
                  name="unitsPerSKU"
                  value={form.unitsPerSKU || ""}
                  type="text"
                />
              </div>
            </div>
            <div className="margin-styles">
              <div className="form-container">
                <TextField
                  style={{ width: "23vw" }}
                  label={"Status"}
                  name="status"
                  value={form.status || ""}
                  type="text"
                />
              </div>
            </div>
            <div className="margin-styles">
              <div className="form-container">
                <TextField
                  label={"Mfr Part Number"}
                  name="mfrPartNumber"
                  value={form.mfrPartNumber || ""}
                  type="text"
                />
                <TextField
                  label={"Title"}
                  name="title"
                  value={form.title || ""}
                  type="text"
                />
              </div>
            </div>
            <div className="margin-styles">
              <div className="form-container">
                <FormControlLabel
                  label="Regular Stock"
                  name="regularStock"
                  control={<Checkbox checked={form.regularStock} />}
                />
                <FormControlLabel
                  label="AirFreightProhibited"
                  name="airFreightProhibited"
                  control={<Checkbox checked={form.airFreightProhibited} />}
                />
                <FormControlLabel
                  label="LTLFreighRequired"
                  name="ltlFreighRequired"
                  control={<Checkbox checked={form.ltlFreighRequired} />}
                />
                <FormControlLabel
                  label="CARB Approved"
                  name="carbApproved"
                  control={<Checkbox checked={form.carbApproved} />}
                />
                <FormControlLabel
                  label="CARBAcknowlegementRequired"
                  name="carbAcknowlegementRequired"
                  control={
                    <Checkbox checked={form.carbAcknowlegementRequired} />
                  }
                />
              </div>
            </div>
            <div className="margin-styles">
              <div className="form-container">
                <TextField
                  label={"CARBEONumber"}
                  name="carbeoNumber"
                  value={form.carbeoNumber || ""}
                  type="text"
                />
                <TextField
                  label={"Prop65"}
                  name="prop65"
                  value={form.prop65 || ""}
                  type="text"
                />
              </div>
            </div>
            <div className="margin-styles">
              <div className="form-container">
                <TextField
                  label={"EPA"}
                  name="epa"
                  value={form.epa || ""}
                  type="text"
                />
                <TextField
                  label={"Thumbnail"}
                  name="thumbnail"
                  value={form.thumbnail || ""}
                  type="text"
                />
              </div>
            </div>
            <div className="margin-styles">
              <div className="form-container">
                <TextField
                  label={"MSPR"}
                  name="mspr"
                  value={form.mspr || ""}
                  type="text"
                />
                <TextField
                  label={"MAP"}
                  name="map"
                  value={form.map || ""}
                  type="text"
                />
              </div>
            </div>
            <div className="margin-styles">
              <div className="form-container">
                <TextField
                  label={"Insured Cost"}
                  name="insuredCost"
                  value={form.insuredCost || ""}
                  type="text"
                />
                <TextField
                  label={"TariffNo"}
                  name="tariffNo"
                  value={form.tariffNo || ""}
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Brand</h2>
          <div className="form">
            <div className="margin-styles">
              <TextField
                style={{ width: "23vw" }}
                label="Name"
                type="text"
                name="brandName"
                value={form.brandName || ""}
              />
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Category</h2>
          <div className="form">
            <div className="margin-styles">
              <div className="form-container">
                <TextField
                  label="Category Name"
                  type="text"
                  name="categoryName"
                  value={form.categoryName || ""}
                />
                <TextField
                  label="AAIACategoryId"
                  type="text"
                  name="aaiaCategoryId"
                  value={form.aaiaCategoryId || ""}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <h2>Sub category</h2>
          <div className="form">
            <div className="margin-styles">
              <div className="form-container">
                <TextField
                  label="Name"
                  type="text"
                  name="subCategoryName"
                  value={form.subCategoryName || ""}
                />
                <TextField
                  label="AAIASubCategoryId"
                  type="text"
                  name="aaiaSubCategoryId"
                  value={form.aaiaSubCategoryId || ""}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
      <div style={{ marginTop: "20px" }}></div>
    </div>
  );
}
