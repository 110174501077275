import React from "react";
import { Button, Form } from "react-bootstrap";
import * as FiIcons from "react-icons/fi";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { TextField } from "@mui/material";
import AutoComplete from "../../../../ui/AutoComplete";
import PhoneTextField from "../../../../ui/PhoneComponent";
import {
  GetCountriesAsync,
  GetProvincesOfCountryAsync,
} from "../../../../common/countryServices";
import CreatableAutocomplete from "../../../../ui/CreatableAutocomplete";
import { toast } from "react-toastify";
import { Messages } from "../../../../redux/constants";

export default function AddressDetails({ form, setForm, saveForm }) {
  const [beenSubmitted, setBeenSubmitted] = useState(false);
  const [error, setError] = useState({});
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const navigate = useNavigate();

  async function getCountries() {
    let response = await GetCountriesAsync();
    return response.data;
  }

  async function getProvincesOfCountry(id) {
    let response = await GetProvincesOfCountryAsync(id);
    return response.data;
  }

  useEffect(() => {
    (async () => {
      try {
        if (countries.length === 0) {
          let countries = await getCountries();
          setCountries(countries);
          if (!!form.country) {
            let country = countries.find((item) => item.name === form.country);
            let response = await getProvincesOfCountry(country.id);
            let provinces = response.map((item) => item.name);
            setProvinces(provinces);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let country = countries.find((item) => item.name === form.country);
        let response = await getProvincesOfCountry(country.id);
        let provinces = response.map((item) => item.name);
        setProvinces(provinces);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [form.country]);

  function GoBack() {
    navigate(-1);
  }
  useEffect(() => {
    validate();
  }, [form]);

  const zeroingvaliables = () => {
    setForm({});
  };
  const zeroingerrors = () => {
    setError({});
  };

  const validate = () => {
    const allErrors = {};
    zeroingerrors();

    if (!form.phone) {
      allErrors.phone = "Please write a phonenumber!";
    } else {
      if (form.phone.length < 9)
        allErrors.phone =
          "Incorrect phone number! (must be at least 9 characters)";
    }
    if (!form.countrytel) {
      allErrors.phone = "Please choose a phone country code!";
    }
    if (!form.zip) {
      allErrors.zip = "Zip can't be empty!";
    } else {
      if (!(form.zip.length === 5)) {
        allErrors.zip = "Zip require 5 characters";
      }
    }
    if (!form.recipient) {
      allErrors.recipient = "Recipient can't be empty!";
    }
    if (!form.addressLine1) {
      allErrors.addressLine1 = "Address Line 1 can't be empty!";
    }
    if (!form.street) {
      allErrors.street = "Street can't be empty!";
    }
    if (!form.city) {
      allErrors.city = "City can't be empty!";
    }
    if (!form.country) {
      allErrors.country = "Country can't be empty!";
    }
    if (!form.province) {
      allErrors.province = "State/Province can't be empty!";
    }
    setError(allErrors);
  };

  const handleInput = (event) => {
    const { target } = event;
    const value = target.value;
    if (target.name == "zip") {
      const onlyNums = value.replace(/[^0-9]/g, "");

      if (onlyNums.length <= 5) {
        setForm((prevForm) => ({
          ...prevForm,
          [target.name]: onlyNums,
        }));
      }
      return;
    }

    if (target.name == "phone") {
      const onlyNums = value.replace(/[^0-9]/g, "");

      if (onlyNums.length <= 10) {
        setForm((prevForm) => ({
          ...prevForm,
          [target.name]: onlyNums,
        }));
      }
      return;
    }

    setForm((prevForm) => ({
      ...prevForm,
      [target.name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setBeenSubmitted(true);
    if (Object.keys(error).length === 0) {
      saveForm();
      zeroingvaliables();
      setBeenSubmitted(false);
      GoBack();
    } else {
      toast.error(Messages.FillFields);
    }
  };

  function onCreateProvince(e) {
    if (e.length <= 2) {
      toast.error("Provinces name can't be less that 2 characters!");
      return;
    }
    setProvinces([...provinces, e]);
  }

  return (
    <div>
      <div className="header">
        <h3>Address Details</h3>
        <div onClick={GoBack} className="button-content-address-book">
          <FiIcons.FiPlus className="close-btn" />
        </div>
      </div>
      <div className="body">
        <Form noValidate onSubmit={handleSubmit}>
          <div className="margin-styles">
            <TextField
              style={{ width: "31vw" }}
              label={"Recipient"}
              error={beenSubmitted ? error.recipient : null}
              helperText={beenSubmitted ? error.recipient : null}
              placeholder="Sally Sunshine"
              required
              name="recipient"
              value={form.recipient || ""}
              type="text"
              onChange={handleInput}
            />
          </div>
          <div className="margin-styles">
            <TextField
              style={{ width: "31vw" }}
              error={beenSubmitted ? error.addressLine1 : null}
              helperText={beenSubmitted ? error.addressLine1 : null}
              label={"Address Line 1"}
              placeholder="1500 NE Miami Pl"
              type="text"
              required
              name="addressLine1"
              value={form.addressLine1 || ""}
              onChange={handleInput}
            />
          </div>
          <div className="margin-styles">
            <TextField
              style={{ width: "31vw" }}
              label={"Address Line 2"}
              placeholder="Apt 202"
              type="text"
              name="addressLine2"
              value={form.addressLine2 || ""}
              onChange={handleInput}
            />
          </div>
          <div className="margin-styles">
            <PhoneTextField
              autocompleteStyles={{ width: "8vw" }}
              textFieldStyles={{ width: "21vw" }}
              form={form}
              setForm={setForm}
              handleInput={handleInput}
              error={beenSubmitted ? error.phone : null}
            ></PhoneTextField>
          </div>
          <div className="margin-styles">
            <TextField
              style={{ width: "31vw" }}
              label={"Street"}
              error={beenSubmitted ? error.street : null}
              helperText={beenSubmitted ? error.street : null}
              placeholder="Avenue"
              type="text"
              required
              name="street"
              value={form.street || ""}
              onChange={handleInput}
            />
          </div>
          <div className="form-container">
            <div>
              <TextField
                className="text-field-styles"
                label={"City"}
                error={beenSubmitted ? error.city : null}
                helperText={beenSubmitted ? error.city : null}
                placeholder="Miami"
                type="text"
                required
                name="city"
                value={form.city || ""}
                onChange={handleInput}
              />
            </div>
            <div>
              <TextField
                className="text-field-styles"
                label={"Zip"}
                error={beenSubmitted ? error.zip : null}
                helperText={beenSubmitted ? error.zip : null}
                required
                placeholder="33132"
                type="text"
                name="zip"
                value={form.zip || ""}
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="form-container">
            <div className="text-field-styles">
              <AutoComplete
                className="text-field-styles"
                error={beenSubmitted ? error.country : null}
                name={"country"}
                form={form}
                setForm={setForm}
                inputData={countries.map((item) => item.name)}
                label={"Country"}
              />
            </div>
            <div className="text-field-styles">
              <CreatableAutocomplete
                className="text-field-styles"
                options={provinces}
                onCreate={onCreateProvince}
                label={"State/Province"}
                error={beenSubmitted ? error.province : null}
                name={"province"}
                form={form}
                setForm={setForm}
                disabled={!!!form.country}
              ></CreatableAutocomplete>
            </div>
          </div>
          <div className="btn-container">
            <Button className="action-button" variant="light" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
