import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import * as IoIcons from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { AuthContainer } from "./container";

export default function Sidebar({ isExpanded, setExpendState }) {
  const user = useSelector((state) => state.authReducer.user);
  const dispatch = useDispatch();
  const authContainer = AuthContainer(dispatch);
  const navigate = useNavigate();

  function Logout() {
    authContainer.logout();
    navigate("/login");
  }

  return (
    <div
      className={
        isExpanded
          ? "side-nav-container"
          : "side-nav-container side-nav-container-NX"
      }
    >
      <div className="nav-upper">
        <div className="nav-heading">
          {isExpanded && (
            <div className="nav-brand">
              <img src="icons/Logo.svg" alt="" srcset="" />
              <h2>DMM Motorsports Administration</h2>
            </div>
          )}
          <button
            style={{ marginTop: 22, marginLeft: 25 }}
            className={
              isExpanded ? "hamburger hamburger-in" : "hamburger hamburger-out"
            }
            onClick={() => setExpendState(!isExpanded)}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div className="nav-menu">
          {SidebarData.map((item) => (
            <Link
              className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}
              to={item.path}
            >
              {item.icon}
              {isExpanded && <p>{item.title}</p>}
            </Link>
          ))}
        </div>
      </div>
      <div className="nav-footer">
        {isExpanded && (
          <div className="nav-details">
            <div className="nav-footer-info">
              <p className="nav-footer-user-name">{user.Email}</p>
              <p className="nav-footer-user-role">{user.Roles}</p>
            </div>
          </div>
        )}
        <IoIcons.IoIosLogOut onClick={Logout} className="logout-icon" />
      </div>
    </div>
  );
}
