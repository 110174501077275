import {
  AddAddressToTempCustomer,
  ClearTempCustomerState,
  ClearTempWarehouseToTempCustomer,
  ClearWarehouseAddressToTempCustomer,
  CreateWarehouseToTempCustomer,
  FillTempCustomer,
  RemoveAddressFromCustomer,
  RemoveAssosiateBrandFromCustomer,
  RemoveBrandAccessFromCustomer,
  RemoveWarehouseFromCustomer,
  SaveTempWarehouseDataToCustomer,
  SelectAssociateBrandsToTempCustomer,
  SelectBillingAddress,
  SelectBrandsAccessesToTempCustomer,
  SelectOwnedBrandsToTempCustomer,
  SelectShippingAddress,
  SelectWarehouseLocation,
  UpdateAddressToTempCustomer,
  UpdateBrandAccessToTempCustomer,
  UpdateOwnedBrandToTempCustomer,
  UpdateWarehouseToTempCustomer,
} from "../../../redux/actions/customers/customer-temp/types";

export const CustomerTempContainer = (dispatch) => ({
  ClearTempCustomer() {
    dispatch(ClearTempCustomerState());
  },
  SaveTempWarehouseData(data) {
    dispatch(SaveTempWarehouseDataToCustomer(data));
  },
  RemoveAddress(id) {
    dispatch(RemoveAddressFromCustomer(id));
  },
  RemoveBrandAccess(id) {
    dispatch(RemoveBrandAccessFromCustomer(id));
  },
  RemoveAssosiateBrand(id) {
    dispatch(RemoveAssosiateBrandFromCustomer(id));
  },
  RemoveWarehouse(id) {
    dispatch(RemoveWarehouseFromCustomer(id));
  },
  UpdateBrandAccess(data) {
    dispatch(UpdateBrandAccessToTempCustomer(data));
  },
  UpdateOwnedBrand(data) {
    dispatch(UpdateOwnedBrandToTempCustomer(data));
  },
  ClearTempWarehouse() {
    dispatch(ClearTempWarehouseToTempCustomer());
  },
  ClearWarehouseLocation() {
    dispatch(ClearWarehouseAddressToTempCustomer());
  },
  UpdateWarehouse(data) {
    dispatch(UpdateWarehouseToTempCustomer(data));
  },
  CreateWarehouse(data) {
    dispatch(CreateWarehouseToTempCustomer(data));
  },
  AddAddress(data) {
    dispatch(AddAddressToTempCustomer(data));
  },
  UpdateAddress(data) {
    dispatch(UpdateAddressToTempCustomer(data));
  },
  SelectBillingAddress(data) {
    dispatch(SelectBillingAddress(data));
  },
  SelectWarehouseLocation(data) {
    dispatch(SelectWarehouseLocation(data));
  },
  SelectShippingAddress(data) {
    dispatch(SelectShippingAddress(data));
  },
  SelectBrandsAccesses(data) {
    dispatch(SelectBrandsAccessesToTempCustomer(data));
  },
  SelectAssociateBrands(data) {
    dispatch(SelectAssociateBrandsToTempCustomer(data));
  },
  SelectOwnedBrands(data) {
    dispatch(SelectOwnedBrandsToTempCustomer(data));
  },
  FillCustomer(data) {
    dispatch(FillTempCustomer(data));
  },
});
