import { all, spawn, call } from "redux-saga/effects";
import authWatcher from "./auth";
import statusWatcher from "./status";

export function* rootSaga() {
  const sagas = [authWatcher, statusWatcher];

  const retrySagas = sagas.map((saga) => {
    return spawn(function* () {
      while (true) {
        try {
          yield call(saga);
          break;
        } catch (e) {
          console.error(e);
        }
      }
    });
  });

  yield all(retrySagas);
}
