import { api } from "../../../api/axios";

const controller = "Customer";

export async function GetCustomersAsync() {
  return await api.get(`${controller}/GetCustomers`);
}

export async function GetCustomerByIdAsync(id) {
  return await api.get(`${controller}/GetCustomer/${id}`);
}

export async function DeleteCustomerAsync(Id) {
  return await api.delete(`${controller}/DeleteCustomer/${Id}`);
}

export async function UpdateCustomerAsync(customer) {
  return await api.put(`/${controller}/UpdateCustomer/`, customer);
}

export async function CreateCustomerAsync(customer) {
  return await api.post(`/${controller}/CreateCustomer/`, customer);
}

export async function GetCustomersPagedAsync(
  PageIndex,
  PageSize = 20,
  Name = "",
  BCNo,
  SortDescending = false
) {
  return await api.get(
    `${controller}/GetCustomersPaginated?PageSize=${PageSize}
    &PageIndex=${PageIndex}&SortDescending=${SortDescending}&Name=${Name}&BCNo=${BCNo}`
  );
}

export async function SyncCustomersAsync() {
  return await api.post(`/${controller}/SynchronizeCustomersShopifyCatalogs`);
}

export async function SyncCustomerAsync(id) {
  return await api.post(
    `/${controller}/SynchronizeCustomerShopifyCatalogs/${id}`
  );
}

export async function CreateOrUpdateCustomerInShopifyAccountAsync(id) {
  return await api.post(`/${controller}/PushCustomerToShopify/${id}`);
}
