import React, { useEffect } from "react";
import SKUDetails from "../../../components/price-groups/sku.details";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreatedPrefix } from "../../../redux/constants";
import { SKUTempContainer } from "../../../components/price-groups/sku.details/container";
import { useParams } from "react-router-dom";
import { GetItemsIdsandSKUsByBrandIdAsync } from "../../../components/items/services";
import Loader from "../../../ui/loader";

export default function SKUCreatePage() {
  const { brandId } = useParams();
  const dispatch = useDispatch();
  const container = SKUTempContainer(dispatch);
  const skus = useSelector((state) => state.skuTemReducer.skus);
  const id = !!skus.length ? skus.length : 0;
  const initForm = { id: `${id}${CreatedPrefix}`, discountType: "percent" };
  const [form, setForm] = useState(initForm);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  function Save() {
    let item = items.find((item) => item.sku == form.sku);
    const sku = {
      id: form.id,
      itemId: item.id,
      sku: form.sku,
      reference: form.reference,
      quantity: form.quantity,
    };

    if (form.discountType == "percent") {
      sku.percent = form.amount;
    } else {
      sku.amount = form.amount;
    }

    container.AddSKU(sku);
  }

  async function GetItemsByBrandId(id) {
    return await GetItemsIdsandSKUsByBrandIdAsync(id);
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await GetItemsByBrandId(brandId);
        setItems(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <SKUDetails
          Items={items}
          form={form}
          setForm={setForm}
          Save={Save}
        ></SKUDetails>
      )}
    </div>
  );
}
