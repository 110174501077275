import React, { useState } from "react";
import AdminDetails from "../../../components/admin/admin-details";
import { useSelector } from "react-redux";
import { CreateAdminUserAsync } from "../../../components/admin/services";
import Loader from "../../../ui/loader";

export default function AdminCreatePage() {
  const roles = useSelector((state) => state.statusReducer.roles);
  const [form, setForm] = useState({ roles: [] });
  const [loading, setLoading] = useState(false);
  const [disabledPassword, setDisabledPassword] = useState(false);
  async function Save() {
    try {
      let response = await CreateAdminUserAsync(form);
      return response;
    } catch (error) {
      return error;
    }
  }
  return (
    <div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <AdminDetails
          form={form}
          setForm={setForm}
          Save={Save}
          roles={roles}
          successfulMessage={`User ${form.email} created successfully!`}
          disabledPassword={disabledPassword}
          setDisabledPassword={setDisabledPassword}
        ></AdminDetails>
      )}
    </div>
  );
}
