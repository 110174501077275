import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import * as FiIcons from "react-icons/fi";
import DataTable from "react-data-table-component";
import { dataTableCustomStyles } from "../../../../common/datatable/styles";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { GetDefaultPricingLevelView } from "../../../../common/services/view-service";
import FilterComponent from "../../../../ui/filter";

export default function BrandsList({
  brandsView,
  rowSelectCritera,
  save,
  preSelectedRows,
}) {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState(brandsView);
  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  useEffect(() => {
    setFilteredBrands(
      brandsView.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
    );
  }, [filterText]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  function GoBack() {
    navigate(-1);
  }

  const brandsTableColums = useMemo(
    () => [
      {
        name: "Name",
        selector: (row) => row.name,
      },
      {
        name: "DropshipAvailable",
        selector: (row) => row.dropshipAvailable + "",
      },
      {
        name: "AccessDefault",
        selector: (row) => row.accessDefault,
      },
      {
        name: "Owner",
        selector: (row) => (!!row.owner ? row.owner.name : "null"),
      },
      {
        name: "DefaultPricingLevel",
        selector: (row) =>
          !!row.defaultPricingLevel
            ? GetDefaultPricingLevelView(row.defaultPricingLevel)
            : "null",
        width: "30%",
      },
    ],
    []
  );

  function Select() {
    let filteredPreselectedRowsIds = preSelectedRows
      .filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
      .map((item) => item.id);

    const result = GetSelectedRows(selectedRows, preSelectedRows);

    let filteredPreSelectedRows = preSelectedRows.filter(
      (item) => !filteredPreselectedRowsIds.includes(item.id)
    );
    filteredPreSelectedRows.push(...result.preSelectedRows);
    save(result.selectedRows, filteredPreSelectedRows);
    GoBack();
  }

  function GetSelectedRows(selectedRows, preSelectedRows) {
    let selectedIds = selectedRows.map((item) => item.id); // brand Ids
    let preSelectedBrandIds = preSelectedRows.map((item) => item.brandId); // brand Ids
    let resultSelectedRows = [];
    let resultPreSelectedRows = [];
    selectedIds.forEach((brandId) => {
      if (preSelectedBrandIds.includes(brandId)) {
        resultPreSelectedRows.push(
          preSelectedRows.find((item) => item.brandId === brandId)
        );
      } else {
        resultSelectedRows.push(
          selectedRows.find((item) => item.id === brandId)
        );
      }
    });
    return {
      selectedRows: resultSelectedRows,
      preSelectedRows: resultPreSelectedRows,
    };
  }

  return (
    <div>
      <div className="header">
        <h3>Brands</h3>
        <div onClick={GoBack} className="button-content-add-brand">
          <FiIcons.FiPlus className="close-btn" />
        </div>
      </div>
      <div className="body">
        <DataTable
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
          selectableRows
          customStyles={dataTableCustomStyles}
          data={filteredBrands}
          columns={brandsTableColums}
          onSelectedRowsChange={handleChange}
          selectableRowSelected={rowSelectCritera}
          pagination
        ></DataTable>
        <div className="btn-container">
          <Button className="action-button" onClick={Select} variant="light">
            Select
          </Button>
        </div>
      </div>
    </div>
  );
}