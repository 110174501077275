import React, { useEffect } from "react";
import PriceGroupsList from "../../../components/price-groups/price-groups.list";
import { useDispatch } from "react-redux";
import { SKUTempContainer } from "../../../components/price-groups/sku.details/container";
import { useLocation } from "react-router-dom";

export default function PriceGroupsPage() {
  const dispatch = useDispatch();
  const container = SKUTempContainer(dispatch);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname == "/price-groups") {
      container.ClearSKU();
    }
  }, [location]);
  return (
    <div>
      <PriceGroupsList></PriceGroupsList>
    </div>
  );
}
