import { useEffect, useMemo, useState } from "react";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { dataTableCustomStyles } from "../../../common/datatable/styles";
import routes from "../../../routes";
import { GetPhoneNumberView } from "../../../common/services/view-service";
import FilterComponent from "../../../ui/filter";
import * as AiIcons from "react-icons/ai";
import "./style.css";
import {
  DeleteCustomerAsync,
  GetCustomersPagedAsync,
  SyncCustomerAsync,
  SyncCustomersAsync,
} from "../service/CustomerService";
import { toast } from "react-toastify";

export default function CustomerList() {
  const [customersView, setCustomersView] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [rowToRemoveCustomer, setRowToRemoveCustomer] = useState(-1);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [filterNameText, setFilterNameText] = useState("");
  const [filterBCNoText, setFilterBCNoText] = useState("");
  const filteredCustomers = getFilteredItems();
  function getFilteredItems() {
    return customersView.filter((item) => {
      const lowerCaseName = item.name?.toLowerCase() || "";
      const lowerCaseBCNo = item.bcNo?.toLowerCase() || "";

      return (
        lowerCaseName.includes(filterNameText.toLowerCase()) &&
        lowerCaseBCNo.includes(filterBCNoText.toLowerCase())
      );
    });
  }

  useEffect(() => {
    // Clear any existing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTimeout = setTimeout(() => {
      let nameFilter = filterNameText !== "" ? filterNameText : "";
      let bcNoFilter = filterBCNoText !== "" ? filterBCNoText : "";

      fetchCustomers(page, pageSize, nameFilter, bcNoFilter);
    }, 1000);

    setTypingTimeout(newTimeout);
  }, [filterNameText, filterBCNoText]);

  const getCustomersPaged = async (page, pageSize, name = "", bcno = "") => {
    let response = await GetCustomersPagedAsync(page, pageSize, name, bcno);
    return response.data;
  };

  const fetchCustomers = async (page, pageSize, name = "", bcno = "") => {
    try {
      setLoading(true);
      const response = await getCustomersPaged(page, pageSize, name, bcno);
      setCustomersView([...response.items]);
      setTotalRows(response.meta.totalCount);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleNameClear = () => {
      if (filterNameText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterNameText("");
      }
    };
    const handleBCNoClear = () => {
      if (filterBCNoText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterBCNoText("");
      }
    };

    return (
      <div className="filter-header">
        <FilterComponent
          onFilter={(e) => setFilterNameText(e.target.value)}
          onClear={handleNameClear}
          filterText={filterNameText}
        />
        <FilterComponent
          placeholder="filter by BCNo"
          onFilter={(e) => setFilterBCNoText(e.target.value)}
          onClear={handleBCNoClear}
          filterText={filterBCNoText}
        />
      </div>
    );
  }, [filterNameText, filterBCNoText, resetPaginationToggle]);

  const customersTableColums = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "BcNo",
      selector: (row) => row.bcNo,
      sortable: true,
    },
    {
      name: "Verified Status",
      selector: (row) => row.verifiedStatus,
      sortable: true,
    },
    {
      name: "TaxExempt",
      selector: (row) => {
        return row.taxExempt + "";
      },
      sortable: true,
    },
    {
      name: "CurrencyCode",
      selector: (row) => row.currencyCode,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => GetPhoneNumberView(row.phoneNumber),
      sortable: true,
    },
    {
      name: "Can Use Net Terms",
      selector: (row) => row.useNetTerms + "",
      sortable: true,
    },
    {
      name: "CreatedAt",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY HH:mm:ss"),
      sortable: true,
    },
    {
      name: "UpdatedAt",
      selector: (row) =>
        !!row.updatedAt
          ? moment(row.updatedAt).format("DD-MM-YYYY HH:mm:ss")
          : "null",
      sortable: true,
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <div className="actions">
          <Link to={`/customers/${row.id}/edit`}>
            <Button
              style={{
                width: "4vw",
              }}
              variant="light"
            >
              Edit
            </Button>
          </Link>
        </div>
      ),
    },
    {
      name: "Remove",
      button: true,
      cell: (row) =>
        rowToRemoveCustomer !== row.id ? (
          <Button
            onClick={() => ConfidenceToRemoveCustomer(row.id)}
            style={{ width: "5vw" }}
            variant="light"
          >
            Remove
          </Button>
        ) : (
          <div className="delete-container">
            <Button
              variant="light"
              style={{ width: "2vw", marginRight: "3px" }}
              onClick={() => RemoveCustomer(rowToRemoveCustomer)}
            >
              <AiIcons.AiOutlineCheck></AiIcons.AiOutlineCheck>
            </Button>
            <Button
              onClick={NotSureToRemoveCustomer}
              variant="light"
              style={{ width: "2.5vw", alignItems: "center" }}
            >
              <AiIcons.AiOutlineClose></AiIcons.AiOutlineClose>
            </Button>
          </div>
        ),
    },
    {
      name: "SyncShopifyCatalog",
      button: true,
      cell: (row) => (
        <Button
          style={{
            width: "4vw",
          }}
          variant="light"
          onClick={async () => await SyncCustomer(row.id)}
        >
          Sync
        </Button>
      ),
    },
  ];

  async function SyncCustomer(id) {
    const idToast = toast.loading("Please wait...", {
      closeButton: true,
    });

    try {
      let response = await SyncCustomerAsync(id);
      if (response.status == 200) {
        toast.update(idToast, {
          render: response.data.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      }
    } catch (error) {
      var message = error.response.data.message;
      toast.update(idToast, {
        render: message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }

  function ExportCustomerUpdateReport(reportData) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(reportData);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `CustomerSyncReport.xlsx`);
  }
  async function SyncCustomers() {
    let response = await SyncCustomersAsync();
    if (response.status == 200) {
      toast.success(`Customers successfully synced!`);
      ExportCustomerUpdateReport(response.data);
    }
  }

  async function RemoveCustomer(id) {
    try {
      let response = await DeleteCustomerAsync(id);

      if (response.status == 200) {
        toast.success(`Customer with id: ${id} successfully deleted!`);

        fetchCustomers(page, pageSize, filterNameText);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.details);
      } else {
        toast.error("Bad interent connection");
      }
    }

    fetchCustomers(page, pageSize, filterNameText, filterBCNoText);
    setRowToRemoveCustomer(-1);
  }
  function NotSureToRemoveCustomer() {
    setRowToRemoveCustomer(-1);
  }
  function ConfidenceToRemoveCustomer(id) {
    setRowToRemoveCustomer(id);
  }

  const handlePageChange = (page) => {
    fetchCustomers(page, pageSize, filterNameText, filterBCNoText);
    setPage(page);
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    fetchCustomers(page, newPerPage, filterNameText, filterBCNoText);
    setPage(page);
    setPageSize(newPerPage);
  };

  return (
    <div className="items-list">
      <div className="toolBar">
        <Button
          className="action-button"
          variant="light"
          onClick={SyncCustomers}
        >
          SyncCustomers
        </Button>
        <Link to={`/${routes.customerNew}`}>
          <Button className="action-button" variant="light">
            Create
          </Button>
        </Link>
      </div>

      <DataTable
        paginationServer
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationResetDefaultPage={resetPaginationToggle}
        persistTableHead
        customStyles={dataTableCustomStyles}
        title="Customers"
        columns={customersTableColums}
        data={filteredCustomers}
        pagination
        progressPending={loading}
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      ></DataTable>
    </div>
  );
}
