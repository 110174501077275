import _isEmpty from "lodash/isEmpty";
import { getCookie, setCookie } from "./cookie";
import { TOKEN } from "../redux/constants";

// 1 day in seconds
const STORE_AUTH_TOKEN_FOR = 86400;

// It's important to first check in cookie, then in session storage, not vice versa.
export const getAccessToken = () => {
  let accessToken = getCookie(TOKEN);

  if (_isEmpty(accessToken)) {
    accessToken = sessionStorage.getItem(TOKEN);
  }

  return accessToken;
};

export const setAccessToken = (token, temporary = false) => {
  if (temporary) {
    sessionStorage.setItem(TOKEN, token);
    return;
  }

  setCookie(TOKEN, token, STORE_AUTH_TOKEN_FOR);
};

export const removeAccessToken = () => {
  setCookie(TOKEN, "", 0);
  sessionStorage.removeItem(TOKEN);
};
