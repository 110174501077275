import React, { useEffect, useState } from "react";
import BrandAccessDetails from "../../../../components/customers/customer-details/brandaccessdetails";
import { useDispatch, useSelector } from "react-redux";
import { BrandTempContainer } from "../../../../components/brands/container";
import Loader from "../../../../ui/loader";
import { GetPricingGroupsOfBrand } from "../../../../components/brands/service/BrandService";
import { useNavigate } from "react-router-dom";
import { CreatedPrefix } from "../../../../redux/constants";
export default function AddBrandAccessPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [priceGroups, setPriceGroups] = useState([]);
  const dispatch = useDispatch();
  const container = BrandTempContainer(dispatch);
  const brandTempReducer = useSelector((state) => state.brandTempReducer);
  const [form, setForm] = useState({
    id: `${0}${CreatedPrefix}`,
    name: "",
    status: "",
    pricingLevel: "",
  });

  /**
   * Modify the initial form by extracting the Price Group Name from the pricing level
   * and returning the modified form.
   *
   * @param {object} initForm - The initial form to be modified.
   * @return {object} The modified form with the extracted Price Group Name.
   */
  function ModifyInitForm(initForm) {
    try {
      let priceGroupName = initForm?.pricingLevel?.name;
      if (initForm?.pricingLevel?.status == "Default")
        priceGroupName += " (Default)";
      return {
        name: initForm?.name,
        status: initForm?.status,
        pricingLevel: priceGroupName,
      };
    } catch (error) {
      console.log(error);
      return {
        name: "",
        status: "",
        pricingLevel: "",
      };
    }
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let brandId = brandTempReducer?.brand.id ?? 0;
        let response = await GetPricingGroupsOfBrand(brandId);
        setPriceGroups(response.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);
  function GoBack() {
    navigate(-1);
  }
  function Save() {
    const brandToSave = { ...form };
    brandToSave.pricingLevel = priceGroups.find((el) => {
      if (form.pricingLevel?.includes("Default")) {
        return el.status == "Default";
      }
      return el.name == form.pricingLevel;
    });

    container.AddBrandAccesses(brandToSave);
    // GoBack();
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <BrandAccessDetails
          form={form}
          setForm={setForm}
          Save={Save}
          priceGroups={priceGroups}
        />
      )}
    </div>
  );
}
