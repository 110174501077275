import React from "react";
import ItemsList from "../../../components/items/item.list";

export default function ItemsPage() {
  return (
    <div>
      <ItemsList></ItemsList>
    </div>
  );
}
