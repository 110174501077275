import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import { BrandTempContainer } from "../../container";
import * as FiIcons from "react-icons/fi";
import DataTable from "react-data-table-component";
import { dataTableCustomStyles } from "../../../../common/datatable/styles";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import FilterComponent from "../../../../ui/filter";
import { useDispatch, useSelector } from "react-redux";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
export default function CustomersList({
  customersView,
  ownerId,
  setOwnerId,
  save
}) {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredCustomers, setFilteredBrands] = useState(customersView);
  const [disabledRadios, setDisabledRadios] = useState({});
  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  useEffect(() => {
    setFilteredBrands(
      customersView.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
    );
  }, [filterText, customersView]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  function GoBack() {
    navigate(-1);
  }

  const ownersTableColums = useMemo(
    () => [
      {
        name: "Name",
        selector: (row) => row.name,
      },
      {
        name: "Email",
        selector: (row) => row.email + "",
      },
      {
        name: "Set as owner",
        button: true,
        cell: (row) => (
          <div className="actions">
            <RadioGroup
              aria-label="defaultPricingGroup"
              name="dropshipAvailable"
              value={IsOwnerValue(row)}
            >
              <FormControlLabel
                onClick={() => {
                  handleOwner(row);
                }}
                value="true"
                control={<Radio />}
              />
            </RadioGroup>
          </div>
        ),
      },
    ],
    [ownerId, disabledRadios]
  );

  function IsOwnerValue(row) {
    return ownerId == row.id;
  }

  function handleOwner(row) {
    const newOwnerId = IsOwnerValue(row) ? 0 : row.id;
    setOwnerId(newOwnerId);
  }


  function GetSelectedRows(selectedRows, preSelectedRows) {
    let selectedIds = selectedRows.map((item) => item.id); // brand Ids
    let preSelectedBrandIds = preSelectedRows.map((item) => item.brandId); // brand Ids
    let resultSelectedRows = [];
    let resultPreSelectedRows = [];
    selectedIds.forEach((brandId) => {
      if (preSelectedBrandIds.includes(brandId)) {
        resultPreSelectedRows.push(
          preSelectedRows.find((item) => item.brandId === brandId)
        );
      } else {
        resultSelectedRows.push(
          selectedRows.find((item) => item.id === brandId)
        );
      }
    });
    return {
      selectedRows: resultSelectedRows,
      preSelectedRows: resultPreSelectedRows,
    };
  }

  return (
    <div>
      <div className="header">
        <h3>Brands</h3>
        <div onClick={GoBack} className="button-content-add-brand">
          <FiIcons.FiPlus className="close-btn" />
        </div>
      </div>
      <div className="body">
        <DataTable
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
          customStyles={dataTableCustomStyles}
          data={filteredCustomers}
          columns={ownersTableColums}
          pagination
        ></DataTable>
        <div className="btn-container">
          <Button className="action-button" onClick={save} variant="light">
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}