import { CreatedPrefix } from "../../../redux/constants";

export const CreateUpdateBrandRequest = (
  form,
  brandTempState
) => {
  const brand = GetBrandViewModel(form.brandView, form.accessDefault, form?.ownerView?.id);
  brand.itemsToRemove = brandTempState.itemsToRemove
    .filter((item) => !item.id.toString().includes(CreatedPrefix)) // if user want to delete created item,we don't need to include it in request query
    .map((item) => {
      return {
        key: item.item,
        value: item.id,
      };
    });

  brand.pricingLevels = form.pricingLevelsView.map((item) => {
    let createdId = 0;
    if (!item.id.toString().includes(CreatedPrefix)) createdId = item.id;
    return {
      brandId: form.brandView?.id,
      id: createdId,
      name: item.name,
      defaultPercent: item.defaultPercent,
      defaultReference: item.defaultReference,
      amount: item.amount,
      status: item.status,
      brandId: item?.brand?.id,
      prices: item?.prices,
    }
  });
  brand.dealers = brandTempState.brandAccesses.map((item) => {
    let id = 0;
    if (!item.id.toString().includes(CreatedPrefix)) id = item.id;
    const pricingLevel = item.pricingLevel;
    return {
      id: id,
      name: item.name,
      brandId: form.brandView?.id,
      status: item.status,
      pricingLevelId: pricingLevel.id,
      ownerId: form?.ownerView?.id
    };
  });
  return brand;
};

export function GetBrandOwnerView(ownerView)
{
  return {
    id: ownerView?.id,
    name: ownerView?.name
  }
}

export function GetItemsViewModel(items)
{
  if (items == undefined)
    {
      return [];
    }

    return items;
}

export function GetPricingLevelsViewModel(pricingLevels)
{
  if (pricingLevels == undefined)
    {
      return [];
    }

    return pricingLevels;
}

export function GetBrandAccessesViewModel(brandAccesses)
{
  if (brandAccesses == undefined)
    {
      return [];
    }

    return brandAccesses;
}

export function GetBrandViewModel(brandView, accessDefault, brandOwnerId) {
  return {
    id: brandView?.id,
    name: brandView?.name,
    logoUrl: brandView?.logoUrl,
    dropshipAvailable: brandView?.dropshipAvailable,
    accessDefault: accessDefault ?? brandView?.accessDefault ?? undefined,
    ownerId: brandOwnerId
  }
}

export function GetUpdatePricingLevelModel(id, name, defaultPercent, defaultReference, status) {
  return {
    Id: id,
    Name: name,
    DefaultPercent: defaultPercent,
    DefaultReference: defaultReference,
    Status: status
  };
}
export function GetUpdateOwnerModel(form) {
  const customer = {
    email: form?.owner?.email,
    name: form?.owner?.name,
    bcNo: form?.owner?.bcNo,
    verifiedStatus: form?.owner?.verifiedStatus,
    currencyCode: form?.owner?.currencyCode,
    useNetTerms: form?.owner?.useNetTerms,
    stripeId: !!form?.owner?.stripeId ? form?.owner?.stripeId : null,
    shopifyId: !!form?.owner?.shopifyId ? form?.owner?.shopifyId : null,
    shopifyCompanyLocationId: !!form?.owner?.shopifyCompanyLocationId
      ? form?.shopifyCompanyLocationId
      : null,
    types: [],
  };

  if (form?.owner?.isDealer) customer.types.push("Dealer");
  if (form?.owner?.is3PLCustomer) customer.types.push("ThreePLCustomer");
  return customer;
}
