import moment from "moment/moment";
import { Link } from "react-router-dom";
import "../style.css";

export const paymentTableColums = [
  {
    name: "Payment Type",
    selector: (row) => row.paymentMethood,
    sortable: true,
  },
  {
    name: "Account",
    selector: (row) => ` **** **** **** ${row.last4}`,
    sortable: true,
  },
  {
    name: "Card Brand",
    selector: (row) => (!!row.cardBrand ? row.cardBrand : "null"),
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
  },
  {
    name: "Expiration Date",
    selector: (row) =>
      !!row.expirationDate
        ? moment(row.expirationDate).format("DD-MM-YYYY")
        : "null",
    sortable: true,
  },
];
