export const statusTypes = {
  // Synchronous
  GET_VERIFIEDSTATUSESCUSTOMER: "GET_VERIFIEDSTATUSESCUSTOMER",
  GET_CURRENCYCODESCUSTOMER: "GET_CURRENCYCODESCUSTOMER",
  GET_ACCESSDEFAULDBRAND: "GET_ACCESSDEFAULDBRAND",
  GET_COUNTYCODESADDRESS: "GET_COUNTYCODESADDRESS",
  GET_APPROVALBRANDSTATUSES: "GET_APPROVALBRANDSTATUSES",
  GET_PRICEREFERENCESTATUSES: "GET_PRICEREFERENCESTATUSES",
  GET_ROLES: "GET_ROLES",

  // Asynchronous
  ASYNC_GET_STATUSES: "ASYNC_GET_STATUSES",
};

export function AddRoles(data) {
  return {
    type: statusTypes.GET_ROLES,
    payload: data,
  };
}
export function AddPriceReferenceStatuses(data) {
  return {
    type: statusTypes.GET_PRICEREFERENCESTATUSES,
    payload: data,
  };
}

export function AddApprovalBrandStatuses(data) {
  return {
    type: statusTypes.GET_APPROVALBRANDSTATUSES,
    payload: data,
  };
}
export function AddCountryCodesAddress(data) {
  return {
    type: statusTypes.GET_COUNTYCODESADDRESS,
    payload: data,
  };
}
export function AddAccessDefaultBrand(data) {
  return {
    type: statusTypes.GET_ACCESSDEFAULDBRAND,
    payload: data,
  };
}
export function AddCurrencyCodeCustomer(data) {
  return {
    type: statusTypes.GET_CURRENCYCODESCUSTOMER,
    payload: data,
  };
}
export function AddVerifiedStatusCustomer(data) {
  return {
    type: statusTypes.GET_VERIFIEDSTATUSESCUSTOMER,
    payload: data,
  };
}
