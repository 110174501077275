import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomerTempContainer } from "../../../../../components/customers/customer-details/container";
import AddressDetails from "../../../../../components/customers/customer-details/address-details";
import { LocationContainer } from "../container";
import { CreatedPrefix } from "../../../../../redux/constants";

export default function LocationAddressCreatePage() {
  const [form, setForm] = useState({ id: `0${CreatedPrefix}` });

  const dispatch = useDispatch();
  const container = LocationContainer(dispatch);
  function saveForm() {
    container.AddAddress(form);
  }
  return (
    <div>
      <AddressDetails
        form={form}
        setForm={setForm}
        saveForm={saveForm}
      ></AddressDetails>
    </div>
  );
}
