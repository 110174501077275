import { api } from "../../api/axios";

const controller = "PricingGroups";

export async function GetPriceGroupsPagedAsync(
  PageIndex,
  PageSize = 20,
  Name = "",
  BrandName = "",
  SortDescending = false
) {
  return await api.get(
    `${controller}/GetPricingGroupsPaginated?PageSize=${PageSize}&PageIndex=${PageIndex}
    &SortDescending=${SortDescending}&Name=${Name}&BrandName=${BrandName}`
  );
}

export async function GetPriceGroupByIdAsync(id) {
  return await api.get(`${controller}/GetPricingGroups/${id}`);
}

export async function CreatePriceGroupAsync(data) {
  return await api.post(`${controller}/CreatePricingGroup`, data);
}

export async function UpdatePriceGroupAsync(data) {
  return await api.put(`${controller}/UpdatePricingGroup`, data);
}

export async function DeletePriceGroupByIdAsync(id) {
  return await api.delete(`${controller}/DeletePricingGroups/${id}`);
}
