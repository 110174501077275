import { authTypes } from "./types";

export const actionAuth = {
  LoginSuccess(data) {
    return {
      type: authTypes.ASYNC_LOGIN_SUCCESSFUL,
      payload: { data },
    };
  },

  Logout() {
    return {
      type: authTypes.ASYNC_LOGOUT,
    };
  },
};
