import { type } from "@testing-library/user-event/dist/type";

export const brandTempTypes = {
    // Synchronous
    CLEAR_STATE: "CLEAR_STATE",
    PRESAVE_OWNER: "PRESAVE_OWNER",
    UPDATE_OWNER: "UPDATE_OWNER",
    
    REMOVE_BRAND_ACCESS: "REMOVE_BRAND_ACCESS,",
    UPDATE_PRICE_GROUP: "UPDATE_PRICE_GROUP",
    REMOVE_REMOVE_PRICE_LEVEL: "REMOVE_REMOVE_PRICE_LEVEL",
    REMOVE_PRICE_LEVEL: "REMOVE_PRICE_LEVEL",
    ADD_BRAND: "ADD_BRAND",
    ADD_BRAND_ACCESSES: "ADD_BRAND_ACCESSES",
    SELECT_PRICING_LEVELS: "SELECT_PRICING_LEVELS",
    SELECT_ITEMS: "SELECT_ITEMS",
    UPDATE_ADDRESS: "UPDATE_ADDRESS",
    SELECT_BRAND_ACCESS: "SELECT_BRAND_ACCESS",
    SELECT_ASSOCIATE_BRAND_ACCESSES: "SELECT_ASSOCIATE_BRAND_ACCESSES",
    SELECT_OWNER: "SELECT_OWNER",
    UPDATE_BRAND_ACCESS: "UPDATE_BRAND_ACCESS",
    REMOVE_OWNER: "REMOVE_OWNER",
    REMOVE_BRAND_ACCESS: "REMOVE_BRAND_ACCESS",
    // Warehouse
    ADD_PRICING_LEVELS: "ADD_PRICING_LEVELS",
    ADD_WAREHOUSE: "ADD_WAREHOUSE",
    ADD_ITEM: "ADD_ITEM",
    FILL_TEMP_BRAND: "FILL_TEMP_BRAND",
    REMOVE_BRAND: "REMOVE_BRAND"
  };
  
export function FillTempBrand(payload) {
  return {
    type: brandTempTypes.FILL_TEMP_BRAND,
    payload: payload,
  };
}
  export function ClearTempBrandState() {
    return {
      type: brandTempTypes.CLEAR_STATE,
    };
  }
  export function UpdateBrandAccessToTempBrand(payload) {
    return {
      type: brandTempTypes.UPDATE_BRAND_ACCESS,
      payload: payload,
    };
  }
  export function UpdatePriceGroupToTempBrand(payload)
  {
     return {
      type: brandTempTypes.UPDATE_PRICE_GROUP,
      payload: payload,
     }
  }
  export function UpdateOwnerToTempBrand(payload)
  {
    return {
      type: brandTempTypes.UPDATE_OWNER,
      payload: payload,
    };
  }
  export function AddOwnerTempBrand(payload)
  {
    return {
      type: brandTempTypes.UPDATE_OWNER,
      payload: payload,
    };
  }
  export function AddItemToTempBrand(payload)
  {
    return {
        type: brandTempTypes.ADD_ITEM,
        payload: payload,
      };
  }
  export function AddBrandAccessesToTempBrand(payload)
  {
    return {
        type: brandTempTypes.ADD_BRAND_ACCESSES,
        payload: payload,
      };
  }
  export function AddPricingLevelsToTempBrand(payload)
  {
    return {
        type: brandTempTypes.ADD_PRICING_LEVELS,
        payload: payload,
      };
  }
  export function SelectPricingLevels(payload)
  {
    return {
      type: brandTempTypes.SELECT_PRICING_LEVELS,
      payload: payload,
    };
  }
  export function SelectItems(payload)
  {
    return {
      type: brandTempTypes.SELECT_ITEMS,
      payload: payload,
    };
  }
  export function SelectBrandAccess(payload)
  {
    return {
      type: brandTempTypes.SELECT_BRAND_ACCESS,
      payload: payload,
    };
  }
  export function SaveTempOwnerDataToCustomer(payload) {
    return {
      type: brandTempTypes.PRESAVE_OWNER,
      payload: payload,
    };
  }
  
  export function RemoveBrandAccessesFromBrand(payload) {
    return {
      type: brandTempTypes.REMOVE_BRAND_ACCESS,
      payload: payload,
    };
  }

  export function SelectOwner(payload)
  {
    return {
      type: brandTempTypes.SELECT_OWNER,
      payload: payload,
    };
  }
  export function RemovePriceLevelFromBrand(payload) {
    return {
      type: brandTempTypes.REMOVE_PRICE_LEVEL,
      payload: payload,
    };
  }
  