import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import "./style.css";

import { AiOutlineImport } from "react-icons/ai";

function FileInputButton({ handleFileChange }) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Button variant="light" onClick={handleButtonClick}>
      <div className="button-content-container">
        <AiOutlineImport
          style={{ width: "20px", height: "20px" }}
        ></AiOutlineImport>
        Import
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={(e) => {
            handleFileChange(e);
            e.target.value = null;
          }}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
      </div>
    </Button>
  );
}
<AiOutlineImport style={{ width: "20px", height: "20px" }}></AiOutlineImport>;
export default FileInputButton;
