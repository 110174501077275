import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { dataTableCustomStyles } from "../../../../common/datatable/styles";
import { Button, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import * as FiIcons from "react-icons/fi";
import { GetAddressView } from "../../../../common/services/view-service";
export default function LocationBookList({
  locationsBookView,
  selectedId,
  save,
}) {
  const [selectedRowId, setSelectedRowId] = useState(selectedId);
  const navigate = useNavigate();
  const locationsBookTableColums = [
    {
      name: "Select",
      button: true,
      cell: (row) => (
        <Form.Check
          defaultChecked={selectedRowId === row.id}
          type="radio"
          name="contact"
          onClick={() => setSelectedRowId(row.id)}
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Address",
      selector: (row) => GetAddressView(row.address),
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <Link to={`${row.id}/edit`}>
          <Button style={{ width: "4vw" }} variant="light">
            Edit
          </Button>
        </Link>
      ),
    },
    {
      name: "Remove",
      button: true,
      cell: (row) => (
        <Button style={{ width: "5vw" }} variant="light">
          Remove
        </Button>
      ),
    },
  ];

  function GoBack() {
    navigate(-1);
  }

  function Select() {
    let selectedRow = locationsBookView.find(
      (item) => item.id === selectedRowId
    );
    save(selectedRow);
    GoBack();
  }
  return (
    <div>
      <div className="header">
        <h3>Location Book</h3>
        <div onClick={GoBack} className="button-content-address-book">
          <FiIcons.FiPlus className="close-btn" />
        </div>
      </div>
      <div className="body">
        <div style={{ marginBottom: "1vh" }} className="btn-container">
          <Link to={`new`}>
            <Button className="action-button" variant="light">
              Add location
            </Button>
          </Link>
        </div>
        <DataTable
          customStyles={dataTableCustomStyles}
          data={locationsBookView}
          columns={locationsBookTableColums}
          pagination
        ></DataTable>
        <div className="btn-container">
          <Button className="action-button" onClick={Select} variant="light">
            Select
          </Button>
        </div>
      </div>
    </div>
  );
}
