import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./style.css";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import * as FiIcons from "react-icons/fi";
import { dataTableCustomStyles } from "../../../../common/datatable/styles";
import * as AiIcons from "react-icons/ai";
import { useDispatch } from "react-redux";
import { CustomerTempContainer } from "../container";
export default function AddressBooks({ addressesBookView, selectedId, save }) {
  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);
  const [rowToRemoveAddress, setRowToRemoveAddress] = useState(-1);
  const [selectedRowId, setSelectedRowId] = useState(selectedId);
  const navigate = useNavigate();
  const addressesBookTableColums = [
    {
      name: "Select",
      button: true,
      cell: (row) => (
        <Form.Check
          defaultChecked={selectedRowId === row.id}
          type="radio"
          name="contact"
          onClick={() => setSelectedRowId(row.id)}
        />
      ),
    },
    {
      name: "Recipient",
      selector: (row) => row.recipient,
    },
    {
      name: "Address",
      selector: (row) => row.addressLine1,
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    {
      name: "Edit",
      button: true,
      cell: (row) => (
        <Link to={`${row.id}/edit`}>
          <Button style={{ width: "4vw" }} variant="light">
            Edit
          </Button>
        </Link>
      ),
    },
    {
      name: "Remove",
      button: true,
      cell: (row) =>
        rowToRemoveAddress !== row.id ? (
          <Button
            onClick={() => ConfidenceToAddress(row.id)}
            style={{ width: "5vw" }}
            variant="light"
          >
            Delete
          </Button>
        ) : (
          <div className="delete-container">
            <Button
              variant="light"
              style={{ width: "2vw", marginRight: "3px" }}
              onClick={() => RemoveAddress(rowToRemoveAddress)}
            >
              <AiIcons.AiOutlineCheck></AiIcons.AiOutlineCheck>
            </Button>
            <Button
              onClick={NotSureToAddress}
              variant="light"
              style={{ width: "2.5vw", alignItems: "center" }}
            >
              <AiIcons.AiOutlineClose></AiIcons.AiOutlineClose>
            </Button>
          </div>
        ),
    },
  ];

  function RemoveAddress(rowId) {
    container.RemoveAddress(rowId);
    setRowToRemoveAddress(-1);
  }
  function NotSureToAddress() {
    setRowToRemoveAddress(-1);
  }
  function ConfidenceToAddress(id) {
    setRowToRemoveAddress(id);
  }

  function GoBack() {
    navigate(-1);
  }

  function Select() {
    save(selectedRowId);
    GoBack();
  }

  return (
    <div>
      <div className="header">
        <h3>Address Book</h3>
        <div onClick={GoBack} className="button-content-address-book">
          <FiIcons.FiPlus className="close-btn" />
        </div>
      </div>
      <div className="body">
        <div style={{ marginBottom: "1vh" }} className="btn-container">
          <Link to={`new`}>
            <Button className="action-button" variant="light">
              Add address
            </Button>
          </Link>
        </div>
        <DataTable
          customStyles={dataTableCustomStyles}
          data={addressesBookView}
          columns={addressesBookTableColums}
          pagination
        ></DataTable>
        <div className="btn-container">
          <Button className="action-button" onClick={Select} variant="light">
            Select
          </Button>
        </div>
      </div>
    </div>
  );
}
