import "./LoginPage.css";
import Login from "../../components/auth/login";

export default function LoginPage() {
  return (
    <>
      <Login></Login>
    </>
  );
}
