import React, { useState } from "react";
import OwnedBrandDetails from "../../../../../components/customers/customer-details/ownedbranddetails";
import { useDispatch, useSelector } from "react-redux";
import { CustomerTempContainer } from "../../../../../components/customers/customer-details/container";
import { useParams } from "react-router-dom";
import { GetPricingGroupsOfBrand } from "../../../../../components/brands/service/BrandService";
import Loader from "../../../../../ui/loader";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function OwnedBrandsEditPage() {
  const [loading, setLoading] = useState(false);
  const [priceGroups, setPriceGroups] = useState([]);
  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);
  let { id } = useParams();
  const customerTempReducer = useSelector((state) => state.customerTempReducer);
  let initForm = GetOwnedBrand(customerTempReducer);
  const [form, setForm] = useState(ModifyInitForm(initForm));
  function ModifyInitForm(initForm) {
    try {
    let priceGroupName = initForm?.defaultPricingLevel.name;
    if (initForm?.defaultPricingLevel.status == "Default")
      priceGroupName += " (Default)";
    return {
      id: initForm?.id,
      name: initForm?.name,
      pricingLevel: priceGroupName,
      accessDefault: initForm?.accessDefault,
      dropshipAvailable: initForm?.dropshipAvailable,
    };
    } catch (error) {
      console.log(error);
      toast.update(id, {
        render: String(error.message + '. Perhaps, it is because of the empty price group. Please add one if there is no at least one.'),
        type: "error",
        isLoading: false,
        autoClose: 5000,
    });
      return "";
    }
  }

  function GetOwnedBrand(customerTempReducer) {
    let ownedBrand = customerTempReducer.selectedAssociateBrands.find(
      (item) => item.id == id
    );
    if (!!ownedBrand) return ownedBrand;
    ownedBrand = customerTempReducer.ownedBrands.find((item) => item.id == id);
    if (!!ownedBrand) return ownedBrand;
    return {};
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await GetPricingGroupsOfBrand(form.id);
        setPriceGroups(response.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  function Save() {
    const brandToSave = GetOwnedBrand(customerTempReducer);
    brandToSave.defaultPricingLevel = priceGroups.find((el) => {
      if (form.pricingLevel?.includes("Default")) {
        return el.status == "Default";
      }
      return el.name == form.pricingLevel;
    });
    brandToSave.accessDefault = form.accessDefault;
    brandToSave.dropshipAvailable = form.dropshipAvailable;

    container.UpdateOwnedBrand(brandToSave);
  }
  return (
    <div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <OwnedBrandDetails
          form={form}
          setForm={setForm}
          Save={Save}
          priceGroups={priceGroups}
        ></OwnedBrandDetails>
      )}
    </div>
  );
}
