import React, { useEffect, useState } from "react";
import "./style.css";

import * as FiIcons from "react-icons/fi";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../../../routes";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { CustomerTempContainer } from "../container";
export default function WarehouseDetails({ form, setForm, Save, PreSave }) {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [beenSubmitted, setBeenSubmitted] = useState(false);

  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);

  function GoBack() {
    container.ClearWarehouseLocation();
    container.ClearTempWarehouse();
    navigate(-1);
  }
  const handleInput = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setForm((prevForm) => ({
      ...prevForm,
      [target.name]: value,
    }));
  };

  const zeroingvaliables = () => {
    setForm({});
  };
  const validate = () => {
    const allErrors = {};
    zeroingerrors();
    if (!form.locationName) {
      allErrors.locationName = "Please choose a location!";
    }

    if (!form.name) {
      allErrors.name = "Please write name!";
    }

    setError(allErrors);
  };
  useEffect(() => {
    validate();
  }, [form]);
  const zeroingerrors = () => {
    setError({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setBeenSubmitted(true);
    if (Object.keys(error).length === 0) {
      Save();
      zeroingvaliables();
      GoBack();
    }
  };

  return (
    <div>
      <div className="header">
        <div className="header-contaner">
          <h3>Warehouse Details</h3>
          <h3>{form.name}</h3>
        </div>
        <div onClick={GoBack} className="button-content-add-brand">
          <FiIcons.FiPlus className="close-btn" />
        </div>
      </div>
      <div className="body">
        <Form noValidate onSubmit={handleSubmit}>
          <TextField
            required
            type="text"
            error={beenSubmitted ? error.name : null}
            helperText={beenSubmitted ? error.name : null}
            label={"Name"}
            name="name"
            value={form.name || ""}
            onChange={handleInput}
          />
          <div className="form-container">
            <Form.Group>
              <Form.Check
                checked={form.is3PL}
                inline
                label="3PL"
                name="is3PL"
                type="checkbox"
                value={form.is3PL}
                onChange={handleInput}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                checked={form.isPrivate}
                inline
                label="Private"
                name="isPrivate"
                type="checkbox"
                value={form.isPrivate}
                onChange={handleInput}
              />
            </Form.Group>
          </div>

          <div className="form-container">
            <div>
              <TextField
                required
                error={beenSubmitted ? error.locationName : null}
                helperText={beenSubmitted ? error.locationName : null}
                label={"Location"}
                name="locationName"
                value={form.locationName || ""}
                style={{ width: "20vw" }}
                type="text"
                placeholder="3405 SPEEDWAY  AUSTIN TX 78705-1500 USA"
              />
            </div>
            <Link to={`${routes.locationBook}`}>
              <Button
                className="action-button"
                onClick={PreSave}
                variant="dark"
              >
                Locations Book
              </Button>
            </Link>
          </div>

          <div className="btn-container">
            <Button className="action-button" variant="light" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
