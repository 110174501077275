// Cookies
export const TOKEN = "access_token";

//Token
export const UserIdClaim =
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
export const RoleClaim =
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
export const EmailClaim =
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";

export const CreatedPrefix = "Created";
export const BrandAccessPrefix = "BrandAccess";

// DeleteItemsEnum on the backend
export const removeItemsNames = {
  Warehouse: "Warehouse",
  BrandAccess: "BrandAccess",
  OwnedBrand: "OwnedBrand",
  SKU: "SKU",
  Address: "Address",
};

// logged in userId header
export const LOGGED_USER_ID = "x-dealer-portal-loggedUserId";

export const Messages = {
  FillFields: "Please fill all required fields!",
};
