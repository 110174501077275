import React, { useEffect } from "react";
import BrandsList from "../../../components/brands/brands-list";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { BrandTempContainer } from "../../../components/brands/container";

export default function BrandsPage() {
  const dispatch = useDispatch();
  const container = BrandTempContainer(dispatch);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname == "/brands") {
      container.ClearTempBrand();
    }
  }, [location]);
  return (
    <div>
      <BrandsList></BrandsList>
    </div>
  );
}
