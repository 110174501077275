import { customerTempTypes } from "../../actions/customers/customer-temp/types";
import { removeItemsNames } from "../../constants";
import { brandTempTypes } from "../../actions/brands/brand-temp/types";

const defaultState = {
  brand : undefined,
  owner: undefined,
  logoUrl: undefined,
  brandView: undefined,
  pricingLevels: [],
  items: [],
  brandAccesses: [],
  itemsToRemove: [],
};

export const BrandTempReducer = (
  state = defaultState,
  { type, payload }
) => {
  switch (type) {
    case brandTempTypes.CLEAR_STATE:
      return {
        ...defaultState,
      };
    case brandTempTypes.FILL_TEMP_BRAND:
      return {
        ...state,
        brand: payload,
      };
      case brandTempTypes.UPDATE_PRICE_GROUP:
        if (payload.status == "Default")
        {
          return {
            ...state,
            pricingLevels: [
              ...state.pricingLevels.map((item) => {
                if (item.id == payload.id) {
                  return payload;
                }
                else {
                item.status = "Regular";
                return item;
                }
              }),
            ],
          }
        }
        else
        {
        return {
          ...state,
          pricingLevels: [
            ...state.pricingLevels.map((item) => {
              if (item.id == payload.id) return payload;
              return item;
            }),
          ],
        }
      };
      case brandTempTypes.UPDATE_BRAND_ACCESS:
        return {
          ...state,
          brandAccesses: [
            ...state.brandAccesses.map((item) => {
              if (item.id == payload.id) return payload;
              return item;
            }),
          ],
        };
      case brandTempTypes.SELECT_ITEMS:
        return {
          ...state,
          items: payload,
        };
      case brandTempTypes.SELECT_BRAND_ACCESS:
      return {
        ...state,
        brandAccesses: payload,
      };
      case brandTempTypes.SELECT_PRICING_LEVELS:
        return {
          ...state,
          pricingLevels: payload,
        };
    case brandTempTypes.UPDATE_OWNER:
        return {
          ...state,
          owner: payload
        };
    case brandTempTypes.PRESAVE_OWNER:
        return {
          ...state,
          tempOwner: payload,
        };
      case brandTempTypes.ADD_PRICING_LEVELS:
          if (Array.isArray(payload))
            return { ...state, pricingLevels: [...state.pricingLevels, ...payload] };
          return { ...state, pricingLevels: [...state.pricingLevels, payload] };
      case brandTempTypes.ADD_BRAND_ACCESSES:
      if (Array.isArray(payload))
        return { ...state, brandAccesses: [...state.brandAccesses, ...payload] };
      return { ...state, brandAccesses: [...state.brandAccesses, payload] };
      case brandTempTypes.ADD_ITEM:
      if (Array.isArray(payload))
        return { ...state, items: [...state.items, ...payload] };
      return { ...state, items: [...state.items, payload] };

      case brandTempTypes.REMOVE_BRAND_ACCESS:
      return {
        ...state,
        itemsToRemove: [
          ...state.itemsToRemove,
          { item: removeItemsNames.brandAccesses, id: payload },
        ],
        brandAccesses: state.brandAccesses.filter((item) => item.id != payload),
      };
    case brandTempTypes.REMOVE_PRICE_LEVEL:
      return {
        ...state,
        itemsToRemove: [
          ...state.itemsToRemove,
          { item: removeItemsNames.pricingLevels, id: payload },
        ],
        pricingLevels: state.pricingLevels.filter((item) => item.id != payload),
      };
    case brandTempTypes.REMOVE_BRAND:
      return {
        ...state,
        itemsToRemove: [
          ...state.itemsToRemove,
          { item: removeItemsNames.brand, id: payload },
        ],
        brand: state.brand.id === payload ? undefined : state.brand,
      };
    default:
      return state;
  }
}