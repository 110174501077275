export const skuTypes = {
  // Synchronous
  ADD_SKU: "ADD_SKU",
  ADD_SKUs: "ADD_SKUs",
  UPDATE_SKU: "UPDATE_SKU",
  REMOVE_SKU: "REMOVE_SKU",
  CLEAR_SKU: "CLEAR_SKU",
  // Asynchronous
};

export function AddSKU(payload) {
  return {
    type: skuTypes.ADD_SKU,
    payload: payload,
  };
}

export function UpdateSKU(payload) {
  return {
    type: skuTypes.UPDATE_SKU,
    payload: payload,
  };
}

export function ClearSKU() {
  return {
    type: skuTypes.CLEAR_SKU,
  };
}

export function AddListSKUs(payload) {
  return {
    type: skuTypes.ADD_SKUs,
    payload: payload,
  };
}

export function RemoveSKU(payload) {
  return {
    type: skuTypes.REMOVE_SKU,
    payload: payload,
  };
}
