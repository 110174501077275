import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "../saga";
import { AuthReducer } from "../reducers/auth";
import { StatusReducer } from "../reducers/status";
import { CustomerTempReducer } from "../reducers/customer-temp";
import { authTypes } from "../actions/auth/types";
import { SkuReducer } from "../reducers/price-groups";
import { LocationReducer } from "../reducers/location";
import { BrandTempContainer } from "../../components/brands/container";
import { BrandTempReducer } from "../reducers/brand-temp";

const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
  locationReducer: LocationReducer,
  skuTemReducer: SkuReducer,
  customerTempReducer: CustomerTempReducer,
  statusReducer: StatusReducer,
  authReducer: AuthReducer,
  brandTempReducer: BrandTempReducer
});

const rootReducer = (state, action) => {
  if (action.type === authTypes.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
