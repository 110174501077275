import React from "react";

const ImageWithFallback = ({ src, alt, fallbackSrc }) => {
  const imageUrl = src || fallbackSrc;

  const onError = (event) => {
    event.target.src = fallbackSrc; // If image fails to load, display fallback image
  };

  return (
    <img
      src={imageUrl}
      alt={alt}
      onError={onError}
      style={{ maxWidth: "100%", maxHeight: "100%" }} // Optional: Adjust styling as needed
    />
  );
};

export default ImageWithFallback;
