import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomerTempContainer } from "../../../../../components/customers/customer-details/container";
import BrandsList from "../../../../../components/customers/customer-details/brands-list";
import { GetBrandsAsync } from "../../../../../components/brands/service/BrandService";
import Loader from "../../../../../ui/loader";

export default function AssociateBrandListPage() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);
  const [brands, setBrands] = useState([]);
  const customerTempReducer = useSelector((state) => state.customerTempReducer);
  const selectedBrands = getSelectedIds(customerTempReducer);
  const getBrands = async () => {
    let response = await GetBrandsAsync();
    return response.data;
  };
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await getBrands();
        setBrands(response);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  function getSelectedIds(customerTempReducer) {
    let selectedBrands = [
      ...customerTempReducer.selectedAssociateBrands,
      ...customerTempReducer.ownedBrands,
    ];
    return selectedBrands;
  }

  const selectedIds = selectedBrands.map((item) => item.id);

  const rowSelectCritera = (row) => selectedIds.includes(row.id);

  function save(selectedRows) {
    let alreadySelected = customerTempReducer.ownedBrands.map(
      (item) => item.id
    );
    let mySelectedRows = selectedRows.filter(
      (item) => !alreadySelected.includes(item.id)
    );
    container.SelectAssociateBrands(mySelectedRows);
  }
  return (
    <div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <BrandsList
          preSelectedRows={selectedBrands}
          brandsView={brands.filter((item) => {
            if (!!!item.owner) return item;
          })}
          rowSelectCritera={rowSelectCritera}
          save={save}
        ></BrandsList>
      )}
    </div>
  );
}
