import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomerTempContainer } from "../../../../../components/customers/customer-details/container";
import BrandsList from "../../../../../components/customers/customer-details/brands-list";
import { CreatedPrefix } from "../../../../../redux/constants";
import { GetBrandsAsync } from "../../../../../components/brands/service/BrandService";
import Loader from "../../../../../ui/loader";
export default function BrandListPage() {
  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const selectedBrandsAccess = useSelector(
    (state) => state.customerTempReducer.selectedBrandsAccess
  );
  const selectedIds = getSelectedIds(selectedBrandsAccess);
  const getBrands = async () => {
    let response = await GetBrandsAsync();
    return response.data;
  };
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await getBrands();
        setBrands(response);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  function getSelectedIds(selectedBrandsAccess) {
    return selectedBrandsAccess.map((item) => item.brandId);
  }

  const rowSelectCritera = (row) => selectedIds.includes(row.id);

  const matchStatuses = {
    Allow: "Approved", // Key is a accessDefault status of brand, and value is match status by default for it
    Restricted: "Pending",
  };
  function GetBrandAccessStatus(brandStatus) {
    return matchStatuses[brandStatus];
  }
  function save(newselectedRows, oldselectedrows) {
    let rowsToSelect = [...newselectedRows];
    const inputRows = rowsToSelect.map((brand) => {
      let brandAccess = {};
      brandAccess.name = brand.name;
      brandAccess.accessDefault = brand.accessDefault;
      brandAccess.id = `${brand.id}${CreatedPrefix}`;
      brandAccess.brandId = brand.id;
      brandAccess.pricingLevel = brand.defaultPricingLevel;
      brandAccess.status = brand.status;
      if (!!!brand.status)
        brandAccess.status = GetBrandAccessStatus(brand.accessDefault);
      return brandAccess;
    });
    inputRows.push(...oldselectedrows);
    container.SelectBrandsAccesses(inputRows);
  }

  return (
    <div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <BrandsList
          preSelectedRows={selectedBrandsAccess}
          brandsView={brands}
          rowSelectCritera={rowSelectCritera}
          save={save}
        ></BrandsList>
      )}
    </div>
  );
}
