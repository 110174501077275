import { authTypes } from "../../actions/auth/types";

const defaultState = {
  authenticated: false,
  expireDate: new Date(),
  user: {},
};

export const AuthReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case authTypes.LOGIN_SUCCESSFUL:
      return {
        ...state,
        expireDate: payload.expireDate,
        authenticated: true,
        user: payload.user,
      };
    case authTypes.LOGOUT:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
