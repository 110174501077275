import { skuTypes } from "../../actions/price-groups/types";
import { removeItemsNames } from "../../constants";

const defaultState = {
  skus: [],
  itemsToRemove: [],
};

export const SkuReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case skuTypes.CLEAR_SKU:
      return { ...defaultState };
    case skuTypes.ADD_SKU:
      return { ...state, skus: [...state.skus, payload] };
    case skuTypes.ADD_SKUs:
      return { ...state, skus: [...payload] };
    case skuTypes.REMOVE_SKU:
      return {
        ...state,
        itemsToRemove: [
          ...state.itemsToRemove,
          { item: removeItemsNames.SKU, id: payload },
        ],
        skus: [
          ...state.skus.filter((item) => {
            if (item.id != payload) return true;
            return false;
          }),
        ],
      };
    case skuTypes.UPDATE_SKU:
      return {
        ...state,
        skus: [
          ...state.skus.map((sku) => {
            if (sku.id === payload.id) return payload;
            return sku;
          }),
        ],
      };
    default:
      return state;
  }
};
