import React from "react";
import { Alert } from "react-bootstrap";

export default function CustomAlert(props) {
  const { variant, show, setShow, message } = props;
  return (
    <Alert variant={variant} show={show} onClick={() => setShow(false)}>
      {message}
    </Alert>
  );
}
