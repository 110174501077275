import React from "react";
import PriceGroupDetails from "../../../components/price-groups/price-groups.details";
import { useState } from "react";
import { useEffect } from "react";
import { GetBrandNamesAndIdsAsync } from "../../../components/brands/service/BrandService";
import Loader from "../../../ui/loader";
import { useSelector } from "react-redux";
import { CreatedPrefix } from "../../../redux/constants";
import { CreatePriceGroupAsync } from "../../../common/price-groups.services";
import { CreateRequest } from "../../../common/price-groups.services/helpeService";

export default function PriceGroupCreatePage() {
  const skuTempState = useSelector((state) => state.skuTemReducer);
  const [loading, setLoading] = useState(false);
  const initForm = { id: `${CreatedPrefix}`, prices: [] };
  const [form, setForm] = useState(initForm);
  const [brands, setBrands] = useState([]);

  async function GetBrands() {
    return await GetBrandNamesAndIdsAsync();
  }

  async function Save() {
    form.brandEntity = brands.find((item) => {
      if (item.name == form.brand) return item;
    });
    const inputData = CreateRequest(form, skuTempState);

    try {
      let response = await CreatePriceGroupAsync(inputData);
      return response;
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await GetBrands();
        setBrands(response.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <div className="items-list">
      {loading ? (
        <Loader></Loader>
      ) : (
        <PriceGroupDetails
          form={form}
          setForm={setForm}
          Save={Save}
          brands={brands}
          successfulMessage={`${form.name} successfully created!`}
        ></PriceGroupDetails>
      )}
    </div>
  );
}
