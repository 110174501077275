export const locationTypes = {
  // Synchronous
  ADD_LOCATION_ADDRESS: "ADD_LOCATION_ADDRESS",
  CLEAR_LOCATION_STATE: "CLEAR_LOCATION_STATE",
};

export function AddLocationAddress(data) {
  return {
    type: locationTypes.ADD_LOCATION_ADDRESS,
    payload: data,
  };
}
export function ClearLocationState() {
  return {
    type: locationTypes.CLEAR_LOCATION_STATE,
  };
}
