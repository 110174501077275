import { CreatedPrefix } from "../../../redux/constants";

export const CreateCustomerRequest = (form, customerTempState, statusState) => {
  const customer = {
    email: form.email,
    name: form.name,
    bcNo: form.bcNo,
    verifiedStatus: form.verifiedStatus,
    currencyCode: form.currencyCode,
    useNetTerms: form.useNetTerms,
    stripeId: !!form.stripeId ? form.stripeId : null,
    shopifyId: !!form.shopifyId ? form.shopifyId : null,
    shopifyCompanyLocationId: !!form.shopifyCompanyLocationId
      ? form.shopifyCompanyLocationId
      : null,
    types: [],
  };

  if (form.isDealer) customer.types.push("Dealer");
  if (form.is3PLCustomer) customer.types.push("ThreePLCustomer");
  customer.defaultBillingAddressId = customerTempState.billingAddressId;
  customer.defaultShippingAddressId = customerTempState.shippingAddressId;
  customer.addresses = customerTempState.addresses;
  customer.phoneNumber = GetPhoneNumberModel(
    form.countrytel,
    form.phone,
    statusState
  );

  customer.addresses = customer.addresses.map((item) => {
    item.phoneNumber = GetPhoneNumberModel(
      item.countrytel,
      item.phone,
      statusState
    );
    return item;
  });
  customer.brandAccess = form.brandAccessView.map((item) => {
    const pricingLevel = item.pricingLevel;
    return {
      brandId: item.brandId,
      status: item.status,
      pricingLevelId: pricingLevel.id,
    };
  });

  customer.ownedBrands = form.ownedBrandsView.map((item) => {
    return {
      brandId: item.id,
      pricingLevelId: item.defaultPricingLevel?.id,
    };
  });

  customer.ownedWareouses = form.warehousesView.map((item) => {
    if (item.id.toString().includes(CreatedPrefix)) item.id = 0;
    return item;
  });
  return customer;
};
function GetPhoneNumberModel(countrytel, phone, statusState) {
  const tempphone = countrytel.split(" ");
  const countryCodes = statusState.countryCodes;
  const shortenedNameOfPhoneCountry = tempphone[1];
  const CountryCode = countryCodes.find((item) => {
    if (item.shortenedName == shortenedNameOfPhoneCountry) return item;
  });
  return {
    CountryTelCode: tempphone[0],
    CountryCode: countryCodes.indexOf(CountryCode),
    Number: phone,
  };
}
export const CreateUpdateCustomerRequest = (
  form,
  customerTempState,
  statusState
) => {
  const customer = {
    Id: customerTempState.customer.id,
    email: form.email,
    name: form.name,
    bcNo: form.bcNo,
    verifiedStatus: form.verifiedStatus,
    currencyCode: form.currencyCode,
    useNetTerms: form.useNetTerms,
    stripeId: !!form.stripeId ? form.stripeId : null,
    shopifyId: !!form.shopifyId ? form.shopifyId : null,
    shopifyCompanyLocationId: !!form.shopifyCompanyLocationId
      ? form.shopifyCompanyLocationId
      : null,
    types: [],
  };
  customer.itemsToRemove = customerTempState.itemsToRemove
    .filter((item) => !item.id.toString().includes(CreatedPrefix)) // if user want to delete created item,we don't need to include it in request query
    .map((item) => {
      return {
        key: item.item,
        value: item.id,
      };
    });
  if (form.isDealer) customer.types.push("Dealer");
  if (form.is3PLCustomer) customer.types.push("ThreePLCustomer");
  customer.defaultBillingAddressId = customerTempState.billingAddressId;
  customer.defaultShippingAddressId = customerTempState.shippingAddressId;
  customer.phoneNumber = customerTempState.customer.phoneNumber;
  customer.phoneNumber = GetUpdatePhoneNumberModel(
    customer.phoneNumber.id,
    form.countrytel,
    form.phone,
    statusState
  );

  customer.addresses = customerTempState.addresses.map((item) => {
    item.phoneNumber = GetUpdatePhoneNumberModel(
      item.phoneNumber?.id,
      item.countrytel,
      item.phone,
      statusState
    );
    return item;
  });

  customer.brandAccess = customerTempState.selectedBrandsAccess.map((item) => {
    let id = 0;
    if (!item.id.toString().includes(CreatedPrefix)) id = item.id;
    const pricingLevel = item.pricingLevel;
    return {
      Id: id,
      brandId: item.brandId,
      status: item.status,
      pricingLevelId: pricingLevel.id,
    };
  });

  customer.ownedBrands = form.ownedBrandsView.map((item) => {
    return {
      brandId: item.id,
      accessDefault: item.accessDefault,
      dropshipAvailable: item.dropshipAvailable,
      pricingLevelId: item.defaultPricingLevel?.id,
    };
  });
  customer.ownedWareouses = form.warehousesView.map((item) => {
    if (item.id.toString().includes(CreatedPrefix)) item.id = 0;
    item.locationId = item.location.id;
    return item;
  });
  return customer;
};
export function GetUpdatePhoneNumberModel(id, countrytel, phone, statusState) {
  const tempphone = countrytel.split(" ");
  const countryCodes = statusState.countryCodes;
  const shortenedNameOfPhoneCountry = tempphone[1];
  const CountryCode = countryCodes.find((item) => {
    if (item.shortenedName == shortenedNameOfPhoneCountry) return item;
  });
  return {
    Id: id,
    CountryTelCode: tempphone[0],
    CountryCode: countryCodes.indexOf(CountryCode),
    Number: phone,
  };
}
