import React from "react";
import AddressDetails from "../../../../../components/customers/customer-details/address-details";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { CustomerTempContainer } from "../../../../../components/customers/customer-details/container";
import { CreatedPrefix } from "../../../../../redux/constants";

export default function AddressCreatePage() {
  const addresses = useSelector((state) => state.customerTempReducer.addresses);
  const id = !!addresses.length ? addresses.length : 0;
  const initForm = { id: `${id}${CreatedPrefix}` };
  const [form, setForm] = useState(initForm);

  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);
  function saveForm() {
    container.AddAddress(form);
  }
  return (
    <div>
      <AddressDetails
        form={form}
        setForm={setForm}
        saveForm={saveForm}
      ></AddressDetails>
    </div>
  );
}
