import { useLocation } from "react-router-dom";
import CustomerList from "../../../components/customers/customer-list";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CustomerTempContainer } from "../../../components/customers/customer-details/container";

export default function CustomerPage() {
  const dispatch = useDispatch();
  const container = CustomerTempContainer(dispatch);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname == "/customers") {
      container.ClearTempCustomer();
    }
  }, [location]);
  return (
    <div>
      <CustomerList></CustomerList>
    </div>
  );
}
