import { UpdateOwnerToTempBrand, UpdatePriceGroupToTempBrand, UpdateBrandAccessToTempBrand, SelectPricingLevels, SelectItems,RemoveBrandAccessesFromBrand, AddOwnerTempBrand, AddBrandAccessesToTempBrand, FillTempBrand, AddItemToTempBrand, ClearTempBrandState, SelectBrandAccess, RemoveBrandAccessFromBrand} from "../../redux/actions/brands/brand-temp/types";
  
  export const BrandTempContainer = (dispatch) => ({
    ClearTempBrand() {
      dispatch(ClearTempBrandState());
    },
    AddOwner(data)
    {
      dispatch(AddOwnerTempBrand(data));
    },
    AddItems(data) {
        dispatch(AddItemToTempBrand(data));
      },
      SelectPricingLevels(data)
      {
        dispatch(SelectPricingLevels(data));
      },
      SelectItems(data) {
        dispatch(SelectItems(data));
      },
      UpdateBrandAccess(data) {
        dispatch(UpdateBrandAccessToTempBrand(data));
      },
      UpdateOwner(data) {
        dispatch(UpdateOwnerToTempBrand(data));
      },
      UpdatePriceGroup(data) {
        dispatch(UpdatePriceGroupToTempBrand(data));
      },
    AddBrandAccesses(data)
    {
      dispatch(AddBrandAccessesToTempBrand(data))
    },
    AddPricingLevels(data)
    {
      dispatch(AddBrandAccessesToTempBrand(data))
    },
    SelectBrandAccesses(data) {
        dispatch(SelectBrandAccess(data));
      },
    RemoveBrandAccess(data)
      {
        dispatch(RemoveBrandAccessesFromBrand(data));
      },
    FillBrand(data) {
        dispatch(FillTempBrand(data));
      }
  });
  